import { SOCIALS_ENUM } from "src/consts/socialsEnum";
import { likeCharity, likeDream, likeNewsfeedDream } from "src/services/api";
import {
  CharityCommentDto,
  DreamAngelCommentActivityDto,
  DreamCommentDto,
} from "src/services/api/data-contracts";
import { MessageData } from "src/types/messenger";

export const SET_DREAM_COMMENTS = "SET_DREAM_COMMENTS";
export const UPDATE_DREAM_COMMENTS_COUNT = "UPDATE_DREAM_COMMENTS_COUNT";
export const SET_CHARITY_COMMENTS = "SET_COMMENTS";
export const UPDATE_CHARITY_COMMENTS_COUNT = "UPDATE_COMMENTS_COUNT";
export const SET_ANGEL_COMMENTS = "SET_ANGEL_COMMENTS";
export const UPDATE_ANGEL_COMMENTS_COUNT = "UPDATE_ANGEL_COMMENTS_COUNT";

export const SET_ACTIVE_REPLY = "SET_ACTIVE_REPLY";

export const SET_CHAT_TYPE = "SET_CHAT_TYPE";
export const SET_REQUESTS_CHATS = "SET_REQUESTS_CHATS";
export const SET_MESSENGER_CHATS = "SET_MESSENGER_CHATS";
export const SET_CANCELED_CHATS = "SET_CANCELED_CHATS";

export const SET_SAVED_POSTS = "SET_SAVED_POSTS";
export const SET_UN_SAVED_POSTS = "SET_UN_SAVED_POSTS";

export const UPDATE_CHARITY_LIKES = "UPDATE_CHARITY_LIKES";
export const UPDATE_DREAM_LIKES = "UPDATE_DREAM_LIKES";
export const UPDATE_ANGEL_LIKES = "UPDATE_ANGEL_LIKES";

export const SET_STEP = "SET_STEP";

interface SetStep {
  type: typeof SET_STEP;
  payload: { step: number };
}

export type OnboardingActionTypes = SetStep;

interface SetDreamCommentsAction {
  type: typeof SET_DREAM_COMMENTS;
  payload: { dreamId: string; comments: DreamCommentDto[] };
}

interface UpdateDreamCommentsCountAction {
  type: typeof UPDATE_DREAM_COMMENTS_COUNT;
  payload: { dreamId: string; count: number };
}

interface SetCharityCommentsAction {
  type: typeof SET_CHARITY_COMMENTS;
  payload: { charityId: string; comments: CharityCommentDto[] };
}

interface UpdateCharityCommentsCountAction {
  type: typeof UPDATE_CHARITY_COMMENTS_COUNT;
  payload: { charityId: string; count: number };
}

interface SetAngelCommentsAction {
  type: typeof SET_ANGEL_COMMENTS;
  payload: { dreamAngelId: string; comments };
}

interface UpdateAngelCommentsCountAction {
  type: typeof UPDATE_ANGEL_COMMENTS_COUNT;
  payload: { dreamAngelId: string; count: number };
}

interface SetActiveReply {
  type: typeof SET_ACTIVE_REPLY;
  payload: { parentReplyId: string };
}
interface SetChatType {
  type: typeof SET_CHAT_TYPE;
  payload: { chatType: string };
}
interface SetRequestsChats {
  type: typeof SET_REQUESTS_CHATS;
  payload: { chat: any };
}
interface SetMessengerChats {
  type: typeof SET_MESSENGER_CHATS;
  payload: { chat: any };
}
interface SetCanceledChats {
  type: typeof SET_CANCELED_CHATS;
  payload: { chat: any };
}

export type MessagesActionTypes =
  | SetChatType
  | SetRequestsChats
  | SetMessengerChats
  | SetCanceledChats;

interface UpdateCharityLikesAction {
  type: typeof UPDATE_CHARITY_LIKES;
  payload: {
    charityId: string;
    likesCount: number;
    userLiked: boolean;
    likedBy: string[];
  };
}

interface UpdateDreamLikesAction {
  type: typeof UPDATE_DREAM_LIKES;
  payload: {
    dreamId: string;
    likesCount: number;
    userLiked: boolean;
    likedBy: string[];
  };
}

interface UpdateAngelLikesAction {
  type: typeof UPDATE_ANGEL_LIKES;
  payload: {
    dreamAngelId: string;
    likesCount: number;
    userLiked: boolean;
    likedBy: string[];
  };
}

interface SetSavedPosts {
  type: typeof SET_SAVED_POSTS;
  payload: { postId: string };
}
interface SetUnSavedPosts {
  type: typeof SET_UN_SAVED_POSTS;
  payload: { postId: string };
}

export type CommentsActionTypes =
  | SetCharityCommentsAction
  | UpdateCharityCommentsCountAction
  | SetDreamCommentsAction
  | UpdateDreamCommentsCountAction
  | SetAngelCommentsAction
  | UpdateAngelCommentsCountAction
  | UpdateCharityLikesAction
  | UpdateDreamLikesAction
  | UpdateAngelLikesAction
  | SetActiveReply
  | SetSavedPosts
  | SetUnSavedPosts;

export const setDreamComments = (
  dreamId: string,
  comments: DreamCommentDto[]
): SetDreamCommentsAction => ({
  type: SET_DREAM_COMMENTS,
  payload: { dreamId, comments },
});

export const updateDreamCommentsCount = (
  dreamId: string,
  count: number
): UpdateDreamCommentsCountAction => ({
  type: UPDATE_DREAM_COMMENTS_COUNT,
  payload: { dreamId, count },
});

export const setCharityComments = (
  charityId: string,
  comments: CharityCommentDto[]
): SetCharityCommentsAction => ({
  type: SET_CHARITY_COMMENTS,
  payload: { charityId, comments },
});

export const updateCharityCommentsCount = (
  charityId: string,
  count: number
): UpdateCharityCommentsCountAction => ({
  type: UPDATE_CHARITY_COMMENTS_COUNT,
  payload: { charityId, count },
});

export const setAngelComments = (
  dreamAngelId: string,
  comments: DreamAngelCommentActivityDto[]
): SetAngelCommentsAction => ({
  type: SET_ANGEL_COMMENTS,
  payload: { dreamAngelId, comments },
});

export const updateAngelCommentsCount = (
  dreamAngelId: string,
  count: number
): UpdateAngelCommentsCountAction => ({
  type: UPDATE_ANGEL_COMMENTS_COUNT,
  payload: { dreamAngelId, count },
});

export const updateCharityLikes = (
  charityId: string,
  likesCount: number,
  userLiked: boolean,
  likedBy: string[]
): UpdateCharityLikesAction => ({
  type: UPDATE_CHARITY_LIKES,
  payload: { charityId, likesCount, userLiked, likedBy },
});

export const updateDreamLikes = (
  dreamId: string,
  likesCount: number,
  userLiked: boolean,
  likedBy: string[]
): UpdateDreamLikesAction => ({
  type: UPDATE_DREAM_LIKES,
  payload: {
    dreamId,
    likesCount,
    userLiked,
    likedBy: Array.isArray(likedBy) ? likedBy : [],
  },
});

export const updateAngelLikes = (
  dreamAngelId: string,
  likesCount: number,
  userLiked: boolean,
  likedBy: string[]
): UpdateAngelLikesAction => ({
  type: UPDATE_ANGEL_LIKES,
  payload: { dreamAngelId, likesCount, userLiked, likedBy },
});

export const setActiveReply = (parentReplyId: string): SetActiveReply => ({
  type: SET_ACTIVE_REPLY,
  payload: { parentReplyId },
});

const updateLikesInState = (comment, profileId) => {
  const userLiked = !comment.userLiked;
  const likedBy = userLiked
    ? [...comment.likedBy, { id: profileId }]
    : comment.likedBy.filter((user) => user.id !== profileId);
  const likesCount = userLiked
    ? comment.likesCount + 1
    : comment.likesCount - 1;

  return {
    ...comment,
    userLiked,
    likedBy,
    likesCount,
  };
};

export const likePost = (postId, type) => async (dispatch, getState) => {
  const state = getState().comments;
  const profileId = localStorage.getItem("profileId");

  const getUpdatedComments = (comments) => {
    return updateLikesInState(comments, profileId);
  };

  switch (type) {
    case SOCIALS_ENUM.NEWSFEED_DREAM:
    case SOCIALS_ENUM.PROFILE_DREAM:
      await likeDream(postId);
      const updatedDreamComments = getUpdatedComments(
        state.dreamComments[postId] || []
      );
      dispatch(
        updateDreamLikes(
          postId,
          updatedDreamComments.likesCount,
          updatedDreamComments.userLiked,
          updatedDreamComments.likedBy
        )
      );
      break;
    case SOCIALS_ENUM.NEWSFEED_CHARITY:
    case SOCIALS_ENUM.INTERN_CHARITY:
      await likeCharity(postId);
      const updatedCharityComments = getUpdatedComments(
        state.charityComments[postId] || []
      );
      dispatch(
        updateCharityLikes(
          postId,
          updatedCharityComments.likesCount,
          updatedCharityComments.userLiked,
          updatedCharityComments.likedBy
        )
      );
      break;
    case SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL:
      await likeNewsfeedDream(postId);
      const updatedAngelComments = getUpdatedComments(
        state.angelComments[postId] || []
      );
      dispatch(
        updateAngelLikes(
          postId,
          updatedAngelComments.likesCount,
          updatedAngelComments.userLiked,
          updatedAngelComments.likedBy
        )
      );
      break;
    default:
      break;
  }
};

export const savePost = (postId: string): CommentsActionTypes => ({
  type: SET_SAVED_POSTS,
  payload: { postId },
});
export const unSavePost = (postId: string): CommentsActionTypes => ({
  type: SET_UN_SAVED_POSTS,
  payload: { postId },
});

export const setChatType = (chatType: string): MessagesActionTypes => ({
  type: SET_CHAT_TYPE,
  payload: { chatType },
});

export const setRequestsChats = (chat: MessageData[]): MessagesActionTypes => ({
  type: SET_REQUESTS_CHATS,
  payload: { chat },
});

export const setMessengerChats = (
  chat: MessageData[]
): MessagesActionTypes => ({
  type: SET_MESSENGER_CHATS,
  payload: { chat },
});

export const setCanceledChats = (chat: MessageData[]): MessagesActionTypes => ({
  type: SET_CANCELED_CHATS,
  payload: { chat },
});

export const setStep = (step: number): OnboardingActionTypes => ({
  type: SET_STEP,
  payload: { step },
});
