import React, { useEffect } from "react";
import { socket } from "src/socket";
import { CreateMessageProps } from "src/types/messenger";
import { toast } from "react-toastify";
import { setMessengerChats } from "src/store/action";
import { useDispatch, useSelector } from "react-redux";
import { ChatType } from "src/types/redux";

const CreateMessage = ({
  setMessages,
  scrollToBottom,
  chatUserId,
  isSuccess
}: CreateMessageProps) => {
  const currentUserId = localStorage.getItem("profileId");
  const notify = (message) => toast.error(message);
  const dispatch = useDispatch();
  const chats = useSelector((state: ChatType) => state.messages).messengerChats;
  const chatType = useSelector((state: ChatType) => state.messages).chatType;

  useEffect(() => {
    socket.on("message-created", (data) => {
      const cardId = currentUserId === data?.from ? data?.to : data?.from;

      const filteredChats = chats.filter((chat) => chat.user.id !== cardId);
      const changedChat = chats.find((chat) => chat.user.id === cardId);

      if (changedChat) {
        if (chatType === cardId) {
          dispatch(
            setMessengerChats([
              {
                user: changedChat.user,
                lastMessage: { ...data, seenAt: data.createdAt },
              },
              ...filteredChats,
            ])
          );
        } else {
          dispatch(
            setMessengerChats([
              { user: changedChat.user, lastMessage: data },
              ...filteredChats,
            ])
          );
        }
      }

      if (setMessages && scrollToBottom && chatUserId) {
        if (data?.from === chatUserId || data?.to === chatUserId) {
          setMessages((prev) => [...prev, data]);
        }
        scrollToBottom();
      }
    });

    socket.on("message-error", (data) => {
      if (data.error === "Message request not accepted yet") {
        notify(data.error);
      } else {
        notify("Something went wrong. Please try again later.");
      }
    });
    return () => {
      socket.off("message-created");
      socket.off("message-error");
    };
  }, [isSuccess]);
  return <></>;
};

export default CreateMessage;
