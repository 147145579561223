import { logo } from "../../consts/SvgIcon";
import GradientButton from "../Buttons/GradientButton";
import React from "react";
import { useNavigate } from "react-router-dom";

const PublicNavBar = () => {
  const navigate = useNavigate();
  return (
    <div className="nav-landing-white position-relative w-100 gap-4">
      <div onClick={() => navigate("/")} className="cursor-pointer">
        {logo()}
      </div>
      <div className="d-flex landing-nav-buttons-container">
        <div
          className="custom-gradient-button-white-parent"
          style={{
            minWidth: "115px",
          }}
        >
          <div
            className="custom-gradient-button-white"
            onClick={() => navigate("/login")}
          >
            <span className="custom-gradient-button-space-text text-color-black">
              Log In
            </span>
          </div>
        </div>
        <GradientButton
          onClick={() => navigate("/register")}
          text="Sign Up"
          minWidth={"115px"}
          textClassName="gradient-sing-up-text"
          className="gradient-button landing-sign-up-btn"
        />
      </div>
    </div>
  );
};
export default PublicNavBar;
