import React from "react";
import NoChats from "./MessengerPartials/NoChats";
import ChatListCard from "./MessengerPartials/ChatListCard";
import AddChatIcon from "../../../assets/images/addChat.svg";
import { useSelector } from "react-redux";
import { ChatType } from "src/types/redux";
import { ChatListProps } from "src/types/messenger";

const ChatList = ({ setShowSearchModal }: ChatListProps) => {
  const chats = useSelector((state: ChatType) => state.messages).messengerChats;

  return (
    <div className="chat-list-container">
      {chats?.length ? (
        <div className="chats-container">
          <div className="chats-section">
            {chats.map((chat, index) => {
              return <ChatListCard key={index} user={chat} />;
            })}
          </div>
          <div className="add-chats-container">
            <div
              className="add-chat-btn-border"
              onClick={() => setShowSearchModal(true)}
            >
              <div className="add-chat-btn">
                <img src={AddChatIcon} alt="add-chat-icon" className="icon" />
                <span className="add-chat-text">New Message</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="chat-list-container chat-list-padding ">
          <NoChats setShowSearchModal={setShowSearchModal} />
        </div>
      )}
    </div>
  );
};

export default ChatList;
