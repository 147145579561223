import React from "react";
import { ProgressBar } from "react-bootstrap";
import "../../styles/PublicDreamCard.css";
import "../../styles/pages/LandingCard.css";

const CardCarouselSection = (dream) => {
  return (
    <div className="public-dream-card shadow-card rounded-3 d-flex flex-column landing-card">
      <div
        className="profile-bg-card-landing d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: `url(${dream?.dream?.backgroundImage})`,
        }}
      >
        <div className="d-flex justify-content-center align-items-center bg-white rounded-circle mt-1">
          <img
            loading="lazy"
            src={dream?.dream?.coverImage}
            alt={dream?.dream?.alt}
            className="public-dream-user-img-card-landing rounded-circle"
          />
        </div>
      </div>
      <div className="public-dream-card-box-landing text-center d-flex flex-column">
        <div className="public-dream-card-name-desc-box">
          <span className="my-title public-dream-card-user-name">
            {`${dream?.dream?.firstName || "No Name"} 
                        ${dream?.dream?.lastName || ""}`}
          </span>
          <span className="my-span lh-sm public-dream-card-user-dream ">
            {dream?.dream?.title || "No Title"}
          </span>
        </div>
        <div className="d-flex flex-column w-100 gap-2">
          <div className="w-100 d-flex justify-content-between">
            <span className="public-dream-card-fulfilled">Scope</span>
            <span className="fw-bold public-dream-card-fulfilled">
              {Math.round(dream?.dream?.progress) || 0}%
            </span>
          </div>
          <ProgressBar
            className="card-progress-bar"
            now={dream?.dream?.progress}
            variant={"gradient"}
          />
        </div>
      </div>
    </div>
  );
};

export default CardCarouselSection;
