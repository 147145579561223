import React from "react";
import defaultImage from "../../assets/images/defaultUser.png";
import { ReactComponent as Globe } from "../../assets/images/tooltip/globe.svg";
import { ReactComponent as Spark } from "../../assets/images/tooltip/spark.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setChatType } from "src/store/action";
import { socket } from "src/socket";
import { ChatType } from "src/types/redux";

const TooltipAngel = ({ userInfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("profileId");

  const messengerChats = useSelector(
    (state: ChatType) => state.messages
  ).messengerChats.map((chat) => chat.user.id);

  const requestsChats = useSelector(
    (state: ChatType) => state.messages
  ).requestsChats.map((chat) => chat.user.id);

  const addChat = () => {
    socket.emit("create-message", {
      from: currentUserId,
      to: userInfo.id,
      content: "",
    });
    dispatch(setChatType(userInfo.id));
  };

  const chatType = messengerChats.includes(userInfo.id)
    ? userInfo?.id
    : `requests-${userInfo?.id}`;

  const isChat =
    currentUserId === userInfo.id
      ? false
      : messengerChats.includes(userInfo.id) ||
        requestsChats.includes(userInfo.id);
  return (
    <>
      <div className="tooltip-angel-img-box">
        <div className="tooltip-angel-img-border">
          <img
            src={userInfo.mainImageUrl || defaultImage}
            alt="user-img"
            className="tooltip-angel-img"
          />
        </div>
        <div>
          <div
            className="tooltip-name cursor-pointer"
            onClick={() => navigate(`/dashboard/public-page/${userInfo?.id}`)}
          >
            {`${userInfo.firstName || "No Name"} ${userInfo.lastName || ""}`}
          </div>
          <div className="tooltip-angel-badge">Dream Angel</div>
        </div>
      </div>
      <div className="tooltip-user-name-location">
        <div className="tooltip-details">
          {userInfo.city && userInfo.country ? (
            <div className="d-flex tooltip-gap-4">
              <Globe />
              <div>
                Lives in
                <span className="tooltip-600">{` ${userInfo.city}, ${userInfo.country}`}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex tooltip-gap-4">
            <Spark />
            <div>
              Fulfilled{" "}
              <span className="tooltip-600">{`${userInfo.donations.length} Dreams`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="tooltip-user-buttons-box">
        <div
          className="custom-gradient sliders-button tooltip-angel-button-text"
          onClick={() => navigate(`/dashboard/public-page/${userInfo?.id}`)}
        >
          View Profile
        </div>
        {userInfo?.id !== currentUserId && (
          <div
            className="custom-gradient sliders-button"
            onClick={() => {
              navigate("/messenger");
              if (isChat) {
                dispatch(setChatType(chatType));
              } else {
                addChat();
              }
            }}
          >
            <span className="tooltip-message-btn">Message</span>
          </div>
        )}
      </div>
    </>
  );
};
export default TooltipAngel;
