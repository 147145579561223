import React, { useCallback, useState, useEffect } from "react";
import NothingSaved from "./MessengerPartials/NothingSaved";
import SavedChat from "./SavedChat";
import { getSavedPost } from "src/services/api";
import { useInfiniteQuery } from "@tanstack/react-query";
import SavedSectionHeader from "./MessengerPartials/SavedSectionHeader";
import loaderGif from "../../../assets/images/page-img/page-load-loader.gif";
import { useLocation } from "react-router-dom";

const SavedSection = () => {
  const location = useLocation();
  const [allSavedPosts, setAllSavedPosts] = useState([]);

  const fetchSavedPosts = useCallback(async ({ pageParam = 1 }) => {
    return getSavedPost(pageParam);
  }, []);

  const {
    data: savedPosts,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["get-saved-posts", location],
    getNextPageParam: (last) =>
      last.data.meta.hasNextPage ? last.data.meta.page + 1 : undefined,
    queryFn: fetchSavedPosts,
    staleTime: 30000,
    cacheTime: 30000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const loadMore = useCallback(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!isLoading && savedPosts) {
      const newSavedPosts = savedPosts.pages
        .map((page) => page.data.results)
        .flat()
        .reverse();
      setAllSavedPosts(newSavedPosts);

      const scrollEl = document.querySelector(".saved-section-chat");
      if (scrollEl) {
        scrollEl.scrollTop += 100;
      }
    }
  }, [isLoading, savedPosts]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center w-100" key="loader">
        <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
      </div>
    );
  }

  return (
    <div className="saved-section-container">
      <SavedSectionHeader />
      {allSavedPosts.length > 0 ? (
        <SavedChat
          savedPosts={allSavedPosts}
          loadMore={loadMore}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
        />
      ) : (
        <div className="nothing-saved-section-chat">
          <NothingSaved />
        </div>
      )}
    </div>
  );
};

export default SavedSection;
