import React from "react";
import ChatIcon from "../../../../assets/images/chatIcon.svg";
import AddChatIcon from "../../../../assets/images/addChat.svg";

interface NoChatsProps {
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoChats = ({ setShowSearchModal }: NoChatsProps) => {
  return (
    <div className="no-chats-container">
      <div className="no-chats-info">
        <img src={ChatIcon} alt="chat-icon" className="icon" />
        <span className="info-title">No chats yet </span>
        <span className="info-description">
          Break the ice by adding some contacts and shooting off your first
          message
        </span>
      </div>

      <div className="add-chat-btn-border" onClick={() => setShowSearchModal(true)}>
        <div className="add-chat-btn">
          <img src={AddChatIcon} alt="add-chat-icon" className="icon" />
          <span className="add-chat-text">New Message</span>
        </div>
      </div>
    </div>
  );
};

export default NoChats;
