import React, { useEffect, useState } from "react";
import { ReactComponent as Globe } from "../../../../assets/images/tooltip/globe.svg";
import { ReactComponent as Spark } from "../../../../assets/images/tooltip/spark.svg";
import GradientButton from "src/components/Buttons/GradientButton";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { useNavigate } from "react-router-dom";
import { DreamAngelProfileProps } from "src/types/messenger";
import { useDispatch, useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import { useRoleCheck } from "src/hooks/useRoleCheck";
import { setAllProfileFulfilled } from "src/store/users";

const DreamAngelProfile = ({
  user,
  fullfillDream,
  chatUserDream,
}: DreamAngelProfileProps) => {
  const [isFullfilled, setIsFullfilled] = useState<boolean>(true);
  const currentUserFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const hasDream = useRoleCheck(user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatUserDream) {
      const checkIsFullfilled = currentUserFulfilled?.includes(
        chatUserDream.id
      );
      if (checkIsFullfilled) {
        setIsFullfilled(false);
      } else {
        setIsFullfilled(true);
      }
    } else {
      setIsFullfilled(false);
    }
  }, [chatUserDream]);

  return (
    <div className="dream-angel-profile-container">
      <div
        className={`${
          !hasDream
            ? "dream-angel-profile-image-gradient"
            : "dream-angel-profile-image-no-gradient"
        }`}
      >
        <img
          src={user?.mainImageUrl || defaultImage}
          onClick={() => navigate(`/dashboard/public-page/${user?.id}`)}
          alt=""
          className="dream-angel-profile-image-image"
        />
      </div>
      <span
        className="dream-angel-profile-name text-underline"
        onClick={() => navigate(`/dashboard/public-page/${user?.id}`)}
      >{`${user?.firstName} ${user?.lastName}`}</span>
      {!hasDream && (
        <div className="tooltip-angel-badge dream-angel-profile-angel-badge">
          Dream Angel
        </div>
      )}

      <div className="dream-angel-profile-info-container">
        <div className="dream-angel-profile-info">
          <Globe />
          <div>
            <span className="dream-angel-profile-info-static">Lives in </span>
            <span className="dream-angel-profile-info-dynamic">
              {`${user?.city}, ${user?.country}`}
            </span>
          </div>
        </div>
        <div className="dream-angel-profile-info">
          <Spark />
          <div>
            <span className="dream-angel-profile-info-static">Fulfilled </span>
            <span className="dream-angel-profile-info-dynamic">{`${
              user?.donations?.length || 0
            } Dreams`}</span>
          </div>
        </div>
      </div>
      {!hasDream && (
        <span className="dream-angel-profile-description">
          {user?.description}
        </span>
      )}
      <div className="dream-angel-profile-btn-container">
        {isFullfilled && (
          <GradientButton
            text={
              user.gender === "FEMALE"
                ? "Fullfill Her Dream"
                : "Fullfill His Dream"
            }
            minWidth="170px"
            onClick={() => {
              fullfillDream();
              setIsFullfilled(false);
              dispatch(
                setAllProfileFulfilled([
                  ...currentUserFulfilled,
                  chatUserDream.id,
                ])
              );
            }}
            className="dream-angel-gradient-btn"
            textClassName="dream-angel-gradient-btn-text"
          />
        )}
        <div
          className="nothing-saved-btn-gradient"
          style={{ width: "133px", height: "40px" }}
          onClick={() => navigate(`/dashboard/public-page/${user?.id}`)}
        >
          <div className="nothing-saved-btn">
            <span className="nothing-saved-btn-text">View profile</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamAngelProfile;
