import { useEffect } from "react";

export const useDisableBodyScroll = (open) => {
  useEffect(() => {
    const bodyClass = document.body.classList.contains("modal-open");

    if (open && bodyClass) {
      document.body.style.overflow = "hidden";
    } else if (!open && bodyClass) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);
};
