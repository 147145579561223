import { search } from "../../consts/SvgIcon";
import { Offcanvas } from "react-bootstrap";
import React, { useState } from "react";
import SearchBar from "./SearchBar";
import { SearchBarProps } from "src/types/components-props";

const MobileSearchBar = ({
  user,
  allUsers,
  setAllUsers,
  inputValue,
  setInputValue,
  ...props
}: SearchBarProps) => {
  const [show, setShow] = useState(false);

  return (
    <div {...props} className="mobile-search-container">
      <div className="notify-container " onClick={() => setShow(true)}>
        {search()}
      </div>
      <Offcanvas
        show={show}
        className="canvas-container-mobile"
        placement="top"
        onHide={() => {
          setShow(false);
          setAllUsers([]);
          setInputValue("");
        }}
      >
        <SearchBar
          setShowCanvas={setShow}
          user={user}
          mobile={true}
          allUsers={allUsers}
          setAllUsers={setAllUsers}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </Offcanvas>
    </div>
  );
};
export default MobileSearchBar
