import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "@restart/ui/DropdownItem";
import Dropdown from "react-bootstrap/Dropdown";
import React from "react";
import { deleteImg, resizeImg, uploadImg } from "../../consts/SvgIcon";
import {
  deleteCoverImage,
  getUser,
  uploadCoverImage,
} from "../../services/api";
import { setBgLoader, setErrorMessage, setUser } from "../../store/users";
import { useDispatch } from "react-redux";
import { BgDropDownProps } from "src/types/components-props";

const BgDropDown = ({
  imageId,
  toggleHandleDrag,
  returnPosition,
}: BgDropDownProps) => {
  const dispatch = useDispatch();

  const uploadCoverImg = (e) => {
    dispatch(setErrorMessage(""));
    const image = [...e.target.files];
    dispatch(setBgLoader(true));
    uploadCoverImage(image[0]).then(() => {
      getUser().then((response) => {
        dispatch(setUser(response.data));
      });
    });
  };
  const deleteCoverImg = () => {
    deleteCoverImage(imageId).then(() => {
      getUser().then((response) => {
        dispatch(setUser(response.data));
        returnPosition();
      });
    });
  };
  return (
    <Dropdown drop="down" className="d-flex justify-content-end drop-down-bg">
      <DropdownToggle
        className="d-flex align-items-center justify-content-center flex-row pt-0 pb-0 border-0 ps-1 pe-1"
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          borderRadius: "8px",
          zIndex: 100,
          width: "5px",
        }}
      >
        <img
          src="/interact/edit-bg-image.svg"
          alt=""
          className="m-0 fs-6 ms-1 me-1"
        />
      </DropdownToggle>
      <DropdownMenu
        align="end"
        id="dropdown-menu-align-end"
        className="p-2 border-0 shadow menu "
        as="div"
        style={{
          borderRadius: "8px",
          minWidth: "max-content",
        }}
      >
        <DropdownItem as="a" className="d-flex align-items-center dropDownItem">
          <div>
            <input
              className="file-upload"
              style={{
                position: "absolute",
                opacity: 0,
                display: "block",
              }}
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              name="image"
              id="image"
              onChange={uploadCoverImg}
            />
            {uploadImg()}
            <span className="ms-1 fs-6">Upload</span>
          </div>
        </DropdownItem>
        <DropdownItem
          as="a"
          className="d-flex align-items-center dropDownItem"
          onClick={toggleHandleDrag}
        >
          {resizeImg()}
          <span className="ms-1 fs-6">Resize</span>
        </DropdownItem>
        <DropdownItem
          as="a"
          className="d-flex align-items-center dropDownItem"
          onClick={() => deleteCoverImg()}
        >
          {deleteImg()}
          <span className="ms-1 fs-6">Delete</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
export default BgDropDown;
