import { io, Socket } from "socket.io-client";
const user = localStorage.getItem("profileId");

export const socket: Socket = io(process.env.REACT_APP_BASE_URL, {
  transports: ["websocket"],
  auth: {
    authorization: localStorage.getItem("accessToken"),
  },
  query: {
    userId: user,
  },
});
