import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import React from "react";
import "../../styles/BirthdaySlider.css";
import { fulfillCheck } from "src/utils/fulfillCheck";
import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "../Tooltip/TooltipDetails";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const DreamMakersNew = ({ donations, isFulfilled }) => {
  const navigate = useNavigate();
  const tooltipKey = generateTooltipKey(donations?.user?.id);
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  const hasDream = useRoleCheck(donations?.user);

  const isDreamFulfilled = fulfillCheck(
    allFulfilled,
    donations.user.dreams[0],
    donations?.user?.id
  );

  return (
    <div className="dream-maker-card shadow-card rounded-3">
      {hasDream ? (
        <div className="dream-maker-img-box">
          <Link
            to={`/dashboard/public-page/${donations?.user?.id}`}
            style={{ height: "0px" }}
          >
            <div className="dream-maker-user-box" style={{ marginTop: "22px" }}>
              <img
                src={donations?.user?.mainImageUrl || defaultImage}
                alt="profile-img"
                className="dream-maker-avatar"
              />
            </div>
          </Link>
        </div>
      ) : (
        <div className="dream-maker-img-box">
          <Link
            to={`/dashboard/public-page/${donations?.user?.id}`}
            style={{ height: "0px" }}
          >
            <div
              style={{ marginTop: "20px" }}
              className="bg-white birthday-angel-avatar-border"
            >
              <img
                src={donations?.user?.mainImageUrl || defaultImage}
                alt="profile-img"
                className="bg-white dream-maker-angel-box"
              />
            </div>
          </Link>
        </div>
      )}
      <div
        className={
          hasDream
            ? "dream-maker-main-box dream-maker-main-gap"
            : "dream-maker-main-box dream-maker-angel-main-gap"
        }
      >
        <div style={{ height: hasDream ? "61px" : "51px" }}>
          {hasDream ? (
            <>
              <Link to={`/dashboard/public-page/${donations?.user?.id}`}>
                <div
                  style={{
                    height: "20px",
                  }}
                  className="d-flex justify-content-center"
                >
                  <span
                    className="dream-makers-name my-name-span lh-sm text-underline"
                    data-tooltip-id={tooltipKey}
                  >
                    {`${donations?.user?.firstName || "No Name"} ${
                      donations.user?.lastName || ""
                    }`}
                  </span>
                </div>
              </Link>
              <div className="my-span lh-sm dream-maker-dream-description">
                {donations?.user?.dreams[0]?.title || " "}
              </div>
            </>
          ) : (
            <>
              <Link to={`/dashboard/public-page/${donations?.user?.id}`}>
                <div
                  style={{
                    height: "20px",
                  }}
                  className="d-flex justify-content-center"
                >
                  <span
                    className="dream-makers-name my-name-span lh-sm text-underline"
                    data-tooltip-id={tooltipKey}
                  >
                    {`${donations?.user?.firstName || "No Name"} ${
                      donations.user?.lastName || ""
                    }`}
                  </span>
                </div>
              </Link>

              <div className="birthday-user-container">
                <div
                  className="lh-sm text-dark birthday-user-box"
                  style={{ marginTop: "5px" }}
                >
                  <span className="dream-maker-angel-span">Dream Angel</span>
                </div>
              </div>
            </>
          )}
          <TooltipDetails
            userId={donations?.user?.id}
            isFulfilled={isFulfilled}
            allFulfilled={allFulfilled}
            tooltipKey={tooltipKey}
          />
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%", flexDirection: "column" }}
        >
          <div className="d-flex justify-content-between dream-maker-fulfilled-box">
            <div>Fulfilled</div>
            <div style={{ fontWeight: "700" }}>{donations.donationsLength}</div>
          </div>
          {hasDream ? (
            <div
              className="dashboard-card-buttons custom-gradient"
              style={{ width: "100%", height: "40px" }}
              onClick={() =>
                isDreamFulfilled
                  ? navigate(`/dashboard/public-page/${donations?.user?.id}`)
                  : isFulfilled(donations?.id)
              }
            >
              <div className="dashboard-card-buttons-text">
                <span>
                  {isDreamFulfilled
                    ? "View Profile"
                    : `Fulfill ${
                        donations?.user?.gender === "FEMALE" ? "Her" : "His"
                      } Dream`}
                </span>
              </div>
            </div>
          ) : (
            <Link
              to={`/dashboard/public-page/${donations?.user?.id}`}
              style={{ height: "0px" }}
            >
              <div
                className="dashboard-card-buttons custom-gradient"
                style={{ width: "100%", height: "40px" }}
              >
                <div className="dashboard-card-buttons-text">
                  <span>View Profile</span>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
export default DreamMakersNew;
