import React from "react";
import useIsMobile from "src/hooks/useIsMobile";

interface CustomSelectDropdownProps {
  options: string[];
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
  parentSelectWidth: number;
}

const CustomSelectDropdown = ({
  options,
  selectedOption,
  setSelectedOption,
  setClose,
  parentSelectWidth
}: CustomSelectDropdownProps) => {
  const isMobile = useIsMobile(390);

  return (
    <div className="dropdown-bg">
      <div
        className="dropdown-bg-transparent"
        onClick={() => setClose(false)}
      ></div>
      <div
        className="custom-select-dropdown-container"
        style={{ width: isMobile ? parentSelectWidth : "" }}
      >
        {options.map((option, index) => {
          const selected = selectedOption === option;
          const isLastElement = index === options.length - 1;

          return (
            <div
              onClick={() => {
                setClose(false);
                setSelectedOption(option);
              }}
              className={`dropdown-option ${
                selected ? "dropdown-option-selected" : ""
              }`}
              style={{ marginBottom: isLastElement ? "12px" : "" }}
              key={index}
            >
              <span
                className={
                  selected
                    ? "dropdown-selected-option-text"
                    : "dropdown-option-text"
                }
              >
                {option}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomSelectDropdown;
