import React from "react";
import SavedIcon from "../../../../assets/images/savedIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setChatType } from "src/store/action";
import { ChatType } from "src/types/redux";

const ChatListSaved = () => {
  const dispatch = useDispatch();
  const chatType = useSelector((state: ChatType) => state.messages).chatType;

  return (
    <div
      className="saved-container"
      onClick={() => {
        dispatch(setChatType("saved"));
      }}
    >
      <div
        className={`saved-container-hover ${
          chatType === "saved" ? "selected-saved" : ""
        }`}
      >
        <div className="saved-icon-btn">
          <img src={SavedIcon} alt="saved-icon" className="icon" />
        </div>
        <span className="saved-header-text">Saved</span>
      </div>
    </div>
  );
};

export default ChatListSaved;
