import React from "react";
import arrowLeft from "../../../assets/images/grayArrowLeft.svg";
import NoChats from "./MessengerPartials/NoChats";
import { useDispatch, useSelector } from "react-redux";
import { setChatType } from "src/store/action";
import ChatListCard from "./MessengerPartials/ChatListCard";
import AddChatIcon from "../../../assets/images/addChat.svg";
import { ChatType } from "src/types/redux";
import { ChatRequestsListSectionProps } from "src/types/messenger";

const ChatRequestsListSection = ({
  setShowSearchModal,
}: ChatRequestsListSectionProps) => {
  const dispatch = useDispatch();
  const chats = useSelector((state: ChatType) => state.messages).requestsChats;

  return (
    <div className="chat-list">
      <div className="list-header-container">
        <div className="back-btn-container">
          <div
            className="back-arrow"
            onClick={() => {
              dispatch(setChatType(""));
            }}
          >
            <img src={arrowLeft} alt="arrow" className="back-arrow-icon" />
          </div>
          <span className="back-btn-text">Message Requests</span>
        </div>
      </div>

      <div className="chat-list-container  chat-list-container-request-height">
        {chats?.length > 0 ? (
          <div className="chats-container-request">
            <div
              className="chats-section-request"
              
            >
              {chats.map((chat, index) => {
                if (chat) {
                  return <ChatListCard key={index} user={chat} request />;
                } else {
                  return "";
                }
              })}
            </div>
            <div className="add-chats-container">
              <div
                className="add-chat-btn-border"
                onClick={() => setShowSearchModal(true)}
              >
                <div className="add-chat-btn">
                  <img src={AddChatIcon} alt="add-chat-icon" className="icon" />
                  <span className="add-chat-text">New Message</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NoChats setShowSearchModal={setShowSearchModal} />
        )}
      </div>
    </div>
  );
};

export default ChatRequestsListSection;
