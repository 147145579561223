import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Dashboard.css";
import "../../styles/ProfilePageNavbar.css";
import BigImageModal from "../../components/Partials/BigImageModal";
import { Col, Container, Row, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// images
import BgDropDown from "../../components/Partials/BgDropDown";
import Draggable from "react-draggable";
import SimpleImageModal from "../../components/Partials/SimpleImageModal";
import {
  getAllProfileDonations,
  getDreamDonations,
  getProfileDream,
  getProfileFulfilled,
  getProfileWingDonations,
  getUser,
  imagePosition,
  fulfill,
} from "../../services/api";
import {
  setAllProfileFulfilled,
  setDream,
  setProfileFulfilled,
  setProfileReceived,
  setProfileWingDonations,
  setUser,
} from "../../store/users";
import useSharedTab from "../../hooks/useSharedTab";
import ProfileNavBar from "../../components/Profile/ProfileNavBar";
import useIsMobile from "../../hooks/useIsMobile";
import DreamPhotoNew from "../../components/Profile/DreamPhotoNew";
import ProfileDreamDetails from "../../components/Profile/ProfileDreamDetails";
import DreamMakersSlider from "../../components/Profile/DreamMakersSlider";
import MissingData from "../../components/Profile/MissingData";
import CommentsNew from "../../components/Comments/CommentsNew";
import { MISSING_DATA_ENUM } from "../../consts/missingDataEnum";
import UserAbout from "../../components/Profile/About/UserAbout";
import { UsersModule } from "src/types/redux";
import EditProfileModal from "src/components/Profile/About/EditProfileModal";
import ProfileFulfilled from "src/components/Profile/ProfileFulfilled";
import ProfileReceived from "src/components/Profile/ProfileReceived";
import { toast } from "react-toastify";
import ActivityPage from "src/components/Profile/Activity/ActivityPage";
import { DreamDto } from "src/services/api/data-contracts";
import { setActiveReply } from "src/store/action";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const UserProfileNew = () => {
  const notify = (message) => toast.success(message);
  const [customModals, setCustomModal] = useState("");
  const [dreamId, setDreamId] = useState("");
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const dream = useSelector((state: UsersModule) => state.usersModule)
    .dream as DreamDto;
  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const coverImage = useSelector((state: UsersModule) => state.usersModule).user
    .coverImage;
  const [imageId, setImageId] = useState<string | boolean>(false);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [coverImageHeight, setImageHeight] = useState(0);
  const childRef = useState(null);
  const [mainBigImage, setMainBigImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const coverContainer = useRef(null);
  const coverImageRef = useRef(null);
  const draggbleDraggble = useRef(null);
  const dispatch = useDispatch();
  const fulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).profileFulfilled;
  const profileReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).profileReceived;
  const wingDonationsProfile = useSelector(
    (state: UsersModule) => state.usersModule
  ).profileWingDonations;
  const [coverImagePosition, setCoverImagePosition] = useState(0);
  const [bigImages, setBigImages] = useState(false);
  const [currnetIndex, setIndex] = useState(null);
  const { tab, setTabCallback } = useSharedTab();
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const hasDream = useRoleCheck(user);

  const saveImagePosition = () => {
    const position = Number(Math.abs(draggbleDraggble.current.state.y));
    imagePosition(user?.coverImage?.id, position);
    setCoverImagePosition(Math.abs(draggbleDraggble.current.state.y));
    draggbleDraggble.current.state.y = 0;
    setDisabled((prev) => !prev);
  };

  const cancelPosition = () => {
    draggbleDraggble.current.state.y = 0;
    setCoverImagePosition((prev) => prev);
    setDisabled((prev) => !prev);
  };

  const returnPosition = () => {
    setCoverImagePosition(0);
  };

  const handleBigImages = (index) => {
    setBigImages(true);
    setIndex(index);
  };

  const setMouseLeave = () => {
    setImageId(false);
    document.querySelectorAll(".menu").forEach((el) => {
      el.classList.remove("show");
    });
  };
  const handleMainBigImage = (img) => {
    setImageUrl(img);
    setMainBigImage(true);
  };

  const imgSrc = typeof mainImg === "string" ? mainImg : mainImg.url;

  useEffect(() => {
    setCoverImagePosition(coverImage?.position || 0);
  }, []);

  useEffect(() => {
    if (user?.id) {
      if (!user?.firstName) {
        navigate("/user-profile/edit-form");
      }
    }
    getProfileDream().then((res) => {
      dispatch(setDream(res?.data));
      if (res?.data?.results?.[0]?.id) {
        getDreamDonations(res.data?.results?.[0]?.id, 1).then((response) => {
          dispatch(setProfileReceived(response?.data));
        });
      }
    });
    getAllProfileDonations().then((res) => {
      dispatch(setAllProfileFulfilled(res?.data));
    });
    getProfileFulfilled(1).then((res) => {
      dispatch(setProfileFulfilled(res.data));
    });
    getProfileWingDonations(1).then((res) => {
      dispatch(setProfileWingDonations(res.data));
    });
  }, []);

  useEffect(() => {
    setImageHeight(coverImageRef?.current.clientHeight);
  }, [mainImg, disabled]);

  useEffect(() => {
    return () => {
      dispatch(setActiveReply(""));
      document.body.classList.remove("remove-scroll");
      getUser().then((response) => {
        dispatch(setUser(response?.data));
      });
    };
  }, []);

  const toggleHandleDrag = () => {
    if (coverImage) {
      draggbleDraggble.current.state.y = -coverImagePosition;
      setDisabled((prev) => !prev);
    }
  };

  const isMobile = useIsMobile(768);

  const defalutMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const isFulfilled = (id: string) => {
    if (!user?.balance) {
      setDreamId(id);
      setCustomModal("priceModal");
    } else {
      fulfill(id).then((res) => {
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getUser().then(({ data }) => {
          dispatch(setUser(data));
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
      });
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-center p-0 center-container"
        style={{ backgroundColor: "rgba(241, 241, 241, 1)" }}
      >
        <Row className="d-flex justify-content-center w-100 m-0">
          <Col
            sm={12}
            className="card-mobile"
            style={{ backgroundColor: "white" }}
          >
            {mainBigImage && (
              <SimpleImageModal
                childRef={childRef}
                drambigImages={setMainBigImage}
                imageUrl={imageUrl}
              />
            )}
            <div className="profile-header mb-0 bg-img-top">
              <div
                onMouseOver={() =>
                  user.coverImage?.id
                    ? setImageId(user.coverImage?.id)
                    : setImageId(true)
                }
                onMouseLeave={() => setMouseLeave()}
                className="position-relative user-page-bg-img"
                ref={coverContainer}
                style={{ overflow: "hidden", borderRadius: "8px" }}
              >
                {disabled ? (
                  <BgDropDown
                    toggleHandleDrag={toggleHandleDrag}
                    returnPosition={returnPosition}
                    imageId={imageId}
                  />
                ) : (
                  <div className="d-flex justify-content-end set-drop-down">
                    <div
                      onClick={saveImagePosition}
                      className="d-flex align-items-center justify-content-center profile-photo-drag-save"
                    >
                      Save Changes
                    </div>
                    <div
                      onClick={(e) => cancelPosition()}
                      className="d-flex align-items-center justify-content-center profile-photo-drag-cancel"
                    >
                      Cancel
                    </div>
                  </div>
                )}
                <Draggable
                  disabled={disabled}
                  ref={draggbleDraggble}
                  onMouseDown={(e) => defalutMove(e)}
                  nodeRef={coverImageRef}
                  bounds={{
                    top: -`${coverImageHeight / 2}`,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <img
                    style={{
                      position: `${
                        disabled && coverImage?.url ? "absolute" : "absolute"
                      }`,
                      top: Number(
                        `${disabled && coverImage ? -coverImagePosition : 0}`
                      ),
                      width: "100%",
                      objectFit: "cover",
                      cursor: `${!disabled ? "move" : "pointer"}`,
                      background: "transparent",
                    }}
                    ref={coverImageRef}
                    src={coverImage?.url || "/coverImg.png"}
                    alt="profile-bg"
                    className={
                      coverImage?.url
                        ? "rounded img-fluid height-auto"
                        : "rounded img-fluid profile-profile-bg"
                    }
                    onClick={() =>
                      disabled && coverImage
                        ? handleMainBigImage(coverImage?.url)
                        : null
                    }
                  />
                </Draggable>
              </div>
              <div className="profile-img d-flex justify-content-center public-main-image">
                <div
                  className={
                    hasDream
                      ? "bg-white border-radius-img"
                      : " bg-white profile-angel-img-border"
                  }
                >
                  <div className="profile-img-border">
                    <img
                      src={imgSrc}
                      alt="profile-img"
                      className="avatar-130 img-fluid border-0"
                      onClick={() =>
                        mainImg?.url
                          ? handleBigImages(
                              user?.images?.findIndex((el) => el.isMain)
                            )
                          : null
                      }
                    />
                  </div>
                  {bigImages && (
                    <BigImageModal
                      dream={user}
                      index={currnetIndex}
                      openBigImages={setBigImages}
                    />
                  )}
                </div>
              </div>
              <div className="profile-name-fulfill-box">
              {dream?.id ? (
                <div
                  className="d-flex justify-content-around "
                  style={{
                    color: "#4F4F4F",
                    cursor: "default",
                    margin: "0 auto",
                  }}
                >
                  <div className="profile-user-center-info">
                    <div className="angel-fulfilled-box">
                      <img
                        src="/interact/fulfilled-spark.svg"
                        alt=""
                        style={{
                          width: "17px",
                          display: isMobile ? "none" : "inline-block",
                        }}
                      />
                      <span>Received </span>
                      <span>{profileReceived?.meta?.itemCount || "0"}</span>
                    </div>
                    <div className="angel-fulfilled-box">
                      <img
                        src="/interact/fulfilled-spark.svg"
                        alt=""
                        style={{ width: "17px" }}
                      />
                      <span>Fulfilled </span>
                      <span>{fulfilled?.meta?.itemCount || "0"}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
              <div
                className="d-flex justify-content-center text-center text-center m-2 mb-0"
                style={{ paddingTop: "5px" }}
              >
                <h3 className="fw-bold profile-user-name">
                  {user?.firstName || "No name"} {user?.lastName}
                </h3>
                </div>
              </div>
              {!dream?.id ? (
                <div
                  className="d-flex justify-content-center text-center"
                  style={{ gap: "10px", marginTop: "6px", marginBottom: "10px" }}
                >
                  <div className="profile-angel-badge">
                    <p>Dream Angel</p>
                  </div>
                  <div className="angel-fulfilled-box">
                    <img
                      src="/interact/fulfilled-spark.svg"
                      alt=""
                      style={{ width: "17px" }}
                    />
                    <span>Fulfilled </span>
                    <span>{fulfilled?.meta?.itemCount || "0"}</span>
                  </div>
                </div>
              ) : (
                <> </>
              )}
              <div
                className={`d-flex justify-content-center ${dream?.id ? "edit-profile-button-container-dream" : "edit-profile-button-container-angel"}`}
              >
                <div onClick={handleShowModal} className="edit-profile-button">
                  <img
                    src="/interact/edit-profile.svg"
                    alt=""
                    style={{ width: "20px" }}
                  />
                  <span>edit profile</span>
                </div>
                <EditProfileModal
                  show={showModal}
                  handleClose={handleCloseModal}
                  user={user}
                  dream={dream}
                />
              </div>
            </div>
          </Col>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={"third"}
            activeKey={tab}
          >
            <div className="d-flex justify-content-center nav-back-color">
              <div className="p-0 mt-3 shadow-none profile-navbar">
                <ProfileNavBar
                  setTabCallback={setTabCallback}
                  userData={user}
                  profileType={"owner"}
                ></ProfileNavBar>
              </div>
            </div>
            <Col sm={12} className="p-0">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {!dream?.id || dream.progress === 100 ? (
                    <div className="d-flex justify-content-center">
                      <MissingData
                        type={MISSING_DATA_ENUM.USER_DREAM}
                      ></MissingData>
                    </div>
                  ) : (
                    <>
                      <Container
                        style={{
                          maxWidth: "860px",
                          alignItems: "center",
                          flexDirection: "column",
                          display: "flex",
                        }}
                      >
                        <DreamPhotoNew dream={dream} type="user" />
                        <ProfileDreamDetails
                          received={profileReceived?.meta?.itemCount}
                          dream={dream}
                          type="user"
                        />
                        <div className="dream-comments-box mb-3">
                          <CommentsNew
                            user={user}
                            dreamId={dream?.id}
                            mainImg={mainImg}
                            dream={dream}
                            isFulfilled={isFulfilled}
                          />
                        </div>
                      </Container>
                      {!!profileReceived?.meta?.itemCount ? (
                        <DreamMakersSlider
                          donations={profileReceived.results}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="activity"
                  >
                    <div className="d-flex justify-content-center">
                      <ActivityPage user={user} isFulfilled={isFulfilled} />
                    </div>
                  </Tab.Container>
                </Tab.Pane>

                <Tab.Pane eventKey="third">
                  <Container
                    style={{
                      maxWidth: "860px",
                      alignItems: "center",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <UserAbout
                      user={user}
                      type="user"
                      dream={dream}
                    ></UserAbout>
                  </Container>
                </Tab.Pane>

                <Tab.Pane eventKey="fourth" className="ps-2 pe-2">
                  <ProfileFulfilled
                    received={fulfilled?.results}
                    wings={wingDonationsProfile?.results}
                    hasMoreWing={wingDonationsProfile?.meta?.hasNextPage}
                    hasMoreFulfilled={fulfilled?.meta?.hasNextPage}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className="ps-2 pe-2">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                    <ProfileReceived received={profileReceived} dream={dream} />
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Row>
      </div>
    </>
  );
};

export default UserProfileNew;
