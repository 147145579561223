import { Modal } from "react-bootstrap";
import { close } from "../../consts/SvgIcon";
import React, { useEffect, useRef, useState } from "react";
import {
  getPricesId,
  getSubscription,
} from "../../services/api";
import { PricingModalProps } from "src/types/pages";
import useScrollDrag from "src/hooks/useScrollDrag";
import rightArrow from "../../assets/images/payment-scroll-right-arrow.svg";
import leftArrow from "../../assets/images/payment-scroll-left-arrow.svg";
import { defaultPaymentPlans } from "src/consts/payments";
import PaymentCards from "src/components/Onboarding/partials/PaymentCards";

const PricingModal = ({
  setCustomModal,
  customModals,
  dreamId,
}: PricingModalProps) => {
  const [paymentPlans, setPaymentPlans] = useState(defaultPaymentPlans);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<any>();
  const scrollContainerRef = useRef(null);

  const {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    scrollByAmount,
    showLeftArrow,
    showRightArrow,
  } = useScrollDrag(scrollContainerRef);

  useEffect(() => {
    getPricesId().then((response) => {
      const plansWithIds = defaultPaymentPlans.map((plan) => {
        const priceIdKey = plan.priceId;
        const priceId = response.data[priceIdKey];
        return { ...plan, id: priceId };
      });
      setPaymentPlans(plansWithIds);
    });

    getSubscription().then((res) => {
      if (res.data.data.length) {
        const activeSubscription = res.data.data.find(
          (subscription) => subscription.status === "active"
        );
        setSelectedPaymentPlan(activeSubscription);
      }
    });
  }, []);

  return (
    <Modal
      size="lg"
      backdrop={true}
      centered
      show={!!customModals}
      onHide={() => setCustomModal("")}
      animation={true}
      contentClassName="border-0"
      style={{ borderRadius: "8px" }}
    >
      <div className="price-modal-img d-flex flex-column justify-content-start price-modal-container">
        <div className="d-flex flex-row justify-content-between price-modal-info">
          <div style={{ cursor: "default" }} className="pricing-text">
            <h5>Pricing</h5>
          </div>
          <div
            onClick={() => setCustomModal("")}
            className="mb-2"
            style={{ cursor: "pointer" }}
          >
            {close()}
          </div>
        </div>
        <div className="payment-carousel-container">
        <div className="payment-carousel-arrow-container">
          {showLeftArrow && (
            <>
              <div className="payment-cards-scroll-left-btn">
                <img
                  src={rightArrow}
                  alt="icon"
                  onClick={() => scrollByAmount(-250)}
                  className="payment-cards-scroll-icon"
                />
              </div>
              <div className="full-container"></div>
            </>
          )}
          {showRightArrow && (
            <>
              <div className="full-container"></div>

              <div className="payment-cards-scroll-right-btn">
                <img
                  src={leftArrow}
                  alt="icon"
                  onClick={() => scrollByAmount(250)}
                  className="payment-cards-scroll-icon"
                />
              </div>
            </>
          )}
        </div>
        <div
          className="payment-cards-scroll"
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{marginLeft: showLeftArrow ? "" : "20px", marginRight: showRightArrow ? "" : "20px"}}
        >
          {paymentPlans.map((plan, index) => {
            let selectedCard;
            if (selectedPaymentPlan) {
              selectedCard = plan.id === selectedPaymentPlan?.plan?.id;
            }
            if (index === 4 && selectedPaymentPlan) {
              const plansId = paymentPlans.find((plan) => plan.id === selectedPaymentPlan?.plan?.id)
              selectedCard = plansId === undefined
            }
            const mostChosen = !selectedPaymentPlan && index === 1;
            return (
              <PaymentCards
                plan={plan}
                index={index}
                key={index}
                selectedPaymentPlan={selectedCard}
                setSelectedPaymentPlan={setSelectedPaymentPlan}
                mostChosen={mostChosen}
                paymentPlan={selectedPaymentPlan}
              />
            );
          })}
        </div>
      </div>
      </div>
    </Modal>
  );
};
export default PricingModal;
