import React, { useEffect, useRef, useState } from "react";
import arrowUp from "../../../assets/images/arrow-up-icon.svg";
import arrowDown from "../../../assets/images/arrow-down-icon.svg";
import phoneIcon from "../../../assets/images/phone-icon.svg";
import PhonePrefixesDropdown from "./PhonePrefixesDropdown";
import countriesData from "../../../consts/countriesData.json";
import { CountryInterface } from "src/types/onboarding";
import { ErrorInfo } from "../../../consts/SvgIcon";
import useContainerWidth from "src/hooks/useContainerWidth";

interface PhoneNumberSelectProps {
  selectedNumber: string;
  setSelectedNumber: React.Dispatch<React.SetStateAction<string>>;
  isDisabled?: boolean;
  disabledValue?: string;
}

const PhoneNumberSelect = ({
  selectedNumber,
  setSelectedNumber,
  isDisabled,
  disabledValue,
}: PhoneNumberSelectProps) => {
  const [numberPrefixFocus, setNumberPrefixFocus] = useState<boolean>(false);
  const [numberFocus, setNumberFocus] = useState<boolean>(false);

  const [numberPrefix, setNumberPrefix] = useState<string>("");
  const [number, setNumber] = useState<string>("");

  const [timer, setTimer] = useState(null);
  const [countries, setCountries] = useState<CountryInterface[]>(countriesData);

  const [isNumberPrefixError, setIsNumberPrefixError] =
    useState<boolean>(false);
  const [isNumberError, setNumberIsError] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const parentSelectWidth = useContainerWidth(containerRef);

  const handleChange = (e) => {
    let inputValue = e.target.value;

    if (!inputValue.startsWith("+")) {
      inputValue = "+" + inputValue;
    }

    const isValid = /^\+\d*$/.test(inputValue);
    if (isValid) {
      setNumberPrefix(inputValue);
    }

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        if (inputValue) {
          const searchedCountries = countriesData.filter((country) =>
            country.numberPrefix.slice(1).includes(inputValue.slice(1))
          );
          setCountries(searchedCountries);
        } else {
          setCountries(countriesData);
        }
      }, 1000)
    );
  };

  useEffect(() => {
    setSelectedNumber(numberPrefix + number);

    const isNumberPrefix = countries.find(
      (country) => country.numberPrefix === numberPrefix
    );

    if (
      isNumberPrefix !== undefined ||
      numberPrefix === "+" ||
      numberPrefix === ""
    ) {
      setIsNumberPrefixError(false);
    } else {
      setIsNumberPrefixError(true);
    }

    if (number.length + numberPrefix.length > 7 || numberPrefix === "") {
      setNumberIsError(false);
    } else {
      setNumberIsError(true);
    }

    if (number !== "" && (numberPrefix === "+" || numberPrefix === "")) {
      setIsNumberPrefixError(true);
    }

    if (
      (numberPrefix === "+" || numberPrefix === "") &&
      number.length + numberPrefix.length > 7
    ) {
      setNumberIsError(true);
    }
  }, [numberPrefix, number]);

  return (
    <div className="phone-number-container" ref={containerRef}>
      <span className="input-description">Phone number*</span>
      {isDisabled ? (
        <div className="disabled-email-input">
          <span className="gray-description">{disabledValue}</span>
          <img src={phoneIcon} alt="message" />
        </div>
      ) : (
        <div className="insert-container">
          <div
            className={`number-prefix 
              ${isNumberPrefixError ? "custom-input-error" : ""}
              ${numberPrefixFocus ? "number-input-focus" : ""}
          `}
            style={{ width: `${numberPrefix.length * 11 + 24}px` }}
          >
            <input
              type="text"
              placeholder="+1"
              value={numberPrefix}
              maxLength={8}
              className="prefix-number-input"
              onChange={(e) => handleChange(e)}
              onFocus={() => setNumberPrefixFocus(true)}
              style={{ width: `${numberPrefix.length * 12}px` }}
            />
            <img
              src={numberPrefixFocus ? arrowDown : arrowUp}
              alt="arrow-icon"
            />
          </div>

          <div
            className={`number-input-container 
              ${isNumberError ? "custom-input-error" : ""}
              ${numberFocus ? "number-input-focus" : ""}  
          `}
          >
            <input
              type="text"
              className="number-input"
              value={number}
              onChange={(e) => {
                const onlyNumbers = e.target.value.replace(/\D/g, "");
                setNumber(onlyNumbers);
              }}
              onFocus={() => setNumberFocus(true)}
              onBlur={() => setNumberFocus(false)}
            />
            <img src={phoneIcon} alt="phone" className="phone-icon" />
          </div>
        </div>
      )}
      {(isNumberPrefixError || isNumberError) && (
        <div className="error-info-container">
          <div className="error-info-icon">{ErrorInfo()}</div>
          <span className="error-message">Please enter a valid number</span>
        </div>
      )}
      {numberPrefixFocus && countries.length > 0 && (
        <PhonePrefixesDropdown
          countries={countries}
          setPrefix={setNumberPrefix}
          setClose={setNumberPrefixFocus}
          parentSelectWidth={parentSelectWidth}
        />
      )}
    </div>
  );
};

export default PhoneNumberSelect;
