import React from "react";
import SavedIcon from "../../../../assets/images/savedIcon.svg";
import { useNavigate } from "react-router-dom";

const NothingSaved = () => {
  const navigate = useNavigate();
  return (
    <div className="nothing-saved-container">
      <div className="saved-icon-btn nothing-saved-circle">
        <img src={SavedIcon} alt="saved-icon" className="nothing-saved-icon" />
      </div>
      <div className="nothing-saved-container-info">
        <span className="nothing-saved-title">Nothing Saved Yet</span>
        <span className="nothing-saved-description">
          Start saving posts. Saved posts will show up here.
        </span>
      </div>
      <div
        className="nothing-saved-btn-gradient"
        onClick={() => navigate("/newsfeed")}
      >
        <div className="nothing-saved-btn">
          <span className="nothing-saved-btn-text">Go to feed</span>
        </div>
      </div>
    </div>
  );
};

export default NothingSaved;
