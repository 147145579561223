export const successUrl =
  process.env.REACT_APP_ENV === "dev"
    ? "http://dev.dreamerz.net/newsfeed?subscription=success?showModal=true"
    : "https://dreamerz.net/newsfeed?subscription=success?showModal=true";

export const cancelUrl = "https://dreamerz.net/welcome?subscription=canceled";

export const defaultPaymentPlans = [
  {
    category: "Dreamer",
    price: "11",
    descriptionPoints: [
      "Fulfill 10 dreams every month",
      "Donate and collect one fulfilled dream every 3 days ",
      "Gain 10x visibility with each fulfilled dream",
      "Get recognized by a community that values your contributions",
    ],
    priceId: "basicPrice",
    id: "",
  },
  {
    category: "Visionary",
    price: "44",
    descriptionPoints: [
      "Fulfill 40 dreams every month",
      "Donate and collect 1 fulfilled dream daily",
      "Gain 15x visibility with each fulfilled dream",
      "Show your support level in community updates",
    ],
    priceId: "bestPrice",
    id: "",
  },
  {
    category: "Innovator",
    price: "88",
    descriptionPoints: [
      "Fulfill 80 dreams every month",
      "Donate and collect 3 fulfilled dreams daily",
      "Gain 20x visibility with each fulfilled dream",
      "Show your support level with a recognition badge",
      "Accelerate your dream",
    ],
    priceId: "newStandardPrice",
    id: "",
  },
  {
    category: "Dream Angel Legacy",
    price: "440",
    descriptionPoints: [
      "Fulfill 400 dreams every month",
      "Donate and collect 14 fulfilled dreams daily",
      "Gain 25x visibility with each fulfilled dream",
      "Show your support level with a recognition badge",
      "Earn prominent recognition in the community",
    ],
    priceId: "angelPrice",
    id: "",
  },
  {
    category: "Dream Architect",
    price: "custom",
    descriptionPoints: [
      "Increase your impact even more. Get exclusive invitations to participate in special Dreamerz initiatives and forums, adds and promotions.",
    ],
    custom: true,
    id: "",
  },
];
