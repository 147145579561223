import React from "react";
import Carousel from "react-multi-carousel";
import { leftArrow, rightArrow } from "../../consts/SvgIcon";
import founder from "../../assets/images/ceoFounder.png";
import designer from "../../assets/images/design.png";
import marketing from "../../assets/images/marketing.png";

const ButtonGroupLeft = (e) => (
  <div
    className="carousel-button-group cursor-pointer"
    onClick={() => e.onClick()}
  >
    <div className="left-button margin-l">{leftArrow()}</div>
  </div>
);

const ButtonGroupRight = (e) => (
  <div
    className="carousel-button-group cursor-pointer"
    onClick={() => e.onClick()}
  >
    <div className="right-button margin-r">{rightArrow()}</div>
  </div>
);

const founders = [
  {
    image: founder,
    name: "John Graur",
    function: "CEO - FOUNDER",
    description:
      "I strongly believe that Dreamerz is a powerful force that brings transformative change to how people live, think, and connect worldwide. At the very heart of our mission is the potent ability to bring together individuals from diverse backgrounds, all united by a shared purpose: to achieve their dreams while uplifting those in need.",
  },
  {
    image: designer,
    name: "Alex Rudenco",
    function: "Head of Design",
    description:
      " I am proud to be part of a project that helps people not only to create dreams and believe in them but also to make them real by helping each other. I am looking forward to see how far this project will go.",
  },
  {
    image: marketing,
    name: "Maria Ferbei",
    function: "Head of Marketing",
    description:
      "I hope Dreamerz becomes a source of inspiration, igniting ambitions in everyone’s hearts. Let it bring dreamers together from around the world, creating a supportive community where dreams come true.",
  },
];

const responsiveFounder = {
  desktop: {
    breakpoint: { max: 3000, min: 660 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 660, min: 414 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 414, min: 200 },
    items: 1,
    slidesToSlide: 1,
  },
};

const TeamTestimonialsSection = () => (
  <div className=" d-flex flex-column align-items-center justify-content-center founder-container">
    <p className="wing-title-section text-center">Our Team Thoughts</p>
    <Carousel
      responsive={responsiveFounder}
      centerMode={window.innerWidth > 700}
      className="carousel-height-founder-desktop"
      keyBoardControl
      infinite
      renderArrowsWhenDisabled
      itemClass="card-responsive d-flex align-items-center justify-content-center"
      renderButtonGroupOutside={true}
      customLeftArrow={<ButtonGroupLeft />}
      customRightArrow={<ButtonGroupRight />}
      removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {founders.map((card) => {
        return (
          <div key={card.image} className="card-carousel">
            <img
              src={card.image}
              loading="lazy"
              alt="card"
              className="image-card"
            />
            <div className="d-flex flex-column align-items-center team-section-info-container">
              <span className="team-section-user-name">{card.name}</span>
              <div className="gr-bg">
                <span className="team-section-user-function">
                  {card.function}
                </span>
              </div>
              <span className="st-description text-center">
                {card.description}
              </span>
            </div>
          </div>
        );
      })}
    </Carousel>
  </div>
);

export default TeamTestimonialsSection;
