import moment from "moment";
import { MessageDto } from "src/services/api/data-contracts";

export const calculateMessageBorderRadius = (
  messages: MessageDto[],
  index: number,
  myMessage: boolean
) => {
  let borderRadius = "8px 8px 8px 8px";
  let isBatch = false;
  const message = messages[index];

  const messageTime = message?.createdAt;
  const nextMessageTime = messages[index + 1]?.createdAt;
  const prevMessageTime = messages[index - 1]?.createdAt;

  const prevMessage = messages[index - 1];
  const nextMessage = messages[index + 1];

  const isPrevDifferentHour =
    moment(messageTime).get("hour") !== moment(prevMessageTime).get("hour");
  const isNextDifferentHour =
    moment(messageTime).get("hour") !== moment(nextMessageTime).get("hour");

  const isPrevAlsoMinute = isPrevDifferentHour
    ? false
    : prevMessageTime && prevMessage?.from === message?.from
    ? moment(messageTime).diff(moment(prevMessageTime), "minute") <= 5
    : false;

  const isNextAlsoMinute = isNextDifferentHour
    ? false
    : nextMessageTime && nextMessage?.from === message?.from
    ? moment(nextMessageTime).diff(moment(messageTime), "minute") <= 5
    : false;

  if (isPrevAlsoMinute && !isNextAlsoMinute) {
    borderRadius = myMessage ? "8px 0px 8px 8px" : "0px 8px 8px 8px";
    isBatch = true;
  }
  if (!isPrevAlsoMinute && isNextAlsoMinute) {
    borderRadius = myMessage ? "8px 8px 0px 8px" : "8px 8px 8px 0px";
  }

  if (isPrevAlsoMinute && isNextAlsoMinute) {
    borderRadius = myMessage ? "8px 0px 0px 8px" : "0px 8px 8px 0px";
    isBatch = true;
  }

  return { borderRadius, isBatch };
};
