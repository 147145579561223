import React, { useEffect, useState } from "react";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { useDispatch, useSelector } from "react-redux";
import { setChatType } from "src/store/action";
import { ChatType } from "src/types/redux";
import { ChatListCardProps } from "src/types/messenger";
import point from "../../../../assets/images/time-point.svg";
import { formatTimeDuration } from "src/utils/formatTimeDuration";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const ChatListCard = ({ user, request }: ChatListCardProps) => {
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("profileId");
  const chatType = useSelector((state: ChatType) => state.messages).chatType;
  const [wasSeen, setWasSeen] = useState<boolean>(false);
  const [timeString, setTimeString] = useState<string>("");
  const hasDream = useRoleCheck(user?.user);

  const username =
    (user?.user?.firstName || "No name") + " " + (user?.user?.lastName || "");

  const lastMessage =
    user?.lastMessage?.fromUser?.id === currentUserId
      ? `You: ${
          user?.lastMessage?.content === null
            ? "sent a post"
            : user?.lastMessage?.content
        }`
      : `${user?.lastMessage?.fromUser?.firstName}: ${
          user?.lastMessage?.content === null
            ? "sent a post"
            : user?.lastMessage?.content
        }`;

  const cardCLick = () => {
    setWasSeen(false);
    if (request) {
      dispatch(setChatType(`requests-${user?.user?.id}`));
    } else {
      dispatch(setChatType(`${user?.user?.id}`));
    }
  };
  useEffect(() => {
    if (
      user?.lastMessage?.seenAt === null &&
      user?.lastMessage?.from !== currentUserId
    ) {
      setWasSeen(true)
    }
  }, [user?.lastMessage?.seenAt]);

  useEffect(() => {
    setTimeString(formatTimeDuration(user?.lastMessage?.createdAt));

    const intervalId = setInterval(() => {
      setTimeString(formatTimeDuration(user?.lastMessage?.createdAt));
    }, 6000);

    return () => clearInterval(intervalId);
  }, [user?.lastMessage?.createdAt]);
  
  return (
    <div
      className={`${
        request && chatType === `requests-${user?.user?.id}`
          ? "selected-chat"
          : chatType === user?.user?.id
          ? "selected-chat"
          : "search-user"
      }`}
      id={user?.user?.id}
      onClick={() => cardCLick()}
    >
      <div className={`${!hasDream ? "gradient-border" : ""}`}>
        <img
          src={user?.user?.mainImageUrl || defaultImage}
          alt="profile-img"
          className={`saved-dream-avatar ${
            !hasDream ? "avatar-width-height" : ""
          }`}
          style={{ borderRadius: "50%" }}
        />
      </div>
      <div className="d-flex flex-column user-chat-info">
        <div className="chat-info-role-container">
          <span id="username" className="user-name-chat">
            {username.length > 17 ? username.slice(0, 13) + "..." : username}
          </span>

          {!hasDream && user?.lastMessage?.content !== "" && (
            <div className="tooltip-angel-badge-chat-card">
              <span className="chat-card-angel-text">Dream Angel</span>
            </div>
          )}
        </div>

        {user?.lastMessage ? (
          <div className="last-message-container">
            <span className="last-message">{lastMessage}</span>
            <div className="last-message-time-container">
              <img src={point} alt="" className="last-message-time-point" />
              <span className="last-message-time">{timeString}</span>
            </div>
          </div>
        ) : (
          !hasDream && (
            <div className="tooltip-angel-badge-chat-card">
              <span className="chat-card-angel-text">Dream Angel</span>
            </div>
          )
        )}
      </div>
      {wasSeen && <div className="notify-circle-card"></div>}
    </div>
  );
};

export default ChatListCard;
