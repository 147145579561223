import React, { useState } from "react";
import { ReactComponent as Like } from "../../assets/images/socials/like.svg";
import { ReactComponent as Send } from "../../assets/images/socials/send.svg";
import { ReactComponent as Comment } from "../../assets/images/socials/comment.svg";
import { ReactComponent as Save } from "../../assets/images/socials/save.svg";
import { toast } from "react-toastify";
import "../../styles/Dreams.css";
import { SOCIALS_ENUM } from "../../consts/socialsEnum";
import { NewsfeedSocialInteractionProps } from "src/types/components-props";
import { useDispatch, useSelector } from "react-redux";
import { likePost, savePost, unSavePost } from "src/store/action";
import { useNavigate } from "react-router-dom";
import {
  AngelCommentData,
  CharityCommentData,
  DreamCommentData,
  SavedPosts,
} from "src/types/redux";
import { savedPost } from "src/services/api";
import SentModal from "../Messenger/MessengerComponents/SendModal";

const NewsfeedSocialInteraction = ({
  post,
  type,
  charity,
  dream,
  isCommentButton = true,
  openCommentModal,
  focusToInput = false,
  inputToFocus,
  modalOpenClassName = "",
  anotherModalOpen,
  isCommentsCount = true,
  isSocialInteraction = true,
}: NewsfeedSocialInteractionProps) => {
  const notify = (message) => toast.error(message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedPosts = useSelector(
    (state: SavedPosts) => state.comments.savedPosts || ["1"]
  );
  const [showSentModal, setShowSentModal] = useState<boolean>(false);

  const [savedCount, setSavedCount] = useState<number>(
    dream?.savedCount || charity?.savedCount || post?.savedCount || 0
  );
  const [sharedCount, setSharedCount] = useState<number>(
    dream?.sharedCount || charity?.sharedCount || post?.sharedCount || 0
  );

  const dreamComments = useSelector(
    (state: DreamCommentData) => state.comments.dreamComments[dream?.id] || {}
  );

  const charityComments = useSelector(
    (state: CharityCommentData) =>
      state.comments.charityComments[charity?.id] || {}
  );

  const angelComments = useSelector(
    (state: AngelCommentData) => state.comments.angelComments[post?.id] || {}
  );

  const handleLikeClick = async () => {
    try {
      await dispatch(likePost(dream?.id || charity?.id || post?.id, type));
    } catch (error) {
      notify("Something went wrong. Please try again later.");
    }
  };

  const handleSaveClick = async () => {
    if (dream || charity || post) {
      const saveObject = dream?.id
        ? { dreamId: dream?.id }
        : charity?.id
        ? { charityId: charity?.id }
        : { postId: post?.id };
      try {
        await savedPost(saveObject).then((res) => {
          if (res.data !== "") {
            setSavedCount((prev) => prev + 1);
            dispatch(savePost(dream?.id || charity?.id || post?.id));
          } else {
            setSavedCount((prev) => prev - 1);
            dispatch(unSavePost(dream?.id || charity?.id || post?.id));
          }
        });
      } catch (error) {
        notify("Something went wrong. Please try again later.");
      }
    }
  };

  let commentsInput;

  if (focusToInput && inputToFocus) {
    commentsInput = inputToFocus?.current?.querySelector(".comments-input");
  } else if (focusToInput && modalOpenClassName !== "") {
    commentsInput = document
      ?.querySelector(`.${modalOpenClassName}`)
      ?.querySelector(".comments-input");
  }

  const isSaved =
    savedPosts.includes(dream?.id) ||
    savedPosts.includes(charity?.id) ||
    savedPosts.includes(post?.id);

  return (
    <div className="public-dream-social-parts d-flex">
      {showSentModal && (
        <SentModal
          showSentModal={showSentModal}
          setShowSentModal={setShowSentModal}
          anotherModalOpen={anotherModalOpen}
          post={post}
          dream={dream}
          charity={charity}
          setSharedCount={setSharedCount}
        />
      )}
      <div className="dream-social-action d-flex justify-content-between">
        <div className="newsfeed-socials-left">
          <Like
            fill={
              dreamComments.userLiked ||
              charityComments.userLiked ||
              angelComments.userLiked
                ? "#B0E8E8"
                : "white"
            }
            stroke={
              dreamComments.userLiked ||
              charityComments.userLiked ||
              angelComments.userLiked
                ? "#7EC6CE"
                : "#828282"
            }
            className={
              dreamComments.userLiked ||
              charityComments.userLiked ||
              angelComments.userLiked
                ? ""
                : "like-icon-default-styles"
            }
            style={{ cursor: "pointer" }}
            onClick={handleLikeClick}
          />
          {isCommentButton && (
            <Comment
              fill="white"
              stroke="#828282"
              className="like-icon-default-styles"
              style={{ cursor: "pointer" }}
              onClick={() => {
                type === SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL
                  ? navigate(`/angel/${post?.id}`)
                  : focusToInput && commentsInput
                  ? commentsInput.focus()
                  : openCommentModal(charity?.id);
              }}
            />
          )}
          <Send
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
            onClick={() => setShowSentModal(true)}
          />
        </div>
        <div className="newsfeed-socials-right">
          <Save
            fill={isSaved ? "#F8ED84" : "white"}
            stroke={isSaved ? "#CAB500" : "#828282"}
            className={isSaved ? "" : "like-icon-default-styles"}
            style={{ cursor: "pointer" }}
            onClick={handleSaveClick}
          />
        </div>
      </div>
      <div className="dream-stats-row d-flex">
        <div className="d-flex dream-profile-first-container">
          <p className="fw-bold">
            {dreamComments.likesCount ||
              charityComments.likesCount ||
              angelComments.likesCount ||
              0}
          </p>
          <p>Likes</p>
        </div>
        {isCommentsCount && (
          <div className="d-flex dream-profile-second-container">
            <p>
              {dreamComments.count ||
                charityComments.count ||
                angelComments.count ||
                0}
            </p>
            <p>Comments</p>
          </div>
        )}
        {isSocialInteraction && (
          <>
            <div className="d-flex dream-profile-second-container">
              <p>{`${sharedCount || 0} Sent`}</p>
            </div>
            <div className="d-flex dream-profile-third-container">
              <p>{`${savedCount || 0} Saved`}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewsfeedSocialInteraction;
