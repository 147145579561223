import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import GradientButton from "../Buttons/GradientButton";
import { link, viber } from "../../consts/SvgIcon";
import { shareNotification } from "../../services/api";
import { socialMedia } from "../../consts/items";
import { shareType } from "src/consts/notifications";
import useIsMobile from "src/hooks/useIsMobile";
import { closeIconShareModal } from "../../consts/SvgIcon";

const ShareModal = ({
  customModals,
  setCustomModal,
  sharedLink,
  post,
  anotherModalOpen = false,
}) => {
  const currentUserId = localStorage.getItem("profileId");
  const [wasClicked, setWasClicked] = useState<boolean>(false);
  const isMobile = useIsMobile(400);

  const shareOn = (url, media) => {
    navigator.clipboard.writeText(`${sharedLink}`).then(() => {
      const navUrl = url + sharedLink;
      window.open(navUrl, "_blank");
    });
    !post?.charityId &&
      shareNotification({
        type: post?.amount
          ? shareType.SHARE_DREAM
          : shareType.SHARE_DREAM_ANGEL,
        dreamId: post?.id,
        replyUserId: currentUserId,
        media: media,
        dreamAngelId: post?.dreamAngelId,
      });
  };

  return (
    <Modal
      backdrop={true}
      centered
      show={customModals}
      onHide={() => {
        setCustomModal(false);
        setWasClicked(false);
      }}
      animation={true}
      style={{ borderRadius: "8px" }}
    >
      <Modal.Header>
        <div className="share-modal-header">
          <span className="share-modal-title">Share</span>
          {closeIconShareModal(() => {
            setCustomModal(false);
            setWasClicked(false);
          })}
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3 text-dark">
        {anotherModalOpen && (
          <div className="modal-bg-opacity">
            <div className="modal-bg-opacity-color"></div>
          </div>
        )}
        <span>via social media:</span>
        <div className="d-flex gap-2 social-icon-container">
          {socialMedia?.map(({ media, icon, id, url }) => (
            <div
              id={id}
              onClick={() => shareOn(url, media)}
              key={id}
              className="cursor-pointer"
            >
              {icon}
            </div>
          ))}
          <a
            id="viber"
            href={`viber://pa?=text=${link}`}
            onClick={() => navigator.clipboard.writeText(`${sharedLink}`)}
          >
            {viber()}
          </a>
        </div>
        <span>or copy link</span>
        <div className="share-container">
          <div style={{ minWidth: "16px" }}>{link()}</div>
          <span className="my-title">{sharedLink}</span>
          {wasClicked ? (
            <div className="copied-btn">
              {isMobile ? "Copied ✓" : "Copied to clipboard ✓"}
            </div>
          ) : (
            <GradientButton
              style={{height: "34px", minWidth: "96px"}}
              text="Copy"
              onClick={() => {
                setWasClicked(true);
                navigator.clipboard.writeText(`${sharedLink}`);
              }}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ShareModal;
