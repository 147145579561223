import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/users";
import {
  createDream,
  getPublicUserDream,
  getUser,
  uploadDreamImages,
} from "../../services/api";
import { ErrorInfo, returnButton } from "../../consts/SvgIcon";
import GradientButton from "../../components/Buttons/GradientButton";
import { DreamDto } from "src/services/api/data-contracts";
import { useQuery } from "@tanstack/react-query";
import UploadDreamPhotos from "src/components/Onboarding/partials/UploadDreamPhotos";
import CustomInput from "src/components/Partials/CustomInput";
import StepHeader from "src/components/Onboarding/partials/StepHeader";

const CreateDream = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserId = localStorage.getItem("profileId");

  const [uploadedPhotos, setUploadedPhotos] = useState<File[]>([]);
  const [userDescription, setUserDescription] = useState<string>("");

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [infoMessages, setInfoMessages] = useState([
    "You cannot change it later",
    "At least 150$",
  ]);
  const [isActive, setIsActive] = useState(false);

  const isDescriptionError =
    (userDescription.length < 10 && userDescription.length !== 0) ||
    userDescription.length > 255;

  useEffect(() => {
    const numericAmount = parseFloat(amount);
    if (amount === "") {
      setInfoMessages(["You cannot change it later", "At least 150$"]);
      setAmountError("");
    } else if (numericAmount < 150) {
      setInfoMessages(["You cannot change it later"]);
      setAmountError("At least 150$");
    } else {
      setInfoMessages(["You cannot change it later"]);
      setAmountError("");
    }
  }, [amount]);

  useEffect(() => {
    const isValidAmount = parseFloat(amount) >= 150;
    setIsActive(
      uploadedPhotos.length > 0 &&
        userDescription.length > 10 &&
        userDescription.length < 255 &&
        isValidAmount
    );
  }, [amount, uploadedPhotos, userDescription]);

  const handleClick = () => {
    if (isActive) {
      createDream({
        title: userDescription,
        amount: parseFloat(amount),
      }).then((e) => {
        const id = e?.data?.id;
        uploadDreamImages(id, uploadedPhotos).then((response) => {
          if (!response) return;
          navigate("/newsfeed");
          getUser().then((response) => {
            dispatch(setUser(response.data));
          });
        });
      });
    }
  };

  const { data: userDream } = useQuery(["userDream", currentUserId], () =>
    getPublicUserDream(currentUserId).then((res) => res.data.results)
  );

  if (userDream && userDream.length > 0) {
    const currentDream = userDream.find(
      (dream: DreamDto) =>
        dream.status === "IN_PROGRESS" || dream.status === "NOT_STARTED"
    );
    if (currentDream) navigate("/newsfeed");
  }

  return (
    <div className="create-dream-container">
      <div className="create-dream-back-btn">
        <div className="back-button-container" onClick={() => navigate(-1)}>
          {returnButton()}
          <span className="back-button-text">back</span>
        </div>
      </div>
      <div className="step-4-container">
        <StepHeader
          title="Create Your Dream"
          proTip="Upload 3+ high-quality pictures that capture your dream to help others empathize and connect with your vision. Pairing them with an inspiring description will boost your chances of fulfilling your dream."
          showStepIndicator={false}
        />
        <UploadDreamPhotos
          uploadedPhotos={uploadedPhotos}
          setUploadedPhotos={setUploadedPhotos}
        />
        <div className="profile-description-container">
          <span className="textarea-title">Dream Description*</span>

          <textarea
            id="description"
            name="description"
            value={userDescription}
            onChange={(e) => setUserDescription(e.target.value)}
            className="profile-textarea"
          />
          {isDescriptionError && (
            <div className="error-info-container">
              <div className="error-info-icon">{ErrorInfo()}</div>
              <span className="error-message">
                {userDescription.length > 255
                  ? "The description must be a maximum of 255 characters"
                  : "Please enter a description with at least 10 characters"}
              </span>
            </div>
          )}
        </div>
        <div className="profile-description-container">
          <CustomInput
            placeholder="1500"
            description="Dream Amount*"
            state={amount}
            setState={setAmount}
            errorMessage={amountError}
            isError={amountError !== ""}
            infoMessages={infoMessages}
            isInfo={true}
            isDollarIcon
          />
        </div>
        <GradientButton
          style={{ width: "100%", maxWidth: "360px" }}
          text="Create Dream"
          textClassName={` ${
            !isActive ? "disabled-text" : "search-modal-btn-text"
          }`}
          className={` ${!isActive ? "disabled-btn" : "gradient-button"}`}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default CreateDream;
