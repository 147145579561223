import { MessageData } from "src/types/messenger";
import {
  MessagesActionTypes,
  SET_CHAT_TYPE,
  SET_REQUESTS_CHATS,
  SET_MESSENGER_CHATS,
  SET_CANCELED_CHATS,
} from "./action";

export interface MessagesState {
  chatType: string;
  requestsChats: MessageData[];
  messengerChats: MessageData[];
  canceledChats: MessageData[];
}

const initialState: MessagesState = {
  chatType: "",
  requestsChats: [],
  messengerChats: [],
  canceledChats: [],
};

const messageReducer = (
  state = initialState,
  action: MessagesActionTypes
): MessagesState => {
  switch (action.type) {
    case SET_CHAT_TYPE:
      return { ...state, chatType: action.payload.chatType };

    case SET_REQUESTS_CHATS:
      return {
        ...state,
        requestsChats: [...action.payload.chat],
      };

    case SET_MESSENGER_CHATS:
      return {
        ...state,
        messengerChats: [...action.payload.chat],
      };
      
    case SET_CANCELED_CHATS:
      return {
        ...state,
        canceledChats: [...action.payload.chat],
      };

    default:
      return state;
  }
};

export default messageReducer;
