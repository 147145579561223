import React from "react";
import { useSelector } from "react-redux";
import { ChatType } from "src/types/redux";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { SearchChatListCardProps } from "src/types/messenger";
import { formatTimeDuration } from "src/utils/formatTimeDuration";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const SearchChatCard = ({
  user,
  setUserSelected,
  userSelected,
}: SearchChatListCardProps) => {
  const chatType = useSelector((state: ChatType) => state.messages).chatType;
  const selectedChat = userSelected ? userSelected : chatType;
  const activeTime = formatTimeDuration(user?.activeAt);
  const hasDream = useRoleCheck(user);

  const lastTimeActive =
    user?.activeAt === null
      ? user.socketId !== null
        ? "active now"
        : "active a long time ago"
      : activeTime === "now"
      ? `active now`
      : `active ${activeTime} ago`;

  const cardCLick = () => {
    if (setUserSelected) {
      setUserSelected((prev) => (prev === user?.id ? "" : user?.id));
    }
  };

  return (
    <div
      className={`${
        selectedChat === user?.id ? "selected-chat" : "search-user"
      }`}
      onClick={() => cardCLick()}
    >
      <div className={`${!hasDream ? "gradient-border" : ""}`}>
        <img
          src={user?.mainImageUrl || defaultImage}
          alt="profile-img"
          className={`avatar ${!hasDream ? "avatar-width-height" : ""}`}
          style={{ borderRadius: "50%" }}
        />
      </div>
      <div className="d-flex flex-column user-chat-info">
        <span id="username" className="user-name-chat">
          {`${user?.firstName || "No name"}
                 ${user?.lastName || ""}`}
        </span>

        {!hasDream ? (
          <div className="tooltip-angel-badge-chat-card">
            <span className="chat-card-angel-text">Dream Angel</span>
          </div>
        ) : (
          <span className="last-message">{lastTimeActive}</span>
        )}
      </div>
    </div>
  );
};

export default SearchChatCard;
