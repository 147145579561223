import React, { useState } from "react";
import ViewAll from "./ViewAll";
import { getAllTodaysBirthday } from "../../services/api";
import "../../styles/Sliders.css";
import Carousel from "react-multi-carousel";
import BirthdayCard from "./BirthdayCard";
import { birthdayResponsive } from "../../consts/breakpoints";
import useIsMobile from "../../hooks/useIsMobile";
import { SLIDERS_ENUM } from "../../consts/slidersEnum";
import { useQuery } from "@tanstack/react-query";
import { UserDto } from "src/services/api/data-contracts";
import SliderArrows from "../Partials/SliderArrows";

const TodaysBirthday = ({ isFulfilled, sliderWidth }) => {
  const isMobile = useIsMobile(768);
  const [showModal, setShowModal] = useState(false);

  const { data: users, isLoading } = useQuery<UserDto[]>({
    queryKey: ["monthBirthday"],
    queryFn: () => getAllTodaysBirthday().then((res) => res.data),
    staleTime: 30000,
    cacheTime: 30000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {users.length >= 5 && (
        <div className="birthday-app">
          <div className="dream-header">
            <h4 className="dream-slider-title">🎉 This Month's Birthdays:</h4>
            <div className="d-flex align-items-center">
              {users.length >= 12 ? (
                <div onClick={handleShowModal} className="view-all-button">
                  <p className="view-all">view all</p>
                </div>
              ) : (
                <></>
              )}
              {!isMobile ? (
                <SliderArrows containerClass="today-birthday-scroll" />
              ) : (
                <></>
              )}
            </div>
            <ViewAll
              show={showModal}
              handleClose={handleCloseModal}
              items={users}
              isFulfilled={isFulfilled}
              type={SLIDERS_ENUM.TODAYS_BIRTHDAY}
            />
          </div>
          {isMobile ? (
            <div className="carousel-wrapper slider-margin-mobile">
              <Carousel
                responsive={birthdayResponsive}
                className="carousel-width slider-carousel"
                sliderClass="d-flex align-items-center justify-content-between"
                itemClass="dream-card-mobile"
                arrows={false}
                swipeable
                infinite={false}
                draggable
              >
                {users.slice(0, 12).map((dream) => (
                  <BirthdayCard
                    key={dream.id}
                    dream={dream}
                    isFulfilled={isFulfilled}
                  />
                ))}
              </Carousel>
            </div>
          ) : (
            <div
              className="dream-dreams-container today-birthday-scroll"
              style={{ width: sliderWidth }}
            >
              {users.slice(0, 12).map((dream) => (
                <BirthdayCard
                  key={dream.id}
                  dream={dream}
                  isFulfilled={isFulfilled}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TodaysBirthday;
