import moment from "moment";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { SavedDreamCardProps } from "src/types/messenger";
import { useNavigate } from "react-router-dom";
import MessageAvatar from "./MessageAvatar";

const SavedCharityCard = ({
  charity,
  borderRadius,
  myMessage = true,
  message,
  isBatch,
  isMyMessageNext = true,
}: SavedDreamCardProps) => {
  const navigate = useNavigate();
  const progress = calculateDreamProgress(charity);

  return (
    <div
      className={`user-message-container ${
        myMessage ? "my-message-container" : ""
      }`}
      style={{ marginBottom: `${isMyMessageNext ? "0px" : "12px"}` }}
    >
      <MessageAvatar
        myMessage={myMessage}
        message={message}
        isBatch={isBatch}
      />
      <div
        className={`saved-charity-card-container ${
          !myMessage ? "not-my-message" : ""
        } `}
        style={{
          borderRadius: borderRadius,
          marginLeft: isBatch && !myMessage ? "42px" : "0px",
        }}
        onClick={() => navigate(`/wing-donation/${charity?.id}`)}
      >
        <div className="gradient-bg-charity w-100">
          <span className="saved-charity-gradient-text">🕊 Wings Donation</span>
        </div>
        <div className="saved-charity-info">
          <span className="saved-charity-card-title">{charity?.title}</span>
          <span className="saved-charity-card-time">
            {moment(charity?.createdAt)?.fromNow(true)} ago
          </span>
        </div>

        <img
          src={charity?.images[0].avatarUrl || defaultImage}
          alt=""
          className="saved-charity-card-image"
        />
        <span className="saved-dream-description">{charity?.description}</span>
        <div className="saved-dream-progress-bar">
          <div className="progress-bar-container">
            <ProgressBar
              now={progress}
              variant={"gradient"}
              className="post-progress-bar "
            />
            <span className="progress-percent">
              {isNaN(progress) ? 0 : progress}%
            </span>
          </div>
          <div className="d-flex justify-content-start text-dark ">
            <span className="fulfilled-info">
              Received
              <span className="fulfilled-count">
                {" "}
                {charity?.amountReceived}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedCharityCard;
