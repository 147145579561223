import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import loaderGif from "../assets/images/page-img/page-load-loader.gif";
import {
  getDreamDonations,
  getPublicDreamDonations,
  getWingDonations,
} from "../services/api";
import {
  setAddProfileReceived,
  setAddPublicReceived,
  setAddWingDonations,
} from "../store/users";
import { useDisableBodyScroll } from "../hooks/useDisableBodyScroll";
import { DreamMakersModalProps } from "src/types/components-props";
import close from "../assets/images/search-modal-close.svg";
import "../styles/dream-makers-carousel.css";
import DreamMakersModalCard from "./Partials/DreamMakersModalCard";

let page2d = 1;
let loader = false;
const DreamMakersModal = ({
  donations,
  show,
  setShow,
  dreamId,
  wing,
  card,
  profile,
  wingCard,
  anotherModalOpen = false,
}: DreamMakersModalProps) => {
  const handleClose = () => {
    setShow(false);
    setCurrentPage(1);
    page2d = 1;
  };

  const dispatch = useDispatch();
  const params = useParams();
  const [hasNextPage, setHasNextPage] = useState(donations?.meta?.hasNextPage);
  const [currentPage, setCurrentPage] = useState(1);
  let myRef = useRef(null);

  useDisableBodyScroll(show);

  useEffect(() => {
    setHasNextPage(donations?.meta?.hasNextPage);
    setCurrentPage(donations?.meta?.page);
    page2d = donations?.meta?.page;
    loader = false;
  }, [donations?.meta?.page]);

  const loadMore = () => {
    if (donations?.results?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        if (params?.id) {
          if (wing) {
            getWingDonations(params?.id, page2d).then((response) => {
              dispatch(setAddWingDonations(response.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          } else {
            if (profile) {
              getPublicDreamDonations(dreamId, page2d).then((response) => {
                dispatch(setAddPublicReceived(response?.data));
                setHasNextPage(response?.data?.meta?.hasNextPage);
                loader = false;
                setCurrentPage(currentPage + 1);
              });
            } else {
              getPublicDreamDonations(params.id, page2d).then((response) => {
                dispatch(setAddPublicReceived(response?.data));
                setHasNextPage(response?.data?.meta?.hasNextPage);
                loader = false;
                setCurrentPage(currentPage + 1);
              });
            }
          }
        } else {
          if (card) {
            getPublicDreamDonations(dreamId, page2d).then((response) => {
              dispatch(setAddPublicReceived(response?.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          } else if (wingCard) {
            getWingDonations(dreamId, page2d).then((response) => {
              dispatch(setAddWingDonations(response.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          } else {
            getDreamDonations(dreamId, page2d).then((response) => {
              dispatch(setAddProfileReceived(response?.data));
              setHasNextPage(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          }
        }
      }
    }
  };
  return (
    <Modal
      backdropClassName={"bg-opacity-50t"}
      centered
      backdrop={true}
      show={show}
      onHide={handleClose}
      animation={true}
      contentClassName="border-0"
      style={{ borderRadius: "8px" }}
      className="dream-makers-bootstrap-modal"
    >
      <div className="dream-makers-modal-container">
      {anotherModalOpen && (
          <div className="modal-bg-opacity">
            <div className="modal-bg-opacity-color"></div>
          </div>
        )}
        <div className="dream-makers-modal-title-container">
          <span className="dream-makers-title">Dream Makers:</span>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={close} alt="x" />
          </div>
        </div>

        <InfiniteScroll
          loadMore={() => loadMore()}
          hasMore={hasNextPage}
          useWindow={false}
          getScrollParent={() => myRef.current}
          threshold={10}
        >
          <div className="dream-makers-modal-results">
            {donations?.results?.length ? (
              donations?.results?.map((elem, index) => {
                return (
                  <DreamMakersModalCard
                    user={elem?.user}
                    key={index}
                    closeModal={handleClose}
                    marginBottom={
                      index === donations?.results?.length - 1 ? "20px" : ""
                    }
                  />
                );
              })
            ) : (
              <div className="text-center text-black">
                <h6>No dream makers yet</h6>
              </div>
            )}
          </div>

          {loader && (
            <div className="d-flex justify-content-center w-100">
              <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </Modal>
  );
};
export default DreamMakersModal;
