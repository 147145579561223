import React from "react";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { useNavigate } from "react-router-dom";
import { SavedDreamCardProps } from "src/types/messenger";
import MessageAvatar from "./MessageAvatar";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const SavedDreamCard = ({
  dream,
  borderRadius,
  myMessage = true,
  message,
  isBatch,
  isMyMessageNext = true,
}: SavedDreamCardProps) => {
  const navigate = useNavigate();
  const progress = calculateDreamProgress(dream as any);
  const hasDream = useRoleCheck(dream?.user);

  return (
    <div
      className={`user-message-container ${
        myMessage ? "my-message-container" : ""
      }`}
      style={{ marginBottom: `${isMyMessageNext ? "0px" : "12px"}` }}
    >
      <MessageAvatar
        myMessage={myMessage}
        message={message}
        isBatch={isBatch}
      />
      <div
        className={`saved-dream saved-dream-pointer ${
          !myMessage ? "not-my-message" : ""
        } `}
        style={{
          borderRadius: borderRadius,
          marginLeft: isBatch && !myMessage ? "42px" : "0px",
        }}
        onClick={() => navigate(`/dream-page/${dream?.id}`)}
      >
        <div className="saved-dream-info-container">
          <img
            src={dream?.user?.mainImageUrl || defaultImage}
            alt="avatar"
            className={`saved-dream-avatar ${
              !hasDream ? "avatar-width-height" : ""
            }`}
          />
          <div className="saved-dream-info">
            <span className="saved-dream-name">{`${dream?.user?.firstName} ${dream?.user?.lastName}`}</span>
            <span className="saved-dream-time">
              {moment(dream?.createdAt)?.fromNow(true)} ago
            </span>
          </div>
        </div>
        <img
          src={dream?.images[0]?.avatarUrl || defaultImage}
          alt="dreamImage"
          className="saved-dream-image"
        />
        <span className="saved-dream-description">{dream?.title}</span>
        <div className="saved-dream-progress-bar">
          <div className="progress-bar-container">
            <ProgressBar
              now={progress}
              variant={"gradient"}
              className="post-progress-bar "
            />
            <span className="progress-percent">
              {isNaN(progress) ? 0 : progress}%
            </span>
          </div>
          <div className="saved-dream-fulfilled-info">
            <span className="fulfilled-info">
              Fulfilled
              <span className="fulfilled-count">
                {" "}
                {dream?.user?.donatedCount || 0}
              </span>
            </span>
            <span className="fulfilled-info">
              Received
              <span className="fulfilled-count"> {dream?.amountReceived}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedDreamCard;
