import React from "react";

const ChatTimeDifference = ({ text }) => {
  return (
    <div className="time-difference-container">
      <span className="time-difference-text">{text}</span>
    </div>
  );
};

export default ChatTimeDifference;
