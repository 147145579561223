import React, { useEffect } from "react";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setIsAuth } from "../../store/users";
import { confirmEmail } from "../../services/api";
import { Link, useSearchParams } from "react-router-dom";
import { UsersModule } from "src/types/redux";
import PublicNavBar from "src/components/NavBar/PublicNavBar";
import { checkEmail, logo } from "src/consts/SvgIcon";

const ConfirmEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const notify = (message) => toast.success(message);
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profileId");
    confirmEmail(searchParams?.get("token")).then((res) => {
      if (!res) return;
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Success");
    });
  }, []);
  return (
    <div className="d-flex justify-content-center align-items-center flex-column screen-size">
      <PublicNavBar />
      <div className="gradient-login-bg">
        <div className="login-pages-container d-flex flex-column align-items-center">
          <div style={{ color: "black" }}>{logo()}</div>
          <div className="d-flex flex-column align-items-center width-360">
            <div> {checkEmail()} </div>
            <div className="forgot-passowrd-title mb-2">Email is confirmed</div>
            <div className="width-360">
              <Link to="/login" className="login-form-submit-button">
                Log in
              </Link>
              {showLoader && (
                <div className="d-flex justify-content-center w-100">
                  <img src={loader} alt="loader" style={{ height: "50px" }} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
