import React from "react";
import ChatListHeader from "./MessengerComponents/MessengerPartials/ChatListHeader";
import ChatListSaved from "./MessengerComponents/MessengerPartials/ChatListSaved";
import ChatList from "./MessengerComponents/ChatList";
import { useSelector } from "react-redux";
import { ChatType } from "src/types/redux";
import ChatRequestsListSection from "./MessengerComponents/ChatRequestsListSection";
import { ChatListSectionProps } from "src/types/messenger";

const ChatListSection = ({ setShowSearchModal }: ChatListSectionProps) => {
  const chatType = useSelector((state: ChatType) => state.messages).chatType;

  if (
    chatType === "requestsList" ||
    chatType === "request" ||
    chatType?.split("-")[0] === "requests"
  ) {
    return <ChatRequestsListSection setShowSearchModal={setShowSearchModal} />;
  }
  return (
    <div className="chat-list">
      <ChatListHeader />
      <ChatListSaved />
      <ChatList setShowSearchModal={setShowSearchModal} />
    </div>
  );
};

export default ChatListSection;
