import React, { useEffect, useState } from "react";
import emailIcon from "../../../assets/images/email-icon.svg";
import PhoneNumberSelect from "./PhoneNumberSelect";
import CustomSelect from "src/components/Partials/CustomSelect";
import countriesData from "../../../consts/countriesData.json";
import CalendarSelect from "./CalendarSelect";
import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import { getStepNumber } from "src/utils/getStepNumber";
import moment from "moment";
const allCountriesName = countriesData.map((country) => country.name);

interface Step2FormProps {
  selectedNumber: string;
  setSelectedNumber: React.Dispatch<React.SetStateAction<string>>;
  selectedData: string;
  setSelectedData: React.Dispatch<React.SetStateAction<string>>;
  selectedCountry: string;
  setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
  selectedCity: string;
  setSelectedCity: React.Dispatch<React.SetStateAction<string>>;
  selectedCityError: string;
  selectedCountryError: string;
}

const Step2Form = ({
  selectedNumber,
  setSelectedNumber,
  selectedData,
  setSelectedData,
  selectedCountry,
  setSelectedCountry,
  selectedCity,
  setSelectedCity,
  selectedCountryError,
  selectedCityError,
}: Step2FormProps) => {
  const [countriesOptions, setCountriesOptions] =
    useState<string[]>(allCountriesName);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const currentStepNumber = getStepNumber((user as any).onboardingStatus) + 1;
  const isFormCompleted = currentStepNumber !== 2 ? true : false;

  const [citiesOptions, setCitiesOptions] = useState<string[]>([]);

  const [searchedCitiesOptions, setSearchedCitiesOptions] = useState<string[]>(
    citiesOptions || []
  );

  useEffect(() => {
    if (selectedCountry !== "") {
      const allCities = countriesData.filter(
        (country) => country.name === selectedCountry
      );

      if (
        allCities &&
        allCities.length > 0 &&
        allCities[0].regions.length > 0
      ) {
        setSelectedCity("");
        setCitiesOptions(allCities[0].regions);
        setSearchedCitiesOptions(allCities[0].regions);
      }
    } else {
      setSelectedCity("");
      setCitiesOptions([]);
      setSearchedCitiesOptions([]);
    }
  }, [selectedCountry]);

  return (
    <div className="step-form-container">
      <div className="email-input-container">
        <span className="disabled-input-description">E-mail address:</span>
        <div className="disabled-email-input">
          <span className="gray-description">{user.email}</span>
          <img src={emailIcon} alt="message" />
        </div>
      </div>
      <PhoneNumberSelect
        setSelectedNumber={setSelectedNumber}
        selectedNumber={selectedNumber}
        isDisabled={isFormCompleted}
        disabledValue={user.phoneNumber}
      />
      <CalendarSelect
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        isDisabled={isFormCompleted}
        disabledValue={moment(user.birthday).format("DD/MM/YYYY")}
      />
      <CustomSelect
        description="Country*"
        options={countriesOptions}
        selectedOption={selectedCountry}
        setSelectedOption={setSelectedCountry}
        setOptions={setCountriesOptions}
        allOptions={allCountriesName}
        errorMessage={selectedCountryError}
        isError={selectedCountryError !== ""}
        isDisabled={isFormCompleted}
        disabledValue={user.country}
      />
      <CustomSelect
        description="City*"
        options={searchedCitiesOptions}
        selectedOption={selectedCity}
        setSelectedOption={setSelectedCity}
        setOptions={setSearchedCitiesOptions}
        allOptions={citiesOptions}
        errorMessage={selectedCityError}
        isError={selectedCityError !== ""}
        isDisabled={isFormCompleted}
        disabledValue={user.city}
      />
    </div>
  );
};

export default Step2Form;
