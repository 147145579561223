import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllProfileWingDonations,
  getCharitiesById,
  getUser,
  getWingDonations,
  wingDonation,
} from "src/services/api";
import { CharityDto, WingDonationDto } from "src/services/api/data-contracts";
import { useDispatch, useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import { toast } from "react-toastify";
import { setAllProfileWing, setUser, setWingDonations } from "src/store/users";
import CharityCard from "src/components/Partials/CharityCard";
import { Card, Carousel } from "react-bootstrap";
import Comments from "src/components/Comments/Comments";
import { handleFulfillment } from "src/utils/isFulfilled";
import "../../styles/pages/WingDonationPage.css";
import NewsfeedSocialInteraction from "src/components/Partials/NewsfeedSocialInteraction";
import { SOCIALS_ENUM } from "src/consts/socialsEnum";
import { commentType } from "../../consts/commentsTypeEnum";
import PublicWingMakersCarousel from "src/components/PublicandInternShared/PublicWingMakersCarousel";
import PricingModal from "../Pricing/PricingModal";
import { savePost, updateCharityLikes } from "src/store/action";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import useIsMobile from "src/hooks/useIsMobile";
import { updateMarginLeft } from "src/utils/updateMarginLeft";
import { returnButton } from "src/consts/SvgIcon";

export default function WingDonationPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCompleted = useIsMobile(680);
  const notify = (message) => toast.success(message);

  const [customModals, setCustomModal] = useState("");
  const [show, setShow] = useState(false);
  const [dreamId, setDreamId] = useState("");
  const [paddingLeft, setPaddingLeft] = useState(0);

  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const wingDonationsProfile = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileWing;
  const wingDonations = useSelector(
    (state: UsersModule) => state.usersModule
  ).wingDonations;
  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;

  const { data: charity, isLoading: isCharityLoading } = useQuery<CharityDto>({
    queryKey: ["charityByIdPage", id],
    queryFn: () => getCharitiesById(id).then((res) => res.data),
    onSuccess: (charity) => {
      getActionData(charity);
      if (charity?.isSaved) {
        dispatch(savePost(charity?.id));
      }
    },
  });

  const getActionData = (charity) => {
    const likesCount = charity.likedCharitiesByUsers.length;
    const likedBy = charity.likedCharitiesByUsers.map((user) => user.id);
    const userLiked = likedBy.includes(localStorage.getItem("profileId"));
    dispatch(updateCharityLikes(charity?.id, likesCount, userLiked, likedBy));
  };

  useEffect(() => {
    const handleResize = () => {
      const newPadding = updateMarginLeft(680);
      setPaddingLeft(newPadding);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { data: users, isLoading: isDonationsLoading } = useQuery<
    WingDonationDto[]
  >({
    queryKey: ["privateWingPageDonationsUsers"],
    queryFn: () =>
      getWingDonations(charity?.id, 1, 12).then((res) => res.data.results),
    enabled: !!charity?.id,
  });

  if (isCharityLoading || isDonationsLoading) {
    return (
      <div className="d-flex justify-content-center w-100" key="loader">
        <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
      </div>
    );
  }

  const progressClick = () => {
    dispatch(setWingDonations({}));
    setShow((prev) => !prev);
    getWingDonations(charity?.id, 1).then((res) => {
      dispatch(setWingDonations(res.data));
    });
  };

  const handleWingDonation = (id) => {
    if (!user.balance) {
      setCustomModal("priceModal");
    } else {
      wingDonation(id).then((res) => {
        if (!res) return;
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getUser().then((response) => {
          dispatch(setUser(response?.data));
        });
        getAllProfileWingDonations().then((res) => {
          dispatch(setAllProfileWing(res.data));
        });
      });
    }
  };

  const isFulfilled = (id) => {
    handleFulfillment(id, user, setDreamId, setCustomModal, dispatch);
  };

  const goBack = () => {
    navigate(-1);
  };

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="post-image cursor-pointer" alt="img" />
      </Carousel.Item>
    );
  };

  return (
    <div className="d-flex justify-content-center flex-column mt-2">
      <div
        style={{
          paddingLeft: isCompleted ? "0" : `${paddingLeft}%`,
          width: "99%",
        }}
        className="go-back-button"
      >
        <div onClick={goBack} className="back-button-container">
          {returnButton()}
          <span className="back-button-text">back</span>
        </div>
      </div>
      <div className="wing-donation-page-card mb-5">
        <Card className="rounded-3 gap-card pb-0 shadow m-0">
          <Card.Body className="p-0 gap-post">
            <CharityCard
              charity={charity}
              isFulfilled={handleWingDonation}
              wingDonationsProfile={wingDonationsProfile}
              progressClick={progressClick}
              wingDonations={wingDonations}
              carouselItem={carouselItem}
              show={show}
              setShow={setShow}
            />
            <div className="wing-page-newsfeed-box">
              <NewsfeedSocialInteraction
                charity={charity}
                type={SOCIALS_ENUM.INTERN_CHARITY}
                isCommentsCount={false}
                isSocialInteraction
              ></NewsfeedSocialInteraction>
            </div>
            <Comments
              type={commentType.charity}
              user={user}
              mainImg={mainImg}
              isFulfilled={isFulfilled}
              dream={charity}
              postId={charity.id}
            />
          </Card.Body>
        </Card>
      </div>
      <PricingModal
        customModals={customModals}
        setCustomModal={setCustomModal}
        dreamId={dreamId}
      />
      <PublicWingMakersCarousel users={users} isFulfilled={isFulfilled} />
    </div>
  );
}
