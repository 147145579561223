import React from "react";
import CloseIcon from "../../../../assets/images/saved-header-close.svg";
import arrowLeft from "../../../../assets/images/grayArrowLeft.svg";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { setChatType } from "src/store/action";
import { useDispatch } from "react-redux";
import useIsMobile from "src/hooks/useIsMobile";
import { useRoleCheck } from "src/hooks/useRoleCheck";
import { useNavigate } from "react-router-dom";

const UserChatSectionHeader = ({ isRequest, user }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(600);
  const hasDream = useRoleCheck(user);
  const navigate = useNavigate();
  const dispatchType =
    isMobile && isRequest ? "requestsList" : isRequest ? "request" : "";

  return (
    <div className="saved-section-header">
      <div className="header-info-container">
        <div className={`${!hasDream ? "gradient-border" : ""}`}>
          <img
            src={user?.mainImageUrl || defaultImage}
            alt="saved-icon"
            className="chat-header-image"
            onClick={() => navigate(`/dashboard/public-page/${user?.id}`)}
          />
        </div>
        <span
          className="saved-text text-underline"
          onClick={() => navigate(`/dashboard/public-page/${user?.id}`)}
        >{`${user?.firstName} ${user?.lastName}`}</span>
        {!hasDream && (
          <div className="tooltip-angel-badge-chat-card">
            <span className="chat-card-angel-text">Dream Angel</span>
          </div>
        )}
      </div>
      {isMobile ? (
        <div className="back-arrow">
          <img
            src={arrowLeft}
            alt="arrow"
            className="back-arrow-icon"
            onClick={() => {
              dispatch(setChatType(dispatchType));
            }}
          />
        </div>
      ) : (
        <div className="back-arrow">
          <img
            src={CloseIcon}
            alt="saved-icon"
            className="saved-close-icon"
            onClick={() => {
              dispatch(setChatType(dispatchType));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UserChatSectionHeader;
