import React from "react";
import Carousel from "react-multi-carousel";
import { leftArrow, rightArrow } from "src/consts/SvgIcon";
import CardCarouselSection from "./CardCarouselSection";
import { dream } from "../../consts/landingCarouselContent";
import GradientLinkButton from "../Buttons/GradientLinkButton";

const ButtonGroupLeftDreams = (e) => {
  return (
    <div
      className="carousel-button-group cursor-pointer arrows-landing-carousel-section"
      onClick={() => e.onClick()}
    >
      <div className="left-button-dreams">{leftArrow()}</div>
    </div>
  );
};
const ButtonGroupRightDreams = (e) => (
  <div
    className="carousel-button-group cursor-pointer arrows-landing-carousel-section"
    onClick={() => e.onClick()}
  >
    <div className="right-button-dreams">{rightArrow()}</div>
  </div>
);
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 785 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 784, min: 661 },
    items: 3,
    slidesToSlide: 1,
  },
  tabletMini: {
    breakpoint: { max: 660, min: 501 },
    items: 1.5,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 500, min: 200 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CarouselSection = () => (
  <div className="d-flex flex-column justify-content-around align-items-center gap-3">
    <div className="section-container-carousel-landing gap-4 position-relative">
      <p className="carousel-section-title text-center">
        Latest Dreams on Dreamerz
      </p>
      <Carousel
        responsive={responsive}
        className="carousel-width-public"
        keyBoardControl
        infinite
        removeArrowOnDeviceType="mobile"
        centerMode={window.innerWidth < 660}
        renderArrowsWhenDisabled
        swipeable
        renderButtonGroupOutside={true}
        customLeftArrow={<ButtonGroupLeftDreams />}
        customRightArrow={<ButtonGroupRightDreams />}
      >
        {dream.map((card) => {
          return (
            <div
              key={card.id}
              style={{ width: "inherit" }}
              className="d-flex justify-content-center"
            >
              <CardCarouselSection key={card?.id} dream={card} />
            </div>
          );
        })}
      </Carousel>
      <div style={{ width: "258px" }}>
        <GradientLinkButton
          href="/login"
          text="Fulfill Their Dreams"
          style={{ height: "60px", width: "258px", fontSize: "18px" }}
          minWidth={"225px"}
          textClassName="dreams-title"
          className="start-gradient-button"
        />
      </div>
    </div>
  </div>
);
export default CarouselSection;
