import React, { useEffect, useState } from "react";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { SendChatListCardProps } from "src/types/messenger";
import tickIcon from "../../../../assets/images/checkbox-tick.svg";
import { formatTimeDuration } from "src/utils/formatTimeDuration";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const SendModalCard = ({
  user,
  setUsersSelected,
  usersSelected,
}: SendChatListCardProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const activeTime = formatTimeDuration(user?.activeAt);
  const hasDream = useRoleCheck(user);

  const lastTimeActive =
    user?.activeAt === null
      ? user.socketId !== null
        ? "active now"
        : "active a long time ago"
      : activeTime === "now"
      ? `active now`
      : `active ${activeTime} ago`;

  useEffect(() => {
    const isSelected = usersSelected.find((el) => el.id === user?.id);
    if (isSelected) {
      setIsChecked(true);
    }
  }, [usersSelected]);

  useEffect(() => {
    if (isChecked) {
      setUsersSelected((prev) => [...prev, user]);
    } else {
      setUsersSelected(usersSelected.filter((el) => el?.id !== user?.id));
    }
  }, [isChecked]);

  return (
    <div
      className={`search-user send-user-container ${
        isChecked ? "selected-chat" : ""
      }`}
      onClick={() => {
        setIsChecked(!isChecked);
      }}
    >
      <div className="send-user-card">
        <div className={`${!hasDream ? "gradient-border" : ""}`}>
          <img
            src={user?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="avatar"
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div className="send-user-info-container">
          <div className="send-user-name-role">
            <span id="username" className="send-user-name-chat">
              {`${user?.firstName || "No name"}
                 ${user?.lastName || ""}`}
            </span>
            {!hasDream && (
              <div className="tooltip-angel-badge-chat-card send-angel-margin">
                <span className="chat-card-angel-text">Dream Angel</span>
              </div>
            )}
          </div>

          <span className="last-message">{lastTimeActive}</span>
        </div>
      </div>
      <div
        className={`send-user-checkbox ${isChecked ? "send-user-checked" : ""}`}
      >
        {isChecked && <img src={tickIcon} alt="" />}
      </div>
    </div>
  );
};

export default SendModalCard;
