import React, { useEffect } from "react";
import { socket } from "src/socket";

interface MessengerNotificationProps {
  setMessengerNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessengerNotification = ({
  setMessengerNotification,
}: MessengerNotificationProps) => {
  useEffect(() => {
    socket.on("notification-sent", (data) => {
      setMessengerNotification(data);
    });

    return () => {
      socket.off("notification-sent");
    };
  }, []);
  return <></>;
};

export default MessengerNotification;
