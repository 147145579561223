import React from "react";
import SavedCharityCard from "./SavedCharityCard";
import SavedDreamAngelProfile from "./SavedDreamAngelProfile";
import SavedDreamCard from "./SavedDreamCard";
import UserMessage from "./UserMessage";
import { UserChatMessageProps } from "src/types/messenger";

const UserChatMessage = ({
  message,
  borderRadius,
  myMessage,
  isMyMessageNext,
  isBatch,
}: UserChatMessageProps) => {
  if (message.content === null) {
    if (message?.newsFeed && message.newsFeed !== null) {
      return (
        <SavedDreamAngelProfile
          borderRadius={borderRadius}
          myMessage={myMessage}
          message={message}
          isBatch={isBatch}
          isMyMessageNext={isMyMessageNext}
          dreamAngel={message?.newsFeed}
        />
      );
    } else if (message?.charity && message.charity !== null) {
      return (
        <SavedCharityCard
          charity={message.charity}
          borderRadius={borderRadius}
          myMessage={myMessage}
          message={message}
          isBatch={isBatch}
          isMyMessageNext={isMyMessageNext}
        />
      );
    } else if (message?.dream && message.dream !== null) {
      return (
        <SavedDreamCard
          dream={message.dream}
          borderRadius={borderRadius}
          myMessage={myMessage}
          message={message}
          isBatch={isBatch}
          isMyMessageNext={isMyMessageNext}
        />
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <UserMessage
        message={message}
        borderRadius={borderRadius}
        myMessage={myMessage}
        isMyMessageNext={isMyMessageNext}
        isBatch={isBatch}
      />
    );
  }
};

export default UserChatMessage;
