import React, { useState } from "react";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setShowLoader } from "../../store/users";
import { getEmail } from "../../services/api";
import { Link } from "react-router-dom";
import { UsersModule } from "src/types/redux";
import PublicNavBar from "src/components/NavBar/PublicNavBar";
import { goBack, logo } from "src/consts/SvgIcon";

const ForgotPassword = () => {
  const notify = (message) => toast.success(message);
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const dispatch = useDispatch();
  const [isSend, setIsSend] = useState(false);
  const [email, setEmail] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleReset = (e?: React.FormEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(setShowLoader(true));
    getEmail(email).then((res) => {
      if (!res) {
        return;
      }
      setIsSend(true);
      toast.dismiss();
      toast.clearWaitingQueue();
      notify(`${res?.data}, check your email`);
    });
  };
  return (
    <div className="d-flex justify-content-center align-items-center flex-column screen-size">
      <PublicNavBar />
      <div className="gradient-login-bg">
        {isSend ? (
          <div className="login-pages-container d-flex flex-column align-items-center">
            <div className="reset-check-email-top mobile-width-hundred">
              <div style={{ color: "black", textAlign: "center" }}>
                {logo()}
              </div>
              <div className="forgot-passowrd-title mt-2">
                Please check your email to continue password reset.
              </div>
            </div>

            <div className="reset-check-email-main d-flex flex-column align-items-center mobile-width-hundred">
              <p>
                We have sent an email to <span>{email}</span> {""}
                with a reset link. Didn’t receive an email? Try looking in the
                spam folder, or:
              </p>
              <div className="cursor-pointer" onClick={() => handleReset()}>
                Send the link again
              </div>
            </div>
          </div>
        ) : (
          <div className="login-pages-container d-flex flex-column align-items-center">
            <div className="forgot-password-top-box mobile-width-hundred">
              <Link
                to="/login"
                style={{
                  width: "40px",
                  cursor: "pointer",
                }}
              >
                {goBack()}
              </Link>
              <div style={{ color: "black", textAlign: "center" }}>
                {logo()}
              </div>
              <div>
                <div className="forgot-passowrd-title">
                  Forgot Your Password?
                </div>
                <div className="forgot-password-description">
                  Write down your email and we will send you a reset link
                </div>
              </div>
            </div>
            <div className="forgot-password-main-box mobile-width-hundred">
              <div className="forgot-password-input-box mobile-width-hundred">
                <p>E-mail address:</p>
                <input
                  type="email"
                  onChange={handleInputChange}
                  className={email && "input-complete"}
                />
              </div>
              <div className="register-form-button-box w-100">
                <button
                  className={
                    !email ? "login-form-disabled" : "login-form-submit-button"
                  }
                  onClick={handleReset}
                  disabled={!email}
                >
                  Reset Password
                </button>
                {showLoader && (
                  <div className="d-flex justify-content-center w-100">
                    <img src={loader} alt="loader" style={{ height: "50px" }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
