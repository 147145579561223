import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getUserShortInfoById } from "../../services/api";
import { UserShortInfoDto } from "../../services/api/data-contracts";
import "../../styles/TooltipDetails.css";
import { Tooltip } from "react-tooltip";
import TooltipDreamer from "./TooltipDreamer";
import TooltipAngel from "./TooltipAngel";
import { TooltipDetailsProps } from "src/types/components-props";
import { useRoleCheck } from "src/hooks/useRoleCheck";
import useIsMobile from "../../hooks/useIsMobile";

const TooltipDetails = ({
  userId,
  isFulfilled,
  allFulfilled,
  tooltipKey,
}: TooltipDetailsProps) => {
  const { data: userInfo, isLoading } = useQuery<UserShortInfoDto>({
    queryKey: ["userShortInfo", userId],
    queryFn: () => getUserShortInfoById(userId).then((res) => res.data),
    enabled: !!userId,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });

  const hasDream = useRoleCheck(userInfo);
  const isMobile = useIsMobile(768);

  if (isLoading) {
    return null;
  }

  return (
    <Tooltip
      id={tooltipKey}
      className="tooltip-details-container"
      classNameArrow="tooltip-arrow"
      delayShow={500}
      clickable
    >
      {!hasDream ? (
        <TooltipAngel userInfo={userInfo} />
      ) : (
        !isMobile && <TooltipDreamer
          userInfo={userInfo}
          isFulfilled={isFulfilled}
          allFulfilled={allFulfilled}
        />
      )}
    </Tooltip>
  );
};

export default TooltipDetails;
