import moment from "moment";

export const formattedDate = (savedPosts, index) => {
  const postData = savedPosts[index]?.createdAt;
  const prevData = savedPosts[index - 1]?.createdAt;

  if (!postData) return;

  const now = moment();

  
  const prevTimeDiff = prevData
  ? moment(postData).diff(moment(prevData), "hours")
  : null;
  
  if (prevTimeDiff > 1 || index === 0) {
    if (moment(postData).year() < now.year()) {
      return moment(postData).format("DD MMM YYYY, HH:mm");
    } else if (moment(postData).year() === now.year()) {
      if (moment(postData).isSame(now, "week")) {
        return moment(postData).format("dddd, HH:mm");
      } else if (moment(postData).isSame(now.subtract(1, "days"), "day")) {
        return "Yesterday, " + moment(postData).format("HH:mm");
      } else if (moment(postData).isSame(now, "day")) {
        return moment(postData).format("HH:mm");
      } else {
        return moment(postData).format("DD MMM, HH:mm");
      }
    }
  }
};
