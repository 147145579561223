import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right-icon.svg";
import { scrollCarousel } from "src/utils/scrollCarousel";

export default function SliderArrows({ containerClass }) {
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const updateArrowStates = (containerScroll) => {
    const maxScrollLeft =
      containerScroll.scrollWidth - containerScroll.clientWidth;
    setIsLeftDisabled(containerScroll.scrollLeft === 0);
    setIsRightDisabled(containerScroll.scrollLeft >= maxScrollLeft);
  };

  useEffect(() => {
    const containerScroll = document.querySelector(`.${containerClass}`);

    if (!containerScroll) return;

    const updateOnContentLoad = () => {
      if (containerScroll.childElementCount > 0) {
        updateArrowStates(containerScroll);
      }
    };

    updateOnContentLoad();

    containerScroll.addEventListener("scroll", updateOnContentLoad);

    return () => {
      containerScroll.removeEventListener("scroll", updateOnContentLoad);
    };
  }, [containerClass]);

  const onScroll = (direction) => {
    scrollCarousel(direction, containerClass);
  };

  return (
    <div className="carousel-arrow-container">
      <div
        className={
          !isLeftDisabled
            ? "icon-container-hover-right"
            : "icon-container-disabled-right"
        }
        onClick={() => !isLeftDisabled && onScroll("left")}
      >
        <ArrowLeft
          className={
            isLeftDisabled ? "arrow-sliders-disabled" : "img-arrow-hover"
          }
        />
      </div>
      <div
        className={
          !isRightDisabled
            ? "icon-container-hover-left"
            : "icon-container-disabled-left"
        }
        onClick={() => !isRightDisabled && onScroll("right")}
      >
        <ArrowRight
          className={
            isRightDisabled ? "arrow-sliders-disabled" : "img-arrow-hover"
          }
        />
      </div>
    </div>
  );
}
