import React, { useEffect, useState } from "react";
import StepHeader from "./partials/StepHeader";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "src/store/action";
import GradientButton from "../Buttons/GradientButton";
import Step2Form from "./partials/Step2Form";
import { validDate } from "src/utils/validDate";
import { validNumber } from "src/utils/validNumber";
import { getUser, updateUser } from "src/services/api";
import countriesData from "../../consts/countriesData.json";
import { setUser } from "src/store/users";
import { UsersModule } from "src/types/redux";
import { getStepNumber } from "src/utils/getStepNumber";

const Step2 = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const currentStepNumber = getStepNumber((user as any).onboardingStatus) + 1;
  const isFormCompleted = currentStepNumber !== 2 ? true : false;

  const [selectedNumber, setSelectedNumber] = useState<string>("");
  const [selectedData, setSelectedData] = useState<string>("");

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCountryError, setSelectedCountryError] = useState<string>("");

  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedCityError, setSelectedCityError] = useState<string>("");

  const isCountry =
    countriesData?.find((country) => country?.name === selectedCountry) ||
    undefined;

  const isCity =
    isCountry !== undefined
      ? isCountry?.regions?.find((city) => city === selectedCity)
      : undefined;

  const isDisableBtn = isFormCompleted
    ? true
    : validDate(selectedData) && validNumber(selectedNumber);

  useEffect(() => {
    if (isCountry !== undefined) {
      setSelectedCountryError("");
    }
    if (isCity !== undefined) {
      setSelectedCityError("");
    }
  }, [selectedCountry, selectedCity]);

  const handleClick = () => {
    if (isFormCompleted) return dispatch(setStep(3));

    if (isCountry === undefined) {
      setSelectedCountryError("Please choose your country");
    } else if (isCity === undefined) {
      setSelectedCityError("Please choose your city");
    } else if (isDisableBtn) {
      const birthday = selectedData.split("/").reverse().join("-");
      updateUser({
        birthday,
        phoneNumber: selectedNumber,
        country: selectedCountry,
        city: selectedCity,
        onboardingStatus: "PERSONAL_DATA",
      }).then(() => {
        getUser().then((response) => {
          dispatch(setUser(response.data));
        });
        dispatch(setStep(3));
      });
    }
  };

  return (
    <div className="step-1-container">
      <StepHeader title="Let us know more about you" />
      <Step2Form
        selectedNumber={selectedNumber}
        setSelectedNumber={setSelectedNumber}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        selectedCountryError={selectedCountryError}
        selectedCityError={selectedCityError}
      />
      <GradientButton
        style={{ width: "100%", marginTop: "34px", maxWidth: "360px" }}
        text="Continue"
        textClassName={` ${
          !isDisableBtn ? "disabled-text" : "search-modal-btn-text"
        }`}
        className={` ${!isDisableBtn ? "disabled-btn" : "gradient-button"}`}
        onClick={handleClick}
      />
    </div>
  );
};

export default Step2;
