import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { socket } from "src/socket";
import { CreateMessageProps } from "src/types/messenger";

const NewsFeedShared = ({
  setMessages,
  scrollToBottom,
  chatUserId,
}: CreateMessageProps) => {
  const notify = (message) => toast.error(message);
  const currentUserId = localStorage.getItem("profileId");

  useEffect(() => {
    socket.on("post-shared", (data) => {
      const cardId = data?.from;

      const userCard = document.getElementById(cardId);
      if (userCard) {
        userCard.querySelector(".last-message").textContent = `${
          data?.fromUser?.id === currentUserId
            ? "You"
            : data?.fromUser?.firstName || "No name"
        }: sent a post`;
      }

      if (data?.from === chatUserId || data?.to === chatUserId) {
        setMessages((prev) => [...prev, data]);
      }
      scrollToBottom();
    });

    socket.on("share-post-error", (data) => {
      notify("Something went wrong. Please try again later.");
    });

    return () => {
      socket.off("post-shared");
      socket.off("share-post-error");
    };
  }, []);
  return <></>;
};

export default NewsFeedShared;
