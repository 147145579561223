import {
  getDreamById,
  getPublicUserDream,
  getUserById,
  getUserShortInfoById,
} from "../services/api";

export const pushEvent = (event, formLocation) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({ event, formLocation });
};

export const fetchUserDreams = async (users) => {
  const userDreamPromises = users.map((user) => getPublicUserDream(user.id));
  return await Promise.all(userDreamPromises);
};

export const fetchUserDonationDream = async (users) => {
  const userDreamPromises = users.map((user) => getDreamById(user.dreamId));
  return await Promise.all(userDreamPromises);
};

export const processPublicDreamResponses = (responses) => {
  return responses
    .filter((dreamData) => dreamData)
    .map((response) => response.data);
};

export const dreamMakersIds = (donations) => {
  return donations.map((donation) => donation.userId);
};

export const fetchDreamMakersInfo = async (users) => {
  const userIds = users.map((user) => getUserById(user));
  return await Promise.all(userIds);
};

export const fetchDonationMakersInfo = async (users) => {
  const userIds = users.map((user) => getUserShortInfoById(user.user.id));
  return await Promise.all(userIds);
};

export const processDonationMakersResponses = (responses) => {
  return responses
    .map((response) => response.data)
    .filter((userData) => userData);
};

export const extractDreamMakerData = (responses) => {
  return responses.map((user) => user.data);
};

export const mergeDonations = (firstArray, secondArray) => {
  return firstArray.map((user) => {
    const matchingUser = secondArray.find((donor) => donor.id === user.userId);
    return {
      ...user,
      donationsLength: matchingUser ? matchingUser.donations.length : 0,
    };
  });
};

export const getInputClass = (formData, field, otherError?) => {
  return formData[field]?.trim() !== "" && !otherError ? "input-complete" : "";
};
