import React, { useRef, useState } from "react";
import "../../styles/CustomSelect.css";
import arrowUp from "../../assets/images/arrow-up-icon.svg";
import arrowDown from "../../assets/images/arrow-down-icon.svg";
import CustomSelectDropdown from "./CustomSelectDropdown";
import { ErrorInfo } from "../../consts/SvgIcon";
import useContainerWidth from "src/hooks/useContainerWidth";

interface CustomSelectProps {
  description: string;
  options: string[];
  allOptions?: string[];
  setOptions?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  selectType?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  disabledValue?: string;
}

const CustomSelect = ({
  description,
  options,
  setOptions,
  selectedOption,
  setSelectedOption,
  allOptions,
  selectType = false,
  isError,
  errorMessage,
  isDisabled,
  disabledValue,
}: CustomSelectProps) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [timer, setTimer] = useState(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const parentSelectWidth = useContainerWidth(containerRef);

  const handleChange = (e) => {
    if (!selectType) {
      let inputValue = e.target.value;
      setSelectedOption(inputValue);

      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          if (inputValue) {
            const searchedOptions = allOptions.filter((option) =>
              option.toLowerCase().includes(inputValue.trim().toLowerCase())
            );
            setOptions(searchedOptions);
          } else {
            setOptions(allOptions);
          }
        }, 1000)
      );
    }
  };

  return (
    <div className="custom-select-container" ref={containerRef}>
      <span className="input-description">{description}</span>
      {isDisabled ? (
        <div className="disabled-email-input">
          <span className="gray-description">{disabledValue}</span>
        </div>
      ) : (
        <div
          className={`custom-select ${focus ? "number-input-focus" : ""}  ${
            selectType ? "cursor-pointer" : ""
          }`}
          onClick={() => {
            if (selectType) {
              setFocus(!focus);
            }
          }}
        >
          <input
            type="text"
            placeholder="Select"
            value={selectedOption}
            className={`custom-select-input ${
              selectType ? "transparent-caret cursor-pointer" : ""
            }`}
            onChange={(e) => handleChange(e)}
            onFocus={() => {
              if (!selectType) {
                setFocus(true);
              }
            }}
          />
          <img src={focus ? arrowDown : arrowUp} alt="arrow-icon" />
        </div>
      )}
      {isError && !focus && (
        <div className="error-info-container">
          <div className="error-info-icon">{ErrorInfo()}</div>
          <span className="error-message">{errorMessage}</span>
        </div>
      )}
      {focus && options.length > 0 && (
        <CustomSelectDropdown
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setClose={setFocus}
          parentSelectWidth={parentSelectWidth}
        />
      )}
    </div>
  );
};

export default CustomSelect;
