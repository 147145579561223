import React, { useEffect, useState } from "react";
import {
  getPublicCharityComments,
  getPublicComments,
  getPublicDreamAngelComments,
} from "../../services/api";
import InputComment from "../Comments/InputComment";
import { useNavigate } from "react-router-dom";
import Comment from "../Comments/Comment";
import { PublicPostCommentsProps } from "src/types/components-props";
import defaultImage from "../../assets/images/defaultUser.png";
import { useDispatch } from "react-redux";
import {
  updateAngelCommentsCount,
  updateCharityCommentsCount,
  updateDreamCommentsCount,
} from "src/store/action";

const PublicPostComments = ({ dreamId, type }: PublicPostCommentsProps) => {
  const [commentInput, setCommentInput] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [number, setNumber] = useState(0);
  const [emojiPicker, showEmojiPicker] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === "charity" && dreamId)
      getPublicCharityComments(dreamId).then((res) => {
        setCommentData(res.data.results);
        setNumber(res?.data?.meta?.commentCount);
        dispatch(
          updateCharityCommentsCount(dreamId, res?.data?.meta?.commentCount)
        );
      });
    else if (type === "dream" && dreamId)
      getPublicComments(dreamId).then((res) => {
        setCommentData(res?.data?.results);
        setNumber(res?.data?.meta?.commentCount);
        dispatch(updateDreamCommentsCount(dreamId, res?.data?.meta?.commentCount));
      });
    else if (type === "angel" && dreamId)
      getPublicDreamAngelComments(dreamId).then((res) => {
        setCommentData(res?.data?.results);
        setNumber(res?.data?.meta?.commentCount);
        dispatch(updateAngelCommentsCount(dreamId, res?.data?.meta?.commentCount));
      });
  }, [dreamId]);

  useEffect(() => {
    return () => {
      setCommentData([]);
    };
  }, [dreamId]);

  const commentsList = commentData.map((elem, index) => {
    delete elem.children;
    return (
      <div key={`comment-${index}-${elem?.id}`}>
        <Comment elem={elem} dreamId={dreamId} />
      </div>
    );
  });

  return (
    <div>
      {!!commentsList?.length && (
        <div className="comment-line" onClick={() => navigate("/login")}>
          <ul className="post-comments p-0 m-0 container-fluid mb-1">
            <ul className="ps-0 overflow-hidden" id="dream-comment-scroll">
              {commentsList}
            </ul>
          </ul>
          {commentData?.length > 1 && (
            <div className="mt-2">
              <span className="span-hover-underline text-dark cursor-pointer">
                Read all {number} comments
              </span>
            </div>
          )}
        </div>
      )}
      <InputComment
        currentUserComment={commentInput}
        setComment={setCommentInput}
        emojiPicker={emojiPicker}
        showEmojiPicker={showEmojiPicker}
        mainImage={defaultImage}
        handleComment={(e) => {
          e.key === "Enter" && navigate("/login");
        }}
        addComment={() => navigate("/login")}
        style={{
          position: "unset",
          border: "none",
          marginTop: 0,
        }}
      />
    </div>
  );
};

export default PublicPostComments;
