import React, { useState } from "react";
import DreamCard from "./DreamCard";
import ViewAll from "./ViewAll";
import { getAllDreams } from "../../services/api";
import "../../styles/Sliders.css";
import Carousel from "react-multi-carousel";
import useIsMobile from "../../hooks/useIsMobile";
import { responsive } from "../../consts/breakpoints";
import { SLIDERS_ENUM } from "../../consts/slidersEnum";
import { useQuery } from "@tanstack/react-query";
import { DreamDto } from "../../services/api/data-contracts";
import SliderArrows from "../Partials/SliderArrows";

const PopularDreams = ({ isFulfilled, sliderWidth }) => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useIsMobile(768);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { data: dreams, isLoading } = useQuery<DreamDto[]>({
    queryKey: ["popularDreams"],
    queryFn: () =>
      getAllDreams("DESC", 1, 40, true).then((res) => res.data.results),
    staleTime: 30000,
    cacheTime: 30000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      {dreams.length >= 5 && (
        <div className="dream-app">
          <div className="dream-header">
            <h4 className="dream-slider-title">Popular Dreams:</h4>
            {dreams.length >= 12 && (
              <div className="view-all-button-container">
                <div onClick={handleShowModal} className="view-all-button">
                  <p className="view-all">view all</p>
                </div>
                {!isMobile ? (
                  <SliderArrows containerClass="dream-dreams-container" />
                ) : (
                  <></>
                )}
              </div>
            )}
            <ViewAll
              show={showModal}
              handleClose={handleCloseModal}
              items={dreams}
              isFulfilled={isFulfilled}
              type={SLIDERS_ENUM.POPULAR_DREAM}
            />
          </div>
          {isMobile ? (
            <div className="carousel-wrapper">
              <Carousel
                responsive={responsive}
                className="carousel-width slider-carousel"
                sliderClass="d-flex align-items-center justify-content-between"
                itemClass="dream-card-mobile"
                arrows={false}
                infinite={false}
                swipeable
                draggable
              >
                {dreams.slice(0, 12).map((dream) => (
                  <DreamCard
                    key={dream.id}
                    dream={dream}
                    isFulfilled={isFulfilled}
                  />
                ))}
              </Carousel>
            </div>
          ) : (
            <div
              className="dream-dreams-container"
              style={{ width: sliderWidth }}
            >
              {dreams.slice(0, 12).map((dream) => (
                <DreamCard
                  key={dream.id}
                  dream={dream}
                  isFulfilled={isFulfilled}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PopularDreams;
