import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/mobile.css";

//swiper
import SwiperCore, { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";
import loader from "../../assets/images/page-img/page-load-loader.gif";
//services
import { getUser, login, verifyEmail } from "../../services/api";
import { setDream, setIsAuth, setShowLoader, setUser } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";

//img
import "../../styles/dreamerzLogin.css";
import "../../styles/Dashboard.css";
import { error, logo } from "../../consts/SvgIcon";
import { toast } from "react-toastify";
import PublicNavBar from "../../components/NavBar/PublicNavBar";
import { UsersModule } from "src/types/redux";
import { getInputClass } from "src/utils";
import { useCheckIsAuth } from "src/hooks/useCheckIsAuth";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Login = () => {
  const userId = localStorage.getItem("profileId");
  const notify = (message) => toast.warning(message);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [loginError, setLoginError] = useState("");

  const token = useCheckIsAuth(`newsfeed`, userId);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setLoginError("");
  };

  const handleEmailFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(e.target.value)) {
      setEmailError("Wrong email format");
    } else {
      setEmailError("");
    }
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (emailError) return;
    dispatch(setShowLoader(true));
    setLoginError("");

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profileId");
    dispatch(setUser({}));
    dispatch(setDream({}));
    const { email, password } = formData;
    login({ email: email, password: password }).then((response) => {
      if (!response) {
        setLoginError("Wrong credentials, try again");
        dispatch(setShowLoader(false));
        return;
      }
      dispatch(setIsAuth(true));
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.removeItem("email");
      getUser().then((res) => {
        if (res?.data?.isEmailConfirmed) {
          localStorage.setItem("profileId", res.data.id);
          navigate("/newsfeed");
        } else {
          dispatch(setIsAuth(false));
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("profileId");
          verifyEmail(res?.data?.email);
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Please check your Email");
          dispatch(setShowLoader(false));
        }
      });
    });
  };

  const isFormValid =
    formData.email.trim() !== "" &&
    formData.password.trim() !== "" &&
    !emailError;

  if (token) return <div />;

  return (
    <div className="d-flex justify-content-center align-items-center flex-column screen-size">
      <PublicNavBar />
      <div className="gradient-login-bg">
        <div className="login-pages-container d-flex flex-column align-items-center">
          <div className="form-header mobile-width-hundred">
            <div style={{ color: "black" }}>{logo()}</div>
            <div className="form-header-title">Welcome Back</div>
          </div>
          <Form className="form-body" onSubmit={handleLogin}>
            <div>
              <Form.Group className="login-form-input-box">
                <Form.Label>
                  <h3>E-mail address:</h3>
                </Form.Label>
                <Form.Control
                  style={{ color: "black" }}
                  type="email"
                  className={`mb-0 text-dark ${getInputClass(
                    formData,
                    "email",
                    loginError || emailError
                  )} ${loginError || emailError ? "input-error" : ""}`}
                  id="email"
                  onChange={handleInputChange}
                  onBlur={handleEmailFocusOut}
                />
              </Form.Group>
              <div className="register-info-box content-box-error">
                {emailError && (
                  <div>
                    {error()}
                    <span>{emailError}</span>
                  </div>
                )}
                {loginError && (
                  <div>
                    {error()}
                    <span>{loginError}</span>
                  </div>
                )}
              </div>
              <Form.Group className="login-form-input-box">
                <div style={{ height: "24px" }}>
                  <Form.Label>
                    <h3>Password:</h3>
                  </Form.Label>
                  <Link to="/forgot-password" className="float-end mt-1">
                    Forgot password?
                  </Link>
                </div>
                <Form.Control
                  type="password"
                  className={`mb-0 text-dark ${getInputClass(
                    formData,
                    "password",
                    loginError
                  )} ${loginError ? "input-error" : ""}`}
                  id="password"
                  onChange={handleInputChange}
                  style={{ color: "black" }}
                />
              </Form.Group>
              <div className="register-info-box content-box-error">
                {loginError && (
                  <div>
                    {error()}
                    <span>{loginError}</span>
                  </div>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={!isFormValid}
                className={
                  !isFormValid
                    ? "login-form-disabled"
                    : "login-form-submit-button"
                }
              >
                Log in
              </button>
              {showLoader && (
                <div className="d-flex justify-content-center w-100">
                  <img src={loader} alt="loader" style={{ height: "50px" }} />
                </div>
              )}
            </div>
            <div className="login-box-bottom-text">
              <span>Not a dreamer yet?</span>
              <Link to="/register" className="text-blue">
                Sign Up
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
