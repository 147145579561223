import React from "react";
import DropdownPhoneCard from "./DropdownPhoneCard";
import { CountryInterface } from "src/types/onboarding";
import useIsMobile from "src/hooks/useIsMobile";

interface PhonePrefixesDropdownProps {
  countries: CountryInterface[];
  setPrefix: React.Dispatch<React.SetStateAction<string>>;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
  parentSelectWidth: number;
}

const PhonePrefixesDropdown = ({
  countries,
  setPrefix,
  setClose,
  parentSelectWidth
}: PhonePrefixesDropdownProps) => {
  const isMobile = useIsMobile(390);
  return (
    <div className="dropdown-bg">
      <div
        className="dropdown-bg-transparent"
        onClick={() => setClose(false)}
      ></div>
      <div
        className="search-dropdown-container"
        style={{ width: isMobile ? `${parentSelectWidth}px` : "" }}
      >
        {countries.map((country, index) => (
          <DropdownPhoneCard
            country={country}
            setPrefix={setPrefix}
            setClose={setClose}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default PhonePrefixesDropdown;
