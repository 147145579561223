import React, { useEffect, useRef, useState } from "react";
import {
  createCharitiesComments,
  createComments,
  createDreamAngelComments,
  getCharityComments,
  getComments,
  getDreamAngelComments,
} from "../../services/api";
import InfiniteScroll from "react-infinite-scroller";
import Comment from "./Comment";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import InputComment from "./InputComment";
import { CommentsProps } from "src/types/comments-props";
import { useDispatch } from "react-redux";
import {
  updateAngelCommentsCount,
  updateCharityCommentsCount,
  updateDreamCommentsCount,
} from "src/store/action";

let page2d = 1;
let loader = false;

const Comments = ({
  user,
  mainImg,
  dreamAngelId,
  dream,
  postId,
  isFulfilled,
  type,
}: CommentsProps) => {
  const [seeAllComments, setSeeAllComments] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [commentNumber, setCommentNumber] = useState(0);
  const [hasMore, setHasMore] = useState(Boolean);
  const scrollref = useRef(null);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [emojiPicker, showEmojiPicker] = useState(false);

  useEffect(() => {
    if (type === "angel") {
      getDreamAngelComments(postId, page2d, 10).then((res) => {
        setCommentData(res?.data?.results);
        setHasMore(res?.data?.meta?.hasNextPage);
        setCommentNumber(res?.data?.meta?.commentCount);
        dispatch(updateAngelCommentsCount(postId, res?.data?.meta?.commentCount));
      });
    } else if (type === "charity") {
      getCharityComments(postId, page2d, 10).then((res) => {
        setCommentData(res?.data?.results);
        setHasMore(res?.data?.meta?.hasNextPage);
        setCommentNumber(res?.data?.meta?.commentCount);
        dispatch(
          updateCharityCommentsCount(postId, res?.data?.meta?.commentCount)
        );
      });
    } else if (type === "dream") {
      getComments(postId, page2d, 10).then((res) => {
        setCommentData(res?.data?.results);
        setHasMore(res?.data?.meta?.hasNextPage);
        setCommentNumber(res?.data?.meta?.commentCount);
        dispatch(updateDreamCommentsCount(postId, res?.data?.meta?.commentCount));
      });
    }
    page2d = 1;
  }, [postId]);

  useEffect(() => {
    return () => {
      page2d = 1;
      setCommentData([]);
    };
  }, [postId]);

  const loadMore = () => {
    if (commentData?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        if (type === "angel") {
          getDreamAngelComments(postId, page2d, 10).then((response) => {
            setCommentData((prevState) => [
              ...prevState?.map((el) => ({ ...el })),
              ...response.data.results?.map((el) => ({ ...el })),
            ]);
            setHasMore(response?.data?.meta?.hasNextPage);
            setCommentNumber(response?.data?.meta?.commentCount);
            setCurrentPage(currentPage + 1);
          });
        } else if (type === "charity") {
          getCharityComments(postId, page2d, 10).then((response) => {
            setCommentData((prevState) => [
              ...prevState?.map((el) => ({ ...el })),
              ...response.data.results?.map((el) => ({ ...el })),
            ]);
            setHasMore(response?.data?.meta?.hasNextPage);
            setCommentNumber(response?.data?.meta?.commentCount);
            setCurrentPage(currentPage + 1);
          });
        } else if (type === "dream") {
          getComments(postId, page2d, 10).then((response) => {
            setCommentData((prevState) => [
              ...prevState?.map((el) => ({ ...el })),
              ...response.data.results?.map((el) => ({ ...el })),
            ]);
            setHasMore(response?.data?.meta?.hasNextPage);
            setCommentNumber(response?.data?.meta?.commentCount);
            setCurrentPage(currentPage + 1);
          });
        }
        loader = false;
      }
    }
  };

  const sendMessage = () => {
    if (commentInput?.length) {
      if (type === "charity") {
        createCharitiesComments({
          message: commentInput,
          charityId: postId,
        }).then(() => {
          getCharityComments(postId, 1, 10).then((res) => {
            setCommentData(res?.data?.results);
            setHasMore(res?.data?.meta?.hasNextPage);
            page2d = 1;
            dispatch(
              updateCharityCommentsCount(postId, res?.data?.meta?.commentCount)
            );
            setCommentNumber(res?.data?.meta?.commentCount);
          });
        });
      } else if (type === "angel") {
        createDreamAngelComments({
          message: commentInput,
          dreamAngelId: dreamAngelId,
          newsFeedId: postId,
        }).then(() => {
          getDreamAngelComments(postId, 1, 10).then((res) => {
            setCommentData(res?.data?.results);
            setHasMore(res?.data?.meta?.hasNextPage);
            page2d = 1;
            dispatch(
              updateAngelCommentsCount(postId, res?.data?.meta?.commentCount)
            );
            setCommentNumber(res?.data?.meta?.commentCount);
          });
        });
      } else if (type === "dream") {
        createComments({
          message: commentInput,
          dreamId: postId,
        }).then(() => {
          getComments(postId, 1, 10).then((res) => {
            dispatch(
              updateDreamCommentsCount(postId, res?.data?.meta?.commentCount)
            );
            setCommentData(res?.data?.results);
            setHasMore(res?.data?.meta?.hasNextPage);
            page2d = 1;
            setCommentNumber(res?.data?.meta?.commentCount);
          });
        });
      }
      setCommentInput("");
      let scrollDiv = document.getElementById("commentScroll");
      scrollDiv!.scrollTop = 0;
    }
  };

  const handleComment = (event) => {
    setCommentInput(event.target.value);
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const commentsList = commentData.map((elem, index) => {
    return (
      <div key={`comment-${index}-${elem?.id}`}>
        <Comment
          user={user}
          dreamId={type === "angel" ? dreamAngelId : postId}
          newsFeedId={postId}
          elem={elem}
          addLines
          type={type}
          isFulfilled={isFulfilled}
        />
      </div>
    );
  });

  return (
    <>
      <div
        style={{ height: "auto", marginTop: "0" }}
        className="pb-2 comment-area comment-line"
      >
        <ul className="post-comments p-0 m-0 container-fluid mb-1">
          <ul
            style={
              seeAllComments
                ? { maxHeight: "700px", minHeight: "160px" }
                : { maxHeight: "520px" }
            }
            className="overflow-auto ps-0"
            id="dream-comment-scroll"
            ref={scrollref}
          >
            <InfiniteScroll
              pageStart={currentPage}
              loadMore={() => loadMore()}
              hasMore={hasMore}
              getScrollParent={() => scrollref.current}
              useWindow={false}
              threshold={10}
            >
              {commentsList}

              {loader && (
                <div className="d-flex justify-content-center w-100">
                  <img
                    src={loaderGif}
                    alt="loader"
                    style={{ height: "70px" }}
                  />
                </div>
              )}
            </InfiniteScroll>
          </ul>
        </ul>

        {commentsList.length && commentNumber > 6 ? (<div className="p-1">
          <span
            onClick={() => {
              !!commentsList?.length && setSeeAllComments(!seeAllComments);
            }}
            className={
              !!commentsList?.length
                ? "text-dark span-hover-underline cursor-pointer"
                : "text-white"
            }
          >
            {seeAllComments ? "Hide" : `Read all ${commentNumber} comments`}
          </span>
        </div>) : <></>}
        <InputComment
          className="d-flex align-items-center gap-2 mt-2 mb-2"
          currentUserComment={commentInput}
          setComment={setCommentInput}
          emojiPicker={emojiPicker}
          showEmojiPicker={showEmojiPicker}
          mainImage={mainImg}
          handleComment={(e) => handleComment(e)}
          addComment={() => sendMessage()}
        />
      </div>
    </>
  );
};

export default Comments;
