import { GradientLinkButtonProps } from "src/types/components-props";
import "../../styles/Buttons.css";
import React from "react";

export const GradientLinkButton = ({
  className,
  minWidth,
  textClassName,
  text,
  href,
  children,
  ...props
}: GradientLinkButtonProps) => {
  return (
    <a
      href={href}
      className={className ? className : "gradient-button"}
      style={{ minWidth }}
      {...props}
    >
      <span className={textClassName ? textClassName : ""}>{text}</span>
    </a>
  );
};

export default GradientLinkButton;
