import React, { useEffect, useState } from "react";
import "../../styles/onboarding/onboarding.css";
import "../../styles/onboarding/onboardingMobile.css";
import { useDispatch, useSelector } from "react-redux";
import { OnboardingType } from "src/types/redux";
import Step1 from "src/components/Onboarding/Step1";
import Step2 from "src/components/Onboarding/Step2";
import Step3 from "src/components/Onboarding/Step3";
import Step4 from "src/components/Onboarding/Step4";
import Step5 from "src/components/Onboarding/Step5";
import { getUser } from "src/services/api";
import { setUser } from "src/store/users";
import { setStep } from "src/store/action";
import { useNavigate } from "react-router-dom";
import { getStepNumber } from "src/utils/getStepNumber";

const StepsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const step = useSelector((state: OnboardingType) => state.onboarding).step;
  const allSteps = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5 />];

  useEffect(() => {
    getUser().then((response) => {
      dispatch(setUser(response.data));
      const onboardingStatus = response.data.onboardingStatus;
      const stepNumber = getStepNumber(onboardingStatus);
      dispatch(setStep(stepNumber + 1));
      if (stepNumber >= 5) {
        navigate("/newsfeed");
      }
      setIsLoading(false);
    });
  }, []);

  return (
    !isLoading && (
      <div className="steps-page-container">{allSteps[step - 1]}</div>
    )
  );
};

export default StepsPage;
