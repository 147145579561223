import React from "react";
import "../../styles/CustomInput.css";
import { dollarIcon, ErrorInfo, info } from "../../consts/SvgIcon";

interface CustomInputProps {
  placeholder: string;
  description: string;
  isError?: boolean;
  errorMessage?: string;
  state: string | number;
  setState: React.Dispatch<React.SetStateAction<string | number>>;
  isInfo?: boolean;
  infoMessages?: string[];
  isDisabled?: boolean;
  disabledValue?: string;
  isDollarIcon?: boolean;
}
const CustomInput = ({
  placeholder,
  description,
  isError,
  errorMessage,
  state,
  setState,
  isInfo,
  infoMessages,
  isDisabled,
  disabledValue,
  isDollarIcon,
}: CustomInputProps) => {
  return (
    <div className="custom-input-container">
      <span className="input-description">{description} </span>
      {isDisabled ? (
        <div className="disabled-email-input">
          <span className="gray-description">{disabledValue}</span>
        </div>
      ) : (
        <div className={`custom-input ${isError ? "custom-input-error" : ""}`}>
          {isDollarIcon && dollarIcon()}
          <input
            type="text"
            className="custom-input-field"
            placeholder={placeholder}
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
      )}
      {isInfo &&
        infoMessages.map((message, index) => (
          <div key={index} className="error-info-container">
            <div className="error-info-icon">{info()}</div>
            <span className="error-message">{message}</span>
          </div>
        ))}
      {isError && (
        <div className="error-info-container">
          <div className="error-info-icon">{ErrorInfo()}</div>
          <span className="error-message">{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default CustomInput;
