import React from "react";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { useNavigate } from "react-router-dom";
import { SavedDreamCardProps } from "src/types/messenger";
import MessageAvatar from "./MessageAvatar";
import {
  UserSavedPostDto,
  UserSavedPostNewsfeedDreamAngelDto,
} from "src/services/api/data-contracts";

const SavedDreamAngelProfile = ({
  post,
  dreamAngel,
  borderRadius,
  myMessage = true,
  message,
  isBatch,
  isMyMessageNext = true,
}: SavedDreamCardProps) => {
  const navigate = useNavigate();

  const angel = dreamAngel
    ? dreamAngel
    : (post as UserSavedPostDto)?.newsFeedDreamAngel;

  const user = angel.user;

  return (
    <div
      className={`user-message-container ${
        myMessage ? "my-message-container" : ""
      }`}
      style={{ marginBottom: `${isMyMessageNext ? "0px" : "12px"}` }}
    >
      <MessageAvatar
        myMessage={myMessage}
        message={message}
        isBatch={isBatch}
      />
      <div
        className={`saved-dream-angel-profile-container ${
          !myMessage ? "not-my-message" : ""
        } `}
        style={{
          borderRadius: borderRadius,
          marginLeft: isBatch && !myMessage ? "42px" : "0px",
        }}
        onClick={() => navigate(`/angel/${angel.id}`)}
      >
        <div className="saved-dream-angel-profile-gradient">
          <img
            src={user?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="saved-dream-angel-profile-image"
          />
        </div>
        <div className="tooltip-angel-badge">
          <span className="saved-dream-angel-profile-angel-badge">
            Dream Angel
          </span>
        </div>
        <div className="saved-dream-angel-profile-container-text">
          <span className="saved-dream-angel-profile-name">{`${user?.firstName} ${user?.lastName}`}</span>
          <span className="saved-dream-angel-profile-dreams">
            {(angel as UserSavedPostNewsfeedDreamAngelDto)?.title}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SavedDreamAngelProfile;
