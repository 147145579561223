import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import defaultImage from "../assets/images/defaultUser.png";
import { DonationDto, DreamDto, PageDto } from "../services/api/data-contracts";

// As we do not know what the user object will contain, will only use a mock object that has a single field name.

const defaultDream: DreamDto = {
  id: "",
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  title: "",
  status: "NOT_STARTED",
  likedDreamsByUsers: [],
  amount: 0,
  amountReceived: 0,
  progress: 0,
  images: [],
  user: undefined,
  deletedAt: undefined,
  donations: [],
  isSaved: false,
};

export const userSlice = createSlice({
  name: "users",
  initialState: {
    profileCompleted: false,
    isAuth: false,
    showLoader: false,
    bgLoader: false,
    user: {
      images: [],
      dreams: [],
    },
    publicUser: [],
    dreams: [],
    charities: [],
    posts: [],
    dream: defaultDream,
    mainImage: defaultImage,
    errorMessage: "",
    profileFulfilled: {
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      results: [],
    },
    allProfileFulfilled: [],
    allProfileWing: [],
    profileReceived: {
      results: [],
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
    profileWingDonations: {
      results: [],
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
    publicDream: {},
    publicFulfilled: {
      results: [],
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
    publicReceived: {
      results: [],
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
    publicWingDonations: {
      results: [],
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
    currentPage: 1,
    order: "DESC",
    wingDonations: {
      results: [],
      meta: {
        page: 0,
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
    allDreamPayments: [],
    notifications: [],
    admins: [],
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setAdmins: (state, action) => {
      state.admins = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setCompletedProfile: (state, action) => {
      state.profileCompleted = action.payload;
    },
    setAllDreamPayments: (state, action) => {
      state.allDreamPayments = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setAllProfileFulfilled: (state, action) => {
      state.allProfileFulfilled = action.payload;
    },
    setAllProfileWing: (state, action) => {
      state.allProfileWing = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.mainImage =
        action?.payload?.images?.find((image) => image?.isMain === true) ||
        defaultImage;
      action?.payload?.images?.sort(
        (a, b) => (b?.isMain === true ? 1 : 0) - (a?.isMain === true ? 1 : 0)
      );
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setShowLoader: (state, action) => {
      state.showLoader = action.payload;
    },
    setBgLoader: (state, action) => {
      state.bgLoader = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setAllDreams: (state, action) => {
      state.dreams = action.payload;
    },
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setCharity: (state, action) => {
      state.charities = action.payload;
    },
    setAddPosts: (state, action) => {
      state.posts = [
        ...state.posts.map((el) => ({ ...el })),
        ...action.payload.map((el) => ({ ...el })),
      ];
    },
    setAddDreams: (state, action) => {
      state.dreams = [
        ...state.dreams.map((el) => ({ ...el })),
        ...action.payload.map((el) => ({ ...el })),
      ];
    },
    setAddProfileFulfilled: (
      state,
      action: PayloadAction<PageDto & { results: DonationDto[] }>
    ) => {
      state.profileFulfilled = {
        meta: action.payload?.meta,
        results: [
          ...state.profileFulfilled?.results?.map((el) => ({ ...el })),
          ...action.payload?.results?.map((el) => ({ ...el })),
        ],
      };
    },
    setAddProfileReceived: (state, action) => {
      state.profileReceived = {
        meta: action.payload?.meta,
        results: [
          ...state.profileReceived?.results?.map((el) => ({ ...el })),
          ...action.payload?.results?.map((el) => ({ ...el })),
        ],
      };
    },
    setSpliceDreams: (state, action) => {
      const newDreams = JSON.parse(JSON.stringify(state.dreams));
      newDreams[newDreams.findIndex((el) => el.id === action.payload.id)] = {
        ...action.payload,
      };
      state.dreams = newDreams;
    },
    setWingDonations: (state, action) => {
      state.wingDonations = action.payload;
    },
    setAddWingDonations: (state, action) => {
      state.wingDonations = {
        meta: { ...action.payload?.meta },
        results: [
          ...state.wingDonations?.results?.map((el) => ({ ...el })),
          ...action.payload?.results?.map((el) => ({ ...el })),
        ],
      };
    },
    setDream: (state, action) => {
      // const cloneDream = action.payload?.results?.find((dream) => dream.status !== 'COMPLETED') || {...action.payload?.results?.[action.payload?.results.length - 1]}
      const cloneDream = { ...action.payload?.results?.[0] };
      cloneDream?.images?.sort((a, b) => b.isMain - a.isMain);
      state.dream = cloneDream;
    },
    setPublicUser: (state, action) => {
      const clonePublicUser = { ...action.payload };
      clonePublicUser?.images?.sort((a, b) => {
        return b.isMain - a.isMain;
      });
      state.publicUser = clonePublicUser;
    },
    setProfileFulfilled: (state, action) => {
      state.profileFulfilled = action.payload;
    },
    setProfileReceived: (state, action) => {
      state.profileReceived = action.payload;
    },
    setProfileWingDonations: (state, action) => {
      state.profileWingDonations = action.payload;
    },
    setAddProfileWingDonations: (state, action) => {
      state.profileWingDonations = {
        meta: action.payload?.meta,
        results: [
          ...state.profileWingDonations?.results?.map((el) => ({ ...el })),
          ...action.payload?.results?.map((el) => ({ ...el })),
        ],
      };
    },
    setPublicDream: (state, action) => {
      const cloneDream = { ...action.payload };
      cloneDream?.images?.sort((a, b) => {
        return b.isMain - a.isMain;
      });
      state.publicDream = cloneDream;
    },
    setPublicFulfilled: (state, action) => {
      state.publicFulfilled = action.payload;
    },
    setAddPublicFulfilled: (
      state,
      action: PayloadAction<PageDto & { results: DonationDto[] }>
    ) => {
      state.publicFulfilled = {
        meta: action.payload?.meta,
        results: [
          ...state.publicFulfilled?.results?.map((el) => ({ ...el })),
          ...action.payload?.results?.map((el) => ({ ...el })),
        ],
      };
    },
    setPublicWingDonations: (state, action) => {
      state.publicWingDonations = action.payload;
    },
    setAddPublicWingDonations: (state, action) => {
      state.publicWingDonations = {
        meta: action.payload?.meta,
        results: [
          ...state.publicWingDonations?.results?.map((el) => ({ ...el })),
          ...action?.payload?.results?.map((el) => ({ ...el })),
        ],
      };
    },
    setPublicReceived: (state, action) => {
      state.publicReceived = action.payload;
    },
    setAddPublicReceived: (state, action) => {
      state.publicReceived = {
        meta: { ...action.payload?.meta },
        results: [
          ...state.publicReceived?.results?.map((el) => ({ ...el })),
          ...action.payload?.results?.map((el) => ({ ...el })),
        ],
      };
    },
  },
});

export const {
  setUser,
  setIsAuth,
  setShowLoader,
  setErrorMessage,
  setAllDreams,
  setCharity,
  setDream,
  setPublicUser,
  setBgLoader,
  setProfileFulfilled,
  setProfileReceived,
  setProfileWingDonations,
  setAddProfileWingDonations,
  setPublicDream,
  setPublicFulfilled,
  setPublicWingDonations,
  setPublicReceived,
  setAddPublicReceived,
  setAddPublicFulfilled,
  setAddDreams,
  setAddPosts,
  setAddProfileFulfilled,
  setAddProfileReceived,
  setAddPublicWingDonations,
  setCurrentPage,
  setOrder,
  setSpliceDreams,
  setWingDonations,
  setAddWingDonations,
  setAllProfileFulfilled,
  setAllProfileWing,
  setAllDreamPayments,
  setCompletedProfile,
  setNotifications,
  setAdmins,
  setPosts,
} = userSlice.actions;

export default userSlice.reducer;
