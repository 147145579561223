import React from "react";
import messageIcon from "../../../../assets/images/message-requests.svg";

const NoMessageRequests = () => {
  return (
    <div className="no-message-requests-container">
      <img src={messageIcon} alt="messages" />
      <span className="info-title">Message Requests</span>
      <span className="info-description">
        These messages are from people you have never chatted with. They won't
        know you viewed their request until you allow them to message you.
      </span>
    </div>
  );
};

export default NoMessageRequests;
