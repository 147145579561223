import { emoji, sendIcon, chatEmoji, chatSendIcon } from "../../consts/SvgIcon";
import React, { useEffect, useRef } from "react";
import EmojiPicker from "emoji-picker-react";
import { EmojiMenuProps } from "src/types/components-props";
import { useDispatch } from "react-redux";
import { setActiveReply } from "src/store/action";

const EmojiMenu = ({
  showEmojiPicker,
  emojiPicker,
  setText,
  sendMessage,
  reply,
  currentUserComment,
  isSendIcon = true,
  isChat = false,
}: EmojiMenuProps) => {
  const emojiRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        showEmojiPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiRef]);

  return (
    <div className={isChat ? "chat-icons" : "comment-icons"} ref={emojiRef}>
      <div
        className="emoji-icon-container"
        onClick={() => showEmojiPicker((prev: boolean) => !prev)}
      >
        {isChat ? chatEmoji() : emoji()}
      </div>
      {emojiPicker && (
        <div
          className={
            reply
              ? "emoji-picker-container-bottom"
              : isChat
              ? "emoji-picker-container-top-chat"
              : "emoji-picker-container-top"
          }
        >
          <EmojiPicker
            preload={true}
            disableSearchBar={true}
            onEmojiClick={(e, emojiObject) =>
              setText((prev) => prev + emojiObject.emoji)
            }
            pickerStyle={{
              border: "none",
              position: "sticky",
              zIndex: "10",
            }}
          />
        </div>
      )}
      {isChat ? (
        <div
          className={`chat-send-icon-container  ${
            isSendIcon ? "see-chat-icon" : "no-see-chat-icon"
          }`}
        >
          {isSendIcon && (
            <div
              className={
                !!currentUserComment?.length
                  ? "chat-emoji-container text-dark"
                  : "chat-emoji-container"
              }
              onClick={(e) => {
                sendMessage(e);
                dispatch(setActiveReply(""));
                showEmojiPicker(false);
              }}
            >
              {chatSendIcon()}
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            !!currentUserComment?.length
              ? "emoji-container text-dark"
              : "emoji-container"
          }
          onClick={(e) => {
            sendMessage(e);
            dispatch(setActiveReply(""));
            showEmojiPicker(false);
          }}
        >
          {sendIcon()}
        </div>
      )}
    </div>
  );
};
export default EmojiMenu;
