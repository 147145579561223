import React from "react";
import Step5 from "src/components/Onboarding/Step5";

const Pricing = () => {
  return (
    <div className="steps-page-container">
      <Step5 isPricingPage />
    </div>
  );
};

export default Pricing;
