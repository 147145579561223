import React from "react";
import { UserMessageProps } from "src/types/messenger";
import MessageAvatar from "./MessageAvatar";

const UserMessage = ({
  message,
  borderRadius,
  myMessage,
  isMyMessageNext,
  isBatch,
}: UserMessageProps) => {
  if (message.content === "") {
    return null;
  }

  return (
    <div
      className={`user-message-container ${
        myMessage ? "my-message-container" : ""
      }`}
      style={{ marginBottom: `${isMyMessageNext ? "0px" : "12px"}` }}
    >
      <MessageAvatar
        myMessage={myMessage}
        message={message}
        isBatch={isBatch}
      />
      <div
        className={`message-container  ${myMessage ? "my-message" : ""}`}
        style={{
          borderRadius: borderRadius,
          marginLeft: isBatch && !myMessage ? "42px" : "0px",
        }}
      >
        <span
          className={`message-text ${myMessage ? "message-text-right" : ""}`}
        >
          {message?.content}
        </span>
      </div>
    </div>
  );
};

export default UserMessage;
