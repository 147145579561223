import React, { useRef } from "react";
import DreamCard from "../Sliders/DreamCard";
import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import { SLIDERS_ENUM } from "src/consts/slidersEnum";
import AngelsFulfilledCard from "./AngelsFulfilledCard";
import UsersFulfilledCard from "./UsersFulfilledCard";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  getDonationByUserId,
  getPublicDreamDonations,
  getWingDonations,
} from "src/services/api";
import {
  dreamMakersIds,
  extractDreamMakerData,
  fetchDonationMakersInfo,
  fetchDreamMakersInfo,
  fetchUserDonationDream,
  mergeDonations,
  processDonationMakersResponses,
  processPublicDreamResponses,
} from "src/utils";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import DreamMakersNew from "../Profile/DreamMakersNew";

export default function InfiniteViewAllModal({
  id,
  type,
  show,
  handleClose,
  isFulfilled,
}) {
  const scrollref = useRef(null);

  // Infinite WING_PAGE
  const {
    data: wingData,
    fetchNextPage: fetchNextWingPage,
    hasNextPage: hasNextWingPage,
  } = useInfiniteQuery(
    ["wingPage", id],
    async ({ pageParam = 1 }) => {
      const { data } = await getWingDonations(id, pageParam);
      const donations = data?.results || [];
      const userDetails = await fetchDonationMakersInfo(donations);
      return {
        data: processDonationMakersResponses(userDetails),
        page: pageParam,
      };
    },
    {
      enabled: type === SLIDERS_ENUM.WING_PAGE,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.length > 0 ? lastPage.page + 1 : undefined,
      staleTime: 5 * 60 * 1000,
    }
  );

  // Infinite ANGEL_PAGE
  const {
    data: angelData,
    fetchNextPage: fetchNextAngelPage,
    hasNextPage: hasNextAngelPage,
  } = useInfiniteQuery(
    ["angelPage", id],
    async ({ pageParam = 1 }) => {
      const { data } = await getDonationByUserId(id, pageParam);
      const donations = data?.results || [];
      const dreamDetails = await fetchUserDonationDream(donations);
      const processedDreams = processPublicDreamResponses(dreamDetails);
      return {
        dreams: processedDreams,
        hasNextPage: data.meta.hasNextPage,
        page: pageParam,
      };
    },
    {
      enabled: type === SLIDERS_ENUM.ANGEL_PAGE,
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage ? lastPage.page + 1 : undefined,
      staleTime: 5 * 60 * 1000,
    }
  );

  // Infinite DREAM_MAKERS
  const {
    data: dreamMakers,
    fetchNextPage: fetchNextDreamMakersPage,
    hasNextPage: hasNextDreamMakersPage,
  } = useInfiniteQuery(
    ["dreamMakers", id],
    async ({ pageParam = 1 }) => {
      const { data } = await getPublicDreamDonations(id, pageParam);
      const donations = data?.results || [];
      const donationUsers = dreamMakersIds(donations);
      const fulfilledData = await fetchDreamMakersInfo(donationUsers);
      const userDataDet = extractDreamMakerData(fulfilledData);
      const fulfilledDatat = mergeDonations(donations, userDataDet);
      return {
        dreams: fulfilledDatat,
        hasNextPage: data.meta.hasNextPage,
        page: pageParam,
      };
    },
    {
      enabled: type === SLIDERS_ENUM.DREAM_MAKERS,
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage ? lastPage.page + 1 : undefined,
      staleTime: 5 * 60 * 1000,
    }
  );

  const loadMore = () => {
    if (type === SLIDERS_ENUM.WING_PAGE) {
      fetchNextWingPage();
    } else if (type === SLIDERS_ENUM.ANGEL_PAGE) {
      fetchNextAngelPage();
    } else if (type === SLIDERS_ENUM.DREAM_MAKERS) {
      fetchNextDreamMakersPage();
    }
  };

  const hasNextPage =
    type === SLIDERS_ENUM.WING_PAGE
      ? hasNextWingPage
      : type === SLIDERS_ENUM.ANGEL_PAGE
      ? hasNextAngelPage
      : type === SLIDERS_ENUM.DREAM_MAKERS
      ? hasNextDreamMakersPage
      : false;

  return (
    <Modal
      backdrop={true}
      centered
      show={show}
      onHide={handleClose}
      animation={true}
      contentClassName="border-0"
      dialogClassName="modal-xlg"
      style={{ borderRadius: "8px" }}
    >
      <div className="view-all-header">
        <h4 className="dream-slider-title">{type}</h4>
        <img
          onClick={handleClose}
          src="/interact/close.svg"
          alt="close button"
          style={{ cursor: "pointer", position: "absolute", right: "16px" }}
        />
      </div>
      <div
        className="items-container"
        ref={scrollref}
        style={{ overflowY: "auto" }}
      >
        <InfiniteScroll
          pageStart={0}
          getScrollParent={() => scrollref.current}
          loadMore={loadMore}
          hasMore={hasNextPage}
          loader={
            <div className="d-flex justify-content-center w-100" key="loader">
              <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
            </div>
          }
          useWindow={false}
        >
          <div className="items-container" style={{ padding: "0" }}>
            {type === SLIDERS_ENUM.WING_PAGE &&
              wingData?.pages?.map((page) =>
                page.data.map((dream) => (
                  <div
                    key={dream.id}
                    className="view-all-birthday-modal d-flex align-items-center"
                  >
                    {dream?.role === "ANGEL" || dream?.dreams.length <= 0 ? (
                      <AngelsFulfilledCard angel={dream} key={dream?.id} />
                    ) : (
                      <UsersFulfilledCard dream={dream} key={dream?.id} />
                    )}
                  </div>
                ))
              )}

            {type === SLIDERS_ENUM.ANGEL_PAGE &&
              angelData?.pages?.map((page) =>
                page.dreams.map((dream) => (
                  <DreamCard
                    key={dream?.id}
                    dream={dream}
                    isFulfilled={isFulfilled}
                  />
                ))
              )}

            {type === SLIDERS_ENUM.DREAM_MAKERS &&
              dreamMakers?.pages?.map((page) =>
                page.dreams?.map((dream) => (
                  <div className="view-all-birthday-modal d-flex align-items-center">
                    <DreamMakersNew
                      key={dream.id}
                      donations={dream}
                      isFulfilled={isFulfilled}
                    />
                  </div>
                ))
              )}
          </div>
        </InfiniteScroll>
      </div>
    </Modal>
  );
}
