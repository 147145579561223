import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserChats } from "src/services/api";
import { socket } from "src/socket";
import {
  setRequestsChats,
  setMessengerChats,
  setCanceledChats,
} from "src/store/action";

const RequestSent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("request-sent", (data) => {
      getUserChats(false).then((res) => dispatch(setRequestsChats(res.data)));
      getUserChats(true).then((res) => {
        dispatch(setMessengerChats(res.data));
        const canceledChats = res.data.filter(
          (chat) => chat.lastMessage.content === ""
        );

        dispatch(setCanceledChats(canceledChats));
      });
    });

    return () => {
      socket.off("request-sent");
    };
  }, []);
  return <></>;
};

export default RequestSent;
