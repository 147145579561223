import React from "react";
import defaultIcon from "../../../assets/images/no-profile-image.svg";
import useIsMobile from "src/hooks/useIsMobile";

interface UploadProfilePhotosProps {
  setUploadedPhotos: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedPhotos: File[];
  isDisabled?: boolean;
  addedImages?: any;
}

const UploadProfilePhotos = ({
  setUploadedPhotos,
  uploadedPhotos,
  isDisabled,
  addedImages,
}: UploadProfilePhotosProps) => {
  const isMobile = useIsMobile(750);

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadedPhotos.length < 5) {
      const images = Array.from(e.target.files || []);
      setUploadedPhotos((prev) => {
        const newPhotos = [...prev, ...images].slice(0, 5);
        return newPhotos;
      });
    }
  };

  const handleDeleteProfileImg = (index: number) => {
    setUploadedPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  const displayPhotos = isDisabled
    ? [...addedImages, ...Array(5 - addedImages.length).fill(null)]
    : [...uploadedPhotos, ...Array(5 - uploadedPhotos.length).fill(null)];

  const isActiveBtn = isDisabled ? false : uploadedPhotos.length < 5;

  return (
    <div className="upload-photos-container">
      <div className="uploaded-pictures">
        {displayPhotos.map((photo, index) => (
          <div key={index} className="rounded-pictures-white-bg">
            <div className="rounded-pictures">
              <img
                src={
                  isDisabled
                    ? photo
                      ? photo
                      : defaultIcon
                    : photo
                    ? URL.createObjectURL(photo)
                    : defaultIcon
                }
                alt="profile-icon"
                className="profile-image-uploaded"
              />
              {photo && !isDisabled && (
                <img
                  src="../../interact/delete-image.svg"
                  alt="Delete"
                  className={
                    isMobile
                      ? "profile-edit-delete-image"
                      : "profile-image-uploaded-deleted"
                  }
                  style={{ right: "1px" }}
                  onClick={() => handleDeleteProfileImg(index)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {isActiveBtn ? (
        <div className="custom-gradient sliders-button tooltip-angel-button-text upload-btn">
          <input
            className="input-file-upload"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            name="image"
            id="image"
            multiple
            onChange={handleProfileImageChange}
          />
          Upload
        </div>
      ) : (
        <div className="disabled-btn upload-btn">
          <span className="disabled-text">Upload</span>
        </div>
      )}
      <span className="upload-info"> *at least one picture</span>
    </div>
  );
};

export default UploadProfilePhotos;
