import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import GradientButton from "src/components/Buttons/GradientButton";
import close from "../../../assets/images/search-modal-close.svg";
import { getSearchedUsers, getUserChats } from "src/services/api";
import SendModalCard from "./MessengerPartials/SendModalCard";
import { socket } from "src/socket";
import { SendModalProps } from "src/types/messenger";
import { setMessengerChats } from "src/store/action";
import { useDispatch, useSelector } from "react-redux";
import { ChatType } from "src/types/redux";
import { UserDto } from "src/services/api/data-contracts";

const SendModal = ({
  showSentModal,
  setShowSentModal,
  post,
  dream,
  charity,
  anotherModalOpen = false,
  setSharedCount,
}: SendModalProps) => {
  const dispatch = useDispatch();
  const [usersSelected, setUsersSelected] = useState<UserDto[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [timer, setTimer] = useState(null);
  const [allSearchedUsers, setAllSearchedUsers] = useState<UserDto[]>([]);
  const [allUsersCard, setAllUsersCard] = useState([]);
  const currentUserId = localStorage.getItem("profileId");

  const messengerChats = useSelector(
    (state: ChatType) => state.messages
  ).messengerChats;

  const firstChats = messengerChats.map((chat) => chat.user).slice(0, 5);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        if (e.target.value) {
          getSearchedUsers(e.target.value).then((res) => {
            return setAllSearchedUsers(res.data);
          });
        } else {
          setAllSearchedUsers([]);
        }
      }, 1000)
    );
  };

  useEffect(() => {
    getUserChats(true).then((res) => dispatch(setMessengerChats(res.data)));
    socket.emit("join-room", { userId: currentUserId });
  }, []);

  useEffect(() => {
    const allChats = [ ...allSearchedUsers, ...firstChats, ...usersSelected];

    const uniqueIds = new Set();
    const uniqueUsers = allChats.filter((user) => {
      if (!uniqueIds.has(user.id)) {
        uniqueIds.add(user.id);
        return true;
      }
      return false;
    });

    const filterCurrentUser = uniqueUsers.filter(
      (user) => user.id !== currentUserId
    );

    setAllUsersCard(filterCurrentUser);
  }, [allSearchedUsers]);

  const sentSavedPosts = () => {
    const usersSelectedIds = usersSelected.map((user) => user?.id);
    if (setSharedCount) {
      setSharedCount((prev) => prev + usersSelectedIds.length);
    }
    let shareId;

    if (dream) {
      shareId = { dreamId: dream.id };
    } else if (charity) {
      shareId = { charityId: charity.id };
    } else {
      shareId = { newsFeedId: post.id };
    }

    socket.emit("share-post", {
      ...shareId,
      from: currentUserId,
      to: usersSelectedIds,
    });
    setShowSentModal(false);
  };

  return (
    <Modal
      backdrop={true}
      centered
      show={!!showSentModal}
      onHide={() => setShowSentModal(false)}
      animation={true}
      contentClassName="border-0"
      style={{ borderRadius: "8px" }}
      className="search-bootstrap-modal"
    >
      {anotherModalOpen && (
        <div className="modal-bg-opacity">
          <div className="modal-bg-opacity-color"></div>
        </div>
      )}
      <div className="search-modal-container">
        <div className="search-modal-title">
          <span>Send to:</span>
          <div
            onClick={() => setShowSentModal(false)}
            style={{ cursor: "pointer" }}
          >
            <img src={close} alt="x" />
          </div>
        </div>
        <input
          type="text"
          placeholder="Search.."
          className="search-modal-input"
          value={inputValue}
          onChange={(e) => handleChange(e)}
        />
        <div className="search-modal-line"></div>
        <div className="search-modal-results">
          {allUsersCard?.map((user) => {
            return (
              <SendModalCard
                key={user?.id}
                user={user}
                usersSelected={usersSelected}
                setUsersSelected={setUsersSelected}
              />
            );
          })}
        </div>
        <GradientButton
          onClick={sentSavedPosts}
          text="Send"
          textClassName={` ${
            usersSelected?.length === 0
              ? "disabled-text"
              : "search-modal-btn-text"
          }`}
          className={` ${
            usersSelected?.length === 0 ? "disabled-btn" : "gradient-button"
          }`}
        />
      </div>
    </Modal>
  );
};

export default SendModal;
