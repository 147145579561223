import React from "react";
import screen from "../../assets/images/secured-donation-cropwebp.webp";
import secureIcon from "../../assets/images/secyrely.svg";
import checkedIcon from "../../assets/images/checkedSvg.svg";
import GradientLinkButton from "../Buttons/GradientLinkButton";

const SecuredDonationSection = () => (
  <div className="d-flex align-items-center justify-content-center secondBg-desktop">
    <div className="section-container-row section-container-row-secured secured-container-gap">
      <div className="secured-card d-flex justify-content-center align-items-start flex-column w-100">
        <img src={secureIcon} alt="secure" height={64} />
        <h3 className="secured-title text-white">Secured Donations</h3>
        <p className="secure-text">
          The platform provides a strict trackkeeping of your balance. Every
          made and received donation will be confidently saved in separate
          sections of your personal account
        </p>
        <div className="check-list">
          <span className="list-element margin-b">
            <img src={checkedIcon} alt="secure" height={24} />
            <span className="list-text">
              You choose who do you want to support
            </span>
          </span>
          <span className="list-element margin-b">
            <img src={checkedIcon} alt="secure" height={24} />
            <span className="list-text">One dream at a time </span>
          </span>
          <span className="list-element">
            <img src={checkedIcon} alt="secure" height={24} />
            <span className="list-text">
              Transparent Fulfilled and Received Donations history
            </span>
          </span>
        </div>
        <div className="secure-btn">
          <GradientLinkButton
            href="/login"
            text="Start receiving donations"
            style={{ height: "60px", width: "100%" }}
            textClassName="secured-button-text"
            className="start-gradient-button"
          />
        </div>
      </div>
      <div className="secured-card">
        <img
          src={screen}
          loading="lazy"
          alt="Fulfill someone's dream to fulfill yours"
          className="secured-image"
        />
      </div>
    </div>
  </div>
);

export default SecuredDonationSection;
