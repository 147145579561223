import { OnboardingActionTypes, SET_STEP } from "./action";

export interface OnboardingState {
  step: number;
}

const initialState: OnboardingState = {
  step: 1,
};

const onboardingReducer = (
  state = initialState,
  action: OnboardingActionTypes
): OnboardingState => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    default:
      return state;
  }
};

export default onboardingReducer