import React from "react";
import GradientLinkButton from "../Buttons/GradientLinkButton";

const StepsSection = () => (
  <div className="steps-container">
    <div className="white-container-num">
      <div className="number-container d-flex flex-column gap-5">
        <h2 className="window-text-steps">How Dreamerz works:</h2>
        <div className="d-flex flex-column gap-4 flex-num position-relative">
          <div className="line position-absolute" style={{ left: "15px" }} />
          <div className="d-flex gap-3" style={{ zIndex: 1 }}>
            <div className="number-circle">1</div>
            <span className="num-text ">Create and share your dream</span>
          </div>
          <div className="d-flex gap-3" style={{ zIndex: 1 }}>
            <div>
              <div className="number-circle">2</div>
            </div>
            <p className="num-text">
              Start donating with only 1$ to other dreamers to get exposure and
              inspire support
            </p>
          </div>
          <div className="d-flex gap-3 " style={{ zIndex: 1 }}>
            <div>
              <div className="number-circle">3</div>
            </div>
            <p className="num-text">
              Gain visibility in a global community with each donation you make
            </p>
          </div>
          <div className="d-flex gap-3" style={{ zIndex: 1 }}>
            <div>
              <div className="number-circle">4</div>
            </div>
            <p className="num-text ">
              Attract donors to your own dream through your increased exposure
              and achieve your dreams faster and for less personal cost
            </p>
          </div>
          <div className="d-flex gap-3" style={{ zIndex: 1 }}>
            <div>
              <div className="number-circle">5</div>
            </div>
            <span className="num-text ">
              Withdraw funds and turn your dream into reality!
            </span>
          </div>
        </div>
        <div style={{ width: "225px" }}>
          <GradientLinkButton
            href="/login"
            className="start-gradient-button"
            text="Get my dream fulfilled "
            style={{
              height: "60px",
              width: "262px",
              fontSize: "18px",
              color: "black",
            }}
            minWidth={"225px"}
          />
        </div>
      </div>
      <div className="gradient-container-steps" style={{ minWidth: "320px" }}>
        <div className="padding-gradient d-flex flex-column ">
          <h5 className="fw-bold text-black">
            How you will receive your dream fulfilled:
          </h5>
          <span className="text-black">
            Everyone has to donate in order to fulfill their dreams. For every
            $1 you donate, it creates a 10x higher visibility worldwide, which
            helps you attract more donors to your dream, allowing you to achieve
            your goal while only contributing half the total cost yourself. The
            more you give, the more likely you are to receive!
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default StepsSection;
