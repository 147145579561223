import React from "react";
import defaultImage from "../../assets/images/defaultUser.png";
import { useRoleCheck } from "src/hooks/useRoleCheck";
import { formatTimeDuration } from "src/utils/formatTimeDuration";
import { useNavigate } from "react-router-dom";

const DreamMakersModalCard = ({ user, closeModal, marginBottom }) => {
  const navigate = useNavigate();
  const hasDream = useRoleCheck(user);
  const activeTime = formatTimeDuration((user as any)?.activeAt);

  const lastTimeActive =
    (user as any)?.activeAt === null
      ? (user as any).socketId !== null
        ? "active now"
        : "active a long time ago"
      : activeTime === "now"
      ? `active now`
      : `active ${activeTime} ago`;

  return (
    <div className="dream-makers-modal-card" style={{ marginBottom }}>
      <div
        className={`${!hasDream ? "gradient-border" : ""}`}
        onClick={() => {
          closeModal();
          navigate(`/dashboard/public-page/${user?.id}`);
        }}
      >
        <img
          src={user?.mainImageUrl || defaultImage}
          alt="profile-img"
          className="avatar cursor-pointer"
          style={{ borderRadius: "50%" }}
        />
      </div>
      <div className="send-user-info-container">
        <div className="send-user-name-role">
          <span
            id="username"
            className="send-user-name-chat cursor-pointer text-underline"
            onClick={() => {
              closeModal();
              navigate(`/dashboard/public-page/${user?.id}`);
            }}
          >
            {`${user?.firstName || "No name"}
               ${user?.lastName || ""}`}
          </span>
          {!hasDream && (
            <div className="tooltip-angel-badge-chat-card send-angel-margin">
              <span className="chat-card-angel-text">Dream Angel</span>
            </div>
          )}
        </div>

        <span className="last-message">{lastTimeActive}</span>
      </div>
    </div>
  );
};

export default DreamMakersModalCard;
