import React from "react";
import { CountryInterface } from "src/types/onboarding";

interface DropdownPhoneCardProps {
  country: CountryInterface;
  setPrefix: React.Dispatch<React.SetStateAction<string>>;
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
}
const DropdownPhoneCard = ({
  country,
  setPrefix,
  setClose,
}: DropdownPhoneCardProps) => {
  return (
    <div
      className="dropdown-card-container"
      onClick={() => {
        setPrefix(country.numberPrefix);
        setClose(false);
      }}
    >
      <img src={country.flag} alt="flag-icon" className="flag" />
      <div className="country-info">
        <span className="country-name">{country.name}</span>
        <span className="country-name">{country.numberPrefix}</span>
      </div>
    </div>
  );
};
export default DropdownPhoneCard;
