import React, { useEffect, useState } from "react";
import "../../styles/messenger/messenger.css";
import "../../styles/messenger/messengerMobile.css";
import useIsMobile from "../../hooks/useIsMobile";
import ChatSection from "../../components/Messenger/ChatSection";
import ChatListSection from "../../components/Messenger/ChatListSection";
import SearchChatModal from "src/components/Messenger/MessengerComponents/SearchChatModal";
import NavBarComponent from "src/components/NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { ChatType } from "src/types/redux";
import { getUserById, getUserChats } from "src/services/api";
import {
  setCanceledChats,
  setMessengerChats,
  setRequestsChats,
} from "src/store/action";
import RequestSent from "src/events/RequestSent";
import RequestAccept from "src/events/RequestAccept";
import { setAllProfileFulfilled, setUser } from "src/store/users";
import RoomJoined from "src/events/RoomJoined";
import CreateMessage from "src/events/CreateMessage";
import { socket } from "src/socket";

const MessengerPage = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(600);
  const isTablet = useIsMobile(900);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [requestAccept, setRequestAccept] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const chatType = useSelector((state: ChatType) => state.messages).chatType;

  const canceledChats = useSelector(
    (state: ChatType) => state.messages
  ).canceledChats;
  const currentUserId = localStorage.getItem("profileId");
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  useEffect(() => {
    getUserChats(false).then((res) => dispatch(setRequestsChats(res.data)));

    getUserChats(true).then((res) => {
      dispatch(setMessengerChats(res.data));

      const canceledChats = res.data.filter(
        (chat) => chat.lastMessage.content === ""
      );
      dispatch(setCanceledChats(canceledChats));
      setIsSuccess(true);
    });
    getUserById(currentUserId).then((res) => {
      const allProfileFulfilled = res.data.donations.map(
        (donation) => donation.dreamId
      );

      dispatch(setAllProfileFulfilled(allProfileFulfilled));
      dispatch(setUser(res.data));
    });
  }, [accessToken, refreshToken, requestAccept]);

  useEffect(() => {
    if (canceledChats.length) {
      socket.emit("delete-chat", {
        to: canceledChats[0].lastMessage.to,
        from: canceledChats[0].lastMessage.from,
      });
    }
  }, [chatType]);

  let startEvent = false;
  if (isMobile && chatType === "") {
    startEvent = true;
  } else if (!isMobile && (chatType === "saved" || chatType === "")) {
    startEvent = true;
  }

  return (
    <div>
      {!isTablet && <NavBarComponent />}
      <div className="messenger-page-container">
        <RequestSent />
        <RequestAccept setState={setRequestAccept} />
        <RoomJoined />
        {startEvent && <CreateMessage isSuccess={isSuccess} />}
        {showSearchModal && (
          <SearchChatModal
            showSearchModal={showSearchModal}
            setShowSearchModal={setShowSearchModal}
          />
        )}
        <div className="messenger-container">
          {isMobile && chatType !== "" && chatType !== "requestsList" ? (
            <ChatSection />
          ) : (
            <ChatListSection setShowSearchModal={setShowSearchModal} />
          )}
          {!isMobile && <ChatSection />}
        </div>
      </div>
    </div>
  );
};
export default MessengerPage;
