import React from "react";
import NoMessageRequests from "./MessengerPartials/NoMessageRequests";

const RequestsSection = () => {
  const noRequests = true;
  return (
    <div className="saved-section-container">
      {noRequests && (
        <div className="no-requests-container">
          <NoMessageRequests />
        </div>
      )}
    </div>
  );
};

export default RequestsSection;
