import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { returnButton } from "src/consts/SvgIcon";
import { setStep } from "src/store/action";
import { OnboardingType } from "src/types/redux";
import mainLogo from "../../../assets/images/navbar/Logo.svg";
import { useNavigate } from "react-router-dom";
interface StepHeaderProps {
  title: string;
  proTip?: string;
  description?: string;
  noStepNumber?: boolean;
  navigateBack?: boolean;
  showStepIndicator?: boolean;
}
const StepHeader = ({
  title,
  proTip,
  description,
  noStepNumber = true,
  navigateBack = false,
  showStepIndicator = true,
}: StepHeaderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const step = useSelector((state: OnboardingType) => state.onboarding).step;

  return (
    <div className="step-header-container">
      {showStepIndicator && (
        <>
          {step > 1 || navigateBack ? (
            <div className="step-number">
              <div
                onClick={() => {
                  if (navigateBack) {
                    navigate(-1);
                  } else {
                    dispatch(setStep(step - 1));
                  }
                }}
                className="back-button-container"
              >
                {returnButton()}
                <span className="back-button-text">back</span>
              </div>
              <hr className="step-header-line" />
              {noStepNumber && (
                <span className="step-number-text">step {step} of 5</span>
              )}
            </div>
          ) : (
            <div className="step-number">
              <hr className="step-header-line" />
              <span className="step-number-text">step {step} of 5</span>
              <hr className="step-header-line" />
            </div>
          )}
        </>
      )}

      {noStepNumber && (
        <img className="fa-logo-icon-steps" src={mainLogo} alt="main-logo" />
      )}
      <span className="step-title">{title}</span>
      {proTip && (
        <span className="step-protip ">
          Pro Tip: <span className="step-protip-text">{proTip}</span>
        </span>
      )}
      {description && <span className="step-protip-text">{description}</span>}
    </div>
  );
};

export default StepHeader;
