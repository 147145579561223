import React, { useCallback, useEffect, useState } from "react";
import UserChatSectionHeader from "./MessengerPartials/UserChatSectionHeader";
import UserChat from "./UserChat";
import ChatInput from "./MessengerPartials/ChatInput";
import { useSelector } from "react-redux";
import { ChatType } from "src/types/redux";
import { getMessages, getUserById } from "src/services/api";
import { MessageDto, UserDto } from "src/services/api/data-contracts";
import loaderGif from "../../../assets/images/page-img/page-load-loader.gif";
import { useInfiniteQuery } from "@tanstack/react-query";
import CreateMessage from "src/events/CreateMessage";
import NewsFeedShared from "src/events/NewsfeedShared";

const UserChatSection = () => {
  const chatType = useSelector((state: ChatType) => state.messages).chatType;
  const isRequest = chatType?.split("-")[0] === "requests" ? true : false;
  const chats = useSelector((state: ChatType) => state.messages).requestsChats;
  const chatUserId = isRequest ? chatType.split("requests-")[1] : chatType;
  const [userData, setUserData] = useState<UserDto>();
  const [messages, setMessages] = useState<MessageDto[]>([]);

  const [rows, setRows] = useState<number>(1);

  const currentUserId = localStorage.getItem("profileId");
  const [isLoad, setIsLoad] = useState(false);

  const userWhoNeedAcceptRequest = chats?.filter(
    (chat) => chat?.user?.id === chatUserId
  )[0]?.lastMessage?.toUser?.id;

  const scrollToBottom = () => {
    document.querySelector(".user-section-chat").scrollTop += 20000;
  };

  useEffect(() => {
    if (chatUserId) {
      getUserById(chatUserId).then((res) => setUserData(res.data));
    }
    const timer = setTimeout(() => {
      setIsLoad(true);
    }, 500);

    return () => {
      setIsLoad(false);
      clearTimeout(timer);
    };
  }, [chatUserId]);

  const fetchMessages = useCallback(
    async ({ pageParam = 1 }) => {
      return getMessages(pageParam, chatUserId);
    },
    [chatUserId]
  );

  const {
    data: messagesData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["get-messages"],
    getNextPageParam: (last) =>
      last.data.meta.hasNextPage ? last.data.meta.page + 1 : undefined,
    queryFn: fetchMessages,
    staleTime: 30000,
    cacheTime: 30000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    refetch();
  }, [chatUserId]);

  const loadMore = useCallback(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!isLoading && messagesData) {
      const newSavedPosts = messagesData.pages
        .map((page) => page.data.results)
        .flat()
        .reverse();
      setMessages(newSavedPosts);

      const scrollEl = document.querySelector(".user-section-chat");
      if (scrollEl) {
        scrollEl.scrollTop += 100;
      }
    }
  }, [isLoading, messagesData]);

  if (isLoading || !isLoad) {
    return (
      <div className="d-flex justify-content-center w-100" key="loader">
        <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
      </div>
    );
  }

  return (
    <div className="saved-section-container">
      {(chatType === userData?.id ||
        chatType === `requests-${userData?.id}`) && (
        <CreateMessage
          setMessages={setMessages}
          scrollToBottom={scrollToBottom}
          chatUserId={chatUserId}
        />
      )}
      <NewsFeedShared
        setMessages={setMessages}
        scrollToBottom={scrollToBottom}
        chatUserId={chatUserId}
      />
      <UserChatSectionHeader isRequest={isRequest} user={userData} />
      <UserChat
        user={userData}
        messages={messages}
        loadMore={loadMore}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        rows={rows}
      />
      <ChatInput
        isRequest={isRequest}
        userWhoNeedAcceptRequestId={
          userWhoNeedAcceptRequest === currentUserId ? currentUserId : ""
        }
        requestId={chats?.filter((chat) => chat?.user?.id === userData?.id)[0]}
        toUser={userData?.id}
        setRows={setRows}
        rows={rows}
      />
    </div>
  );
};

export default UserChatSection;
