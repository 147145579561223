import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import loaderGif from "../../../assets/images/page-img/page-load-loader.gif";
import ChatTimeDifference from "./MessengerPartials/ChatTimeDifference";
import { SavedChatProps } from "src/types/messenger";
import { formattedDate } from "src/utils/formattedDate";
import SavedChatPost from "./MessengerPartials/SavedChatPost";
import { calculateSavedBorderRadius } from "src/utils/calculateSavedBorderRadius";

const SavedChat = ({
  savedPosts,
  loadMore,
  hasNextPage,
  isLoading,
}: SavedChatProps) => {
  return (
    <div className="saved-section-chat" id="saved-section-chat">
      <InfiniteScroll
        dataLength={savedPosts.length}
        inverse={true}
        next={loadMore}
        hasMore={hasNextPage}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        scrollableTarget="saved-section-chat"
        loader={
          <div
            className="d-flex justify-content-center w-100"
            key="saved-loader"
          >
            <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
          </div>
        }
      >
        {!isLoading && (
          <div className="saved-posts-container ">
            {savedPosts.map((post, index) => {
              const date = formattedDate(savedPosts, index);
              const borderRadius = calculateSavedBorderRadius(
                savedPosts,
                index
              );

              return (
                <div
                  className="post-time-container"
                  key={index}
                  style={{ marginTop: index === 0 ? "10px" : "0px" }}
                >
                  {date && <ChatTimeDifference text={date} key={date} />}
                  <SavedChatPost
                    post={post}
                    borderRadius={borderRadius}
                    key={post?.id}
                  />
                </div>
              );
            })}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default SavedChat;
