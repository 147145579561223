import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setShowLoader } from "../../store/users";
import { resetPassword } from "../../services/api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UsersModule } from "src/types/redux";
import PublicNavBar from "src/components/NavBar/PublicNavBar";
import { done, error, info, logo } from "src/consts/SvgIcon";
import { getInputClass } from "src/utils";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const notify = (message) => toast.success(message);
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    password: "",
    confirmedPassword: "",
    errors: {
      passwordMismatch: false,
    },
    validations: {
      isMinLength: false,
      hasUpperLowerCase: false,
      hasSpecialChar: false,
    },
  });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    validatePassword();
  }, [formData.password, formData.confirmedPassword]);

  const validatePassword = () => {
    const { password, confirmedPassword } = formData;

    const passwordMismatch = password !== confirmedPassword;
    const isMinLength = password.length >= 8;
    const hasUpperLowerCase = /[a-z]/.test(password) && /[A-Z]/.test(password);
    const hasSpecialChar = /[{};:=<>_+\-^#$@!%*?&]/.test(password);

    setFormData((prev) => ({
      ...prev,
      errors: { ...prev.errors, passwordMismatch },
      validations: { isMinLength, hasUpperLowerCase, hasSpecialChar },
    }));
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setShowLoader(true));
    const { password, confirmedPassword, errors, validations } = formData;
    if (
      errors.passwordMismatch ||
      !validations.isMinLength ||
      !validations.hasUpperLowerCase ||
      !validations.hasSpecialChar
    ) {
      return;
    }
    resetPassword(password, confirmedPassword, searchParams?.get("token")).then(
      (res) => {
        if (!res) return;
        toast.dismiss();
        toast.clearWaitingQueue();
        notify(res.data);
        navigate("/");
      }
    );
  };

  const isFormValid =
    !formData.errors.passwordMismatch &&
    formData.validations.isMinLength &&
    formData.validations.hasUpperLowerCase &&
    formData.validations.hasSpecialChar;

  return (
    <div className="d-flex justify-content-center align-items-center flex-column screen-size">
      <PublicNavBar />
      <div className="gradient-login-bg">
        <div className="login-pages-container d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center">
            <div style={{ color: "black" }}>{logo()}</div>
            <div className="form-header-title-container">
              <div className="form-header-title">Create New Password</div>
              <p>Please enter a new password for your account.</p>
            </div>
          </div>
          <Form className="form-body" onSubmit={handleReset}>
            <div>
              <Form.Group className="login-form-input-box">
                <Form.Label>
                  <h3>Password:</h3>
                </Form.Label>
                <Form.Control
                  type="password"
                  className={`mb-0 ${getInputClass(
                    formData,
                    "password",
                    formData.errors.passwordMismatch
                  )} ${formData.errors.passwordMismatch ? "input-error" : ""}`}
                  value={formData.password}
                  onChange={handleInputChange}
                  id="password"
                  style={{ color: "black" }}
                />
              </Form.Group>
              <Form.Group className="login-form-input-box">
                <Form.Label>
                  <h3>Repeat Password:</h3>
                </Form.Label>
                <Form.Control
                  type="password"
                  className={`mb-0 ${getInputClass(
                    formData,
                    "confirmedPassword",
                    formData.errors.passwordMismatch
                  )} ${formData.errors.passwordMismatch ? "input-error" : ""}`}
                  value={formData.confirmedPassword}
                  onChange={handleInputChange}
                  id="confirmedPassword"
                  style={{ color: "black" }}
                />
              </Form.Group>
              <div className="register-info-box">
                {formData.errors.passwordMismatch && (
                  <div>
                    {error()}
                    <span>Passwords don’t match, try again</span>
                  </div>
                )}
                <div>
                  {formData.validations.isMinLength ? done() : info()}
                  <span>Minimum 8 characters</span>
                </div>
                <div>
                  {formData.validations.hasUpperLowerCase ? done() : info()}
                  <span>Uppercase and lowercase letters</span>
                </div>
                <div>
                  {formData.validations.hasSpecialChar ? done() : info()}
                  <span>At least one special symbol</span>
                  <span
                    style={{ color: "#B6B4B4" }}
                  >{`{};:=<>_+-^#$@!%*?&`}</span>
                </div>
              </div>
            </div>
            <div className="register-form-button-box">
              <button
                type="submit"
                disabled={!isFormValid}
                className={
                  !isFormValid
                    ? "login-form-disabled"
                    : "login-form-submit-button"
                }
              >
                Create Password
              </button>
              <div>
                <span>By continuing you accept</span>
                <Link to="/terms-conditions" className="text-blue">
                  Terms and Conditions
                </Link>
              </div>
              {showLoader && (
                <div className="d-flex justify-content-center">
                  <img src={loader} alt="loader" style={{ height: "50px" }} />
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
