export const reminderTypes = {
  SUBSCRIBE_REMINDER: "pricing-page",
  DONATION_DREAM_REMINDER: "dashboard",
  ADMIN_NOTIFICATION: "user-profile",
};

export const wingTypes = {
  POST_WING_DONATION: "wing-donation",
  WING_DONATION_FULFILLED: "wing-donation",
};
export const commentedTypes = { POST_COMMENT: "", REPLY_COMMENT: "" };
export const othersTypes = {
  POST_DREAM: "dream-page",
  DREAM_FULFILLED: "dream-page",
  FULFILL_DONATION: "dream-page",
  SHARE_DREAM: "dashboard/public-page",
  SHARE_DREAM_ANGEL: "dashboard/public-page",
};

export enum shareType {
  SHARE_DREAM = "SHARE_DREAM",
  SHARE_DREAM_ANGEL = "SHARE_DREAM_ANGEL",
}
