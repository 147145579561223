import React, { useState, useEffect, useRef } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import DreamMakersNew from "./DreamMakersNew";
import Carousel from "react-multi-carousel";
import {
  dreamMakersIds,
  fetchDreamMakersInfo,
  extractDreamMakerData,
  mergeDonations,
} from "../../utils";
import { dreamMakersResponsive } from "../../consts/breakpoints";
import { SLIDERS_ENUM } from "../../consts/slidersEnum";
import { DreamMakerProps } from "src/types/profile-props";
import { debounce } from "lodash";
import InfiniteViewAllModal from "../PublicandInternShared/InfiniteViewAllModal";
import { updateMarginLeft } from "src/utils/updateMarginLeft";
import SliderArrows from "../Partials/SliderArrows";

const DreamMakersSlider = ({ donations, isFulfilled }: DreamMakerProps) => {
  const fetchUserDataDetails = async () => {
    const donationUsers = dreamMakersIds(donations);
    const fulfilledData = await fetchDreamMakersInfo(donationUsers);
    const userDataDet = extractDreamMakerData(fulfilledData);
    const fulfilledDatat = mergeDonations(donations, userDataDet);
    setDonationData(fulfilledDatat);
  };
  const isCompleted = useIsMobile(860);
  const isMobile = useIsMobile(768);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [paddingLeft, setPaddingLeft] = useState(0);
  const [donationData, setDonationData] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    fetchUserDataDetails();
    const handleResize = () => {
      const newPadding = updateMarginLeft(884);
      setPaddingLeft(newPadding);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = debounce(() => {}, 100);

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div style={{ marginBottom: "40px" }}>
      <div className="d-flex justify-content-center">
        <div
          className="dream-header"
          style={{
            width: isCompleted ? "96vw" : "860px",
          }}
        >
          <h4 className="dream-slider-title">Dream Makers</h4>
          <div className="view-all-button-container">
            {donationData?.length >= 12 && (
              <div onClick={handleShowModal} className="view-all-button">
                <p className="view-all">view all</p>
              </div>
            )}
            {!isMobile ? (
              <SliderArrows containerClass="dream-dreams-container" />
            ) : (
              <></>
            )}
          </div>
          {donations.length >= 12 ? (
            <InfiniteViewAllModal
              show={showModal}
              id={donations[0].dreamId}
              handleClose={handleCloseModal}
              isFulfilled={isFulfilled}
              type={SLIDERS_ENUM.DREAM_MAKERS}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {isMobile ? (
        <div className="carousel-wrapper">
          <Carousel
            responsive={dreamMakersResponsive}
            className="carousel-width slider-carousel slider-margin-mobile"
            sliderClass="d-flex align-items-center justify-content-between"
            itemClass="dream-card-mobile dream-card-mobile-profile"
            arrows={false}
            swipeable
            infinite={false}
          >
            {donationData.slice(0, 12).map((donations) => (
              <DreamMakersNew
                key={donations.id}
                donations={donations}
                isFulfilled={isFulfilled}
              />
            ))}
          </Carousel>
        </div>
      ) : (
        <div
          ref={containerRef}
          className="dream-dreams-container"
          style={{
            paddingLeft: isCompleted ? "0" : `${paddingLeft}%`,
            width: "99%",
            marginBottom: "50px",
          }}
        >
          {donationData.slice(0, 12).map((donations) => (
            <DreamMakersNew
              key={donations.id}
              donations={donations}
              isFulfilled={isFulfilled}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default DreamMakersSlider;
