import defaultImage from "../../assets/images/defaultUser.png";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { othersTypes } from "../../consts/notifications";

const Interaction = ({ el, setShow }) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    if (el.type !== "SHARE_DREAM_ANGEL" || el.type !== "SHARE_DREAM") {
      navigate(`/${othersTypes[el.type]}/${el?.dreamId}`);
    } else {
      navigate(`/${othersTypes[el.type]}/${el?.sender?.id}`);
    }
    setShow(false);
  };
  return (
    <div className="notification-item-container" onClick={navigateTo}>
      <div className="d-flex gap-2">
        <div>
          <img
            src={el?.sender?.mainImageUrl || defaultImage}
            alt="profile"
            className="avatar"
          />
        </div>
        <div className="d-flex flex-column ">
          <div>
            <span className="fs-5 fw-bold text-dark underline-name cursor-pointer">
              {el?.sender?.firstName} {el?.sender?.lastName}
            </span>{" "}
            <span className="fs-5" style={{ color: "#A8A6A6" }}>
              {el?.title}
            </span>
          </div>
          <span className="text-dark">
            {moment(`${el?.createdAt}`)?.fromNow(true)} ago
          </span>
        </div>
      </div>
      {!el?.seen && <div className="notify-circle-card" />}
    </div>
  );
};
export default Interaction;
