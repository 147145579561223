import moment from "moment";

export const formatTimeDuration = (timestamp, seen = false) => {
  const now = moment();
  const pastDate = moment(timestamp);
  const duration = moment.duration(now.diff(pastDate));

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const weeks = Math.floor(duration.asWeeks());
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  if (years > 0) {
    return seen ? `${years} years` : `${years} y`;
  } else if (months > 0) {
    return seen ? `${months} months` : `${months} m`;
  } else if (weeks > 0) {
    return seen ? `${weeks} weeks` : `${weeks} w`;
  } else if (days > 0) {
    return seen ? `${days} days` : `${days} d`;
  } else if (hours > 0) {
    return seen ? `${hours} hours` : `${hours} h`;
  } else if (minutes > 0) {
    return seen ? `${minutes} minutes` : `${minutes} min`;
  } else {
    return seen ? "Seen now" : "now";
  }
};
