import React, { useState, useEffect } from "react";
import arrowLeft from "../../../assets/images/prev-month-icon.svg";
import arrowRight from "../../../assets/images/next-month-icon.svg";
import MonthSelect from "./MonthSelect";
import { validDate } from "src/utils/validDate";
import useIsMobile from "src/hooks/useIsMobile";

const daysOfTheWeek = ["Mo", "Tu", "We", "Th", "Fr", "Su", "Sa"];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const currentYear = new Date().getFullYear();
const lastYears = Array.from({ length: 120 }, (_, i) => currentYear - i);

interface CustomCalendarProps {
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  selectedMonth: string;
  setSelectedMonth: React.Dispatch<React.SetStateAction<string>>;
  selectedYear: string;
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
  selectedData: string;
  parentSelectWidth: number;
}

const CustomCalendar = ({
  setClose,
  setError,
  selectedDay,
  setSelectedDay,
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
  selectedData,
  parentSelectWidth,
}: CustomCalendarProps) => {
  const date = new Date();
  const [year, setYear] = useState<number>();
  const [month, setMonth] = useState<number>();
  const isMobile = useIsMobile(390);

  const [monthOpen, setMonthOpen] = useState<boolean>(false);
  const [yearOpen, setYearOpen] = useState<boolean>(false);

  const manipulate = () => {
    const dayOne = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();
    const dayEnd = new Date(year, month, lastDate).getDay();
    const monthLastDate = new Date(year, month, 0).getDate();

    let lit = [];
    for (let i = dayOne; i > 0; i--) {
      lit.push(
        <div key={`prev-${i}`} className="inactive">
          {monthLastDate - i + 1}
        </div>
      );
    }

    for (let i = 1; i <= lastDate; i++) {
      const isSelected = i === +selectedDay ? "selected" : "";
      lit.push(
        <div
          key={`current-${i}`}
          className={`active  ${isSelected} `}
          onClick={() => {
            setError(false);
            setSelectedYear(year.toString());
            setSelectedMonth((month + 1).toString());
            setSelectedDay(i.toString());
          }}
        >
          {i}
        </div>
      );
    }

    for (let i = dayEnd; i < 6; i++) {
      lit.push(
        <div key={`next-${i}`} className="inactive">
          {i - dayEnd + 1}
        </div>
      );
    }

    return lit;
  };

  const handleNavigation = (direction) => {
    if (direction === "prev") {
      setSelectedMonth(month.toString());
      if (month === 0) {
        setSelectedYear((year - 1).toString());
        setSelectedMonth("12");
      }
    } else {
      setSelectedMonth((month + 2).toString());
      if (month === 11) {
        setSelectedYear((year + 1).toString());
        setSelectedMonth("1");
      }
    }
  };

  useEffect(() => {
    manipulate();
  }, [year, month]);

  useEffect(() => {
    if (selectedMonth !== "") {
      setMonth(+selectedMonth - 1);
    } else {
      setMonth(date.getMonth());
    }

    if (selectedYear !== "") {
      setYear(+selectedYear);
    } else {
      setYear(date.getFullYear());
    }
  }, [selectedMonth, selectedYear]);

  return (
    <div className="dropdown-bg">
      <div
        className="dropdown-bg-transparent"
        onClick={() => setClose(false)}
      ></div>

      <div
        style={{ width: isMobile ? `${parentSelectWidth}px` : "" }}
        className="dropdown-bg-calendar"
        onClick={() => {
          if (monthOpen) {
            setMonthOpen(false);
          }
          if (yearOpen) {
            setYearOpen(false);
          }
        }}
      >
        <div className="months-years-container">
          <div
            className="month-navigation-btn"
            onClick={() => handleNavigation("prev")}
          >
            <img src={arrowLeft} alt="back" />
          </div>
          <div className="month-year-container">
            <MonthSelect
              minWidth="91px"
              options={months}
              optionSelected={month}
              setOptionSelected={setSelectedMonth}
              isOpen={monthOpen}
              setIsOpen={setMonthOpen}
              isMonths
            />
            <MonthSelect
              minWidth="67px"
              options={lastYears}
              optionSelected={year}
              setOptionSelected={setSelectedYear}
              isOpen={yearOpen}
              setIsOpen={setYearOpen}
              dropdownItemWidth="71px"
            />
          </div>
          <div
            className="month-navigation-btn"
            onClick={() => handleNavigation("next")}
          >
            <img src={arrowRight} alt="back" />
          </div>
        </div>

        <div className="calendar-body">
          <div className="calendar-weekdays">
            {daysOfTheWeek.map((day) => (
              <div key={day} className="week-day">
                {day}
              </div>
            ))}
          </div>

          <div className="calendar-dates">{manipulate()}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;
