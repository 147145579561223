import React from "react";
import arrowLeft from "../../../../assets/images/grayArrowLeft.svg";
import { useDispatch, useSelector } from "react-redux";
import { setChatType } from "src/store/action";
import useIsMobile from "src/hooks/useIsMobile";
import { ChatType } from "src/types/redux";
import { useNavigate } from "react-router-dom";

const ChatListHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile(600);
  const requests = useSelector(
    (state: ChatType) => state.messages
  ).requestsChats;

  const dispatchValue = isMobile ? "requestsList" : "request";
  return (
    <div className="list-header-container">
      <div className="back-btn-container">
        <div
          className="back-arrow"
          onClick={() => {
            dispatch(setChatType(""));
            navigate("/newsfeed");
          }}
        >
          <img src={arrowLeft} alt="arrow" className="back-arrow-icon" />
        </div>
        <span className="back-btn-text">Messages</span>
      </div>
      <span
        className="requests-text"
        onClick={() => {
          dispatch(setChatType(dispatchValue));
        }}
      >
        {`Requests (${requests?.length || 0})`}
      </span>
    </div>
  );
};

export default ChatListHeader;
