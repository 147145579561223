import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDream, setShowLoader, setUser } from "../../store/users";
import { register } from "../../services/api";

//swiper
import SwiperCore, { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

import "../../styles/dreamerzLogin.css";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import { logo, done, info, error } from "../../consts/SvgIcon";
import { toast } from "react-toastify";
import { UsersModule } from "src/types/redux";
import { getInputClass, pushEvent } from "../../utils";
import PublicNavBar from "src/components/NavBar/PublicNavBar";
import { useCheckIsAuth } from "src/hooks/useCheckIsAuth";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Register = () => {
  const userId = localStorage.getItem("profileId");
  const token = useCheckIsAuth(`newsfeed`, userId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmedPassword: "",
    errors: {
      passwordMismatch: false,
    },
    validations: {
      isMinLength: false,
      hasUpperLowerCase: false,
      hasSpecialChar: false,
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleEmailFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(e.target.value)) {
      setEmailError("Wrong email format");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    validatePassword();
  }, [formData.password, formData.confirmedPassword]);

  const validatePassword = () => {
    const { password, confirmedPassword } = formData;

    const passwordMismatch = password !== confirmedPassword;
    const isMinLength = password.length >= 8;
    const hasUpperLowerCase = /[a-z]/.test(password) && /[A-Z]/.test(password);
    const hasSpecialChar = /[{};:=<>_+\-^#$@!%*?&]/.test(password);

    setFormData((prev) => ({
      ...prev,
      errors: { ...prev.errors, passwordMismatch },
      validations: { isMinLength, hasUpperLowerCase, hasSpecialChar },
    }));
  };

  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password, errors, validations } = formData;
    if (
      errors.passwordMismatch ||
      !validations.isMinLength ||
      !validations.hasUpperLowerCase ||
      !validations.hasSpecialChar ||
      !email
    ) {
      return;
    }
    dispatch(setShowLoader(true));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("profileId");
    dispatch(setUser({}));
    dispatch(setDream({}));
    register({ email, password })
      .then((response) => {
        if (!response) {
          return;
        }
        localStorage.setItem("email", email);
        toast.dismiss();
        toast.clearWaitingQueue();
        navigate("/check-email");
        pushEvent("singup_homepage", "login_page");
      })
      .catch((error) => {
        dispatch(setShowLoader(false));
        toast.warning("Something went wrong");
      });
  };

  const isFormValid =
    !formData.errors.passwordMismatch &&
    formData.validations.isMinLength &&
    formData.validations.hasUpperLowerCase &&
    formData.validations.hasSpecialChar &&
    formData.email &&
    !emailError;

  if (token) return <div />;

  return (
    <div className="d-flex justify-content-center align-items-center flex-column screen-size">
      <PublicNavBar />
      <div className="gradient-login-bg">
        <div className="login-pages-container d-flex flex-column align-items-center">
          <div className="form-header mobile-width-hundred">
            <div style={{ color: "black" }}>{logo()}</div>
            <div className="form-header-title-container">
              <div className="form-header-title">Create Account</div>
              <p>Become a dreamer now and start fulfilling your dream!</p>
            </div>
          </div>
          <Form className="form-body" onSubmit={handleRegister}>
            <div>
              <Form.Group className="login-form-input-box">
                <Form.Label>
                  <h3>E-mail address:</h3>
                </Form.Label>
                <Form.Control
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleEmailFocusOut}
                  className={`mb-0 text-dark ${getInputClass(
                    formData,
                    "email",
                    emailError
                  )}  ${emailError ? "input-error" : ""}`}
                  style={{ color: "black" }}
                />
              </Form.Group>
              <div className="register-info-box content-box-error">
                {emailError && (
                  <div>
                    {error()}
                    <span>{emailError}</span>
                  </div>
                )}
              </div>
              <Form.Group className="login-form-input-box">
                <Form.Label>
                  <h3>Password:</h3>
                </Form.Label>
                <Form.Control
                  type="password"
                  className={`mb-0 ${getInputClass(
                    formData,
                    "password",
                    formData.errors.passwordMismatch
                  )} ${formData.errors.passwordMismatch ? "input-error" : ""}`}
                  value={formData.password}
                  onChange={handleInputChange}
                  id="password"
                  style={{ color: "black" }}
                />
              </Form.Group>
              <Form.Group className="login-form-input-box">
                <Form.Label>
                  <h3>Repeat Password:</h3>
                </Form.Label>
                <Form.Control
                  type="password"
                  className={`mb-0 ${getInputClass(
                    formData,
                    "confirmedPassword",
                    formData.errors.passwordMismatch
                  )} ${formData.errors.passwordMismatch ? "input-error" : ""}`}
                  value={formData.confirmedPassword}
                  onChange={handleInputChange}
                  id="confirmedPassword"
                  style={{ color: "black" }}
                />
              </Form.Group>
              <div className="register-info-box">
                {formData.errors.passwordMismatch && (
                  <div>
                    {error()}
                    <span>Passwords don’t match, try again</span>
                  </div>
                )}
                <div>
                  {formData.validations.isMinLength ? done() : info()}
                  <span>Minimum 8 characters</span>
                </div>
                <div>
                  {formData.validations.hasUpperLowerCase ? done() : info()}
                  <span>Uppercase and lowercase letters</span>
                </div>
                <div>
                  {formData.validations.hasSpecialChar ? done() : info()}
                  <span>At least one special symbol</span>
                  <span
                    style={{ color: "#B6B4B4" }}
                  >{`{};:=<>_+-^#$@!%*?&`}</span>
                </div>
              </div>
            </div>
            <div className="register-form-button-box">
              <button
                type="submit"
                className={
                  !isFormValid
                    ? "login-form-disabled"
                    : "login-form-submit-button"
                }
                disabled={!isFormValid}
              >
                Create Account
              </button>
              <div>
                <span>By continuing you accept</span>
                <Link to="/terms-conditions" className="text-blue">
                  Terms and Conditions
                </Link>
              </div>
              {showLoader && (
                <div className="d-flex justify-content-center">
                  <img src={loader} alt="loader" style={{ height: "50px" }} />
                </div>
              )}
            </div>
            <div className="login-box-bottom-text">
              <span>Already have an account?</span>
              <Link to="/login" className="text-blue">
                Log In
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
