import React, { useState, useEffect } from "react";
import { likeDream, likeNewsfeedDream, savedPost } from "../../services/api";
import { ReactComponent as Like } from "../../assets/images/socials/like.svg";
import { ReactComponent as Send } from "../../assets/images/socials/send.svg";
import { ReactComponent as Share } from "../../assets/images/socials/share.svg";
import { ReactComponent as Save } from "../../assets/images/socials/save.svg";
import { toast } from "react-toastify";
import "../../styles/Dreams.css";
import _ from "lodash";
import { SOCIALS_ENUM } from "../../consts/socialsEnum";
import { useDispatch } from "react-redux";
import { savePost, unSavePost } from "src/store/action";
import SentModal from "../Messenger/MessengerComponents/SendModal";
import ShareModal from "./ShareModal";

const SocialInteraction = ({ dream, type }) => {
  const notify = (message) => toast.error(message);
  const dispatch = useDispatch();
  const profileId = localStorage.getItem("profileId");
  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [showSentModal, setShowSentModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(dream?.isSaved || false);
  const [savedCount, setSavedCount] = useState<number>(dream?.savedCount || 0);
  const [sharedCount, setSharedCount] = useState<number>(
    dream?.sharedCount || 0
  );

  useEffect(() => {
    const typeToPropertyPath = {
      [SOCIALS_ENUM.NEWSFEED_DREAM]: "newsFeedDream.likedDreamsByUsers",
      [SOCIALS_ENUM.PROFILE_DREAM]: "likedDreamsByUsers",
      [SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL]: "likedNewsFeedByUsers",
      [SOCIALS_ENUM.NEWSFEED_CHARITY]: "likedNewsFeedByUsers",
    };

    const propertyPath = typeToPropertyPath[type];
    const users = propertyPath ? _.get(dream, propertyPath, []) : [];

    const userHasLiked = users.some((user) => user.id === profileId);
    setLiked(userHasLiked);
    setLikesCount(users.length);
  }, [dream, type, profileId]);

  const handleLikeClick = async () => {
    try {
      const response = await (() => {
        switch (type) {
          case SOCIALS_ENUM.NEWSFEED_DREAM:
            return likeDream(dream.dreamId);
          case SOCIALS_ENUM.PROFILE_DREAM:
            return likeDream(dream.id);
          case SOCIALS_ENUM.NEWSFEED_DREAM_ANGEL:
            return likeNewsfeedDream(dream.id);
          case SOCIALS_ENUM.NEWSFEED_CHARITY:
            return likeNewsfeedDream(dream.id);
          default:
            return null;
        }
      })();

      if (response) {
        const userHasLiked = !liked;
        setLiked(userHasLiked);
        setLikesCount(response.data.likedDreamsByUsers.length);
      } else {
        throw new Error("Like failed");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.");
    }
  };

  const handleSaveClick = async () => {
    if (dream) {
      try {
        await savedPost({ dreamId: dream?.id });
        dispatch(isSaved ? unSavePost(dream?.id) : savePost(dream?.id));
        setIsSaved(!isSaved);
        setSavedCount((prev) => (isSaved ? prev - 1 : prev + 1));
      } catch (error) {
        notify("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <div className="public-dream-social-parts d-flex">
      {showSentModal && (
        <SentModal
          showSentModal={showSentModal}
          setShowSentModal={setShowSentModal}
          dream={dream}
          setSharedCount={setSharedCount}
        />
      )}
      {showShareModal && (
        <ShareModal
          customModals={showShareModal}
          setCustomModal={setShowShareModal}
          sharedLink={`https://dreamerz.net/dream/${dream?.id}`}
          anotherModalOpen={false}
          post={dream}
        />
      )}
      <div className="dream-social-action d-flex justify-content-between">
        <div className="socials-left">
          <Like
            fill={liked ? "#B0E8E8" : "white"}
            stroke={liked ? "#7EC6CE" : "#828282"}
            className={liked ? "" : "like-icon-default-styles"}
            style={{ cursor: "pointer" }}
            onClick={handleLikeClick}
          />
          <Send
            fill="white"
            stroke="#828282"
            className="like-icon-default-styles"
            style={{ cursor: "pointer" }}
            onClick={() => setShowSentModal(true)}
          />
        </div>
        <div className="socials-right">
          <Share
            fill="white"
            stroke="#828282"
            style={{ cursor: "pointer" }}
            className="like-icon-default-styles"
            onClick={() => setShowShareModal(true)}
          />
          <Save
            fill={isSaved ? "#F8ED84" : "white"}
            stroke={isSaved ? "#CAB500" : "#828282"}
            className={isSaved ? "" : "like-icon-default-styles"}
            style={{ cursor: "pointer" }}
            onClick={handleSaveClick}
          />
        </div>
      </div>
      <div className="dream-stats-row d-flex">
        <div className="d-flex dream-profile-first-container">
          <p className="fw-bold">{likesCount}</p>
          <p>Likes</p>
        </div>
        <div className="d-flex dream-profile-second-container">
          <p>{`${sharedCount || 0} Sent`}</p>
        </div>
        <div className="d-flex dream-profile-third-container">
          <p>{`${savedCount || 0} Saved`}</p>
        </div>
      </div>
    </div>
  );
};

export default SocialInteraction;
