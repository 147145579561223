import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../../styles/Dashboard.css";
import { ProgressBar } from "react-bootstrap";
import DreamMakersModal from "../../components/DreamMakersModal";
import SocialInteraction from "../Partials/SocialInteraction";
import { SOCIALS_ENUM } from "../../consts/socialsEnum";
import { UsersModule } from "src/types/redux";
import EditProfileModal from "./About/EditProfileModal";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";

const ProfileDreamDetails = ({ received, dream, type }) => {
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;

  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const progressBarValue = dream ? calculateDreamProgress(dream) : 0;

  return (
    <div className="dream-photo-container mb-3" style={{ height: "inherit" }}>
      <div className="pb-0 pt-0">
        <div className="d-flex justify-content-between">
          <div className="dream-photo-title">Dream Details</div>
          {type === "user" && (
            <div
              onClick={handleShowModal}
              className="edit-profile-button"
              style={{ width: "auto" }}
            >
              <img
                src="/interact/edit-profile.svg"
                alt=""
                style={{ width: "20px" }}
              />
              <span>edit</span>
            </div>
          )}
        </div>
        <EditProfileModal
          show={showModal}
          handleClose={handleCloseModal}
          user={user}
          dream={dream}
        />

        <div className="dream-scope-bar">
          <div className="dream-bar-box d-flex align-items-center justify-content-between">
            <ProgressBar
              className="dream-progress-bar-style"
              variant={"gradient"}
              now={progressBarValue || 0}
              onClick={() => setShow((prev) => !prev)}
            />
            <DreamMakersModal
              donations={publicReceived}
              show={show}
              setShow={setShow}
              dreamId={dream.id}
              profile={true}
            />
            <p>{progressBarValue || "0"}%</p>
          </div>
          <div className="d-flex justify-content-between dream-scope-fulfilled">
            <div className="dream-scope-boxes">
              <p>Scope</p>
              <p className="fw-bold">
                {dream?.amount ? dream?.amount + "$" : "0$"}
              </p>
            </div>
            <div className="dream-scope-boxes">
              <p>Received</p>
              <p className="fw-bold">{received}</p>
            </div>
          </div>
        </div>
        <div className="font dream-description-content">
          {dream?.title || ""}
        </div>
        <SocialInteraction
          dream={dream}
          type={SOCIALS_ENUM.PROFILE_DREAM}
        ></SocialInteraction>
      </div>
    </div>
  );
};
export default ProfileDreamDetails;
