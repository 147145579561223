import React, { useEffect, useRef, useState } from "react";
import calendarIcon from "../../../assets/images/calendar-icon.svg";
import CustomCalendar from "./CustomCalendar";
import { ErrorInfo } from "../../../consts/SvgIcon";
import { validDate } from "src/utils/validDate";
import useContainerWidth from "src/hooks/useContainerWidth";
interface CalendarSelectProps {
  selectedData: string;
  setSelectedData: React.Dispatch<React.SetStateAction<string>>;
  isDisabled?: boolean;
  disabledValue?: string;
}

const CalendarSelect = ({
  selectedData,
  setSelectedData,
  isDisabled,
  disabledValue,
}: CalendarSelectProps) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");

  const [isError, setIsError] = useState<boolean>(false);
  const formateData = (data: string, dataMaxLength?: number) => {
    if (data.length === 1 && data !== "0") {
      return "0" + data;
    } else if (data.length > dataMaxLength) {
      return data.slice(0, 2);
    } else {
      return data;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^\d/]/g, "").slice(0, 10);

    if (value.endsWith("//")) {
      value = value.slice(0, -1);
    }

    if (e.target.value === "/") {
      value = "";
    }

    let arr = value.split("/");

    arr = arr.map((el, index) => {
      if (index === 0 || index === 1) {
        return el.slice(0, 2);
      } else if (index === 2) {
        return el.slice(0, 4);
      } else {
        return "";
      }
    });

    setSelectedDay(arr[0]);

    if (arr.length === 2) {
      setSelectedMonth(arr[1]);
      arr = [formateData(arr[0], 2), ...arr.slice(1)];
    } else if (arr.length === 3) {
      setSelectedYear(arr[2]);

      arr = [formateData(arr[0], 2), formateData(arr[1], 2), ...arr.slice(2)];
    }

    value = arr.join("/");
    setSelectedData(value);

    setIsError(value === "" ? true : !validDate(value));
  };

  useEffect(() => {
    if (selectedDay !== "") {
      setSelectedData(selectedDay);
    }
    if (selectedMonth !== "") {
      setSelectedData(selectedDay + "/" + selectedMonth);
    }
    if (selectedYear !== "") {
      setSelectedData(
        formateData(selectedDay) + "/" + selectedMonth + "/" + selectedYear
      );
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const parentSelectWidth = useContainerWidth(containerRef);
  return (
    <div className="custom-calendar-select-container" ref={containerRef}>
      <span className="input-description">Birth Date*</span>
      {isDisabled ? (
        <div className="disabled-email-input">
          <span className="gray-description">{disabledValue}</span>
          <img src={calendarIcon} alt="message" />
        </div>
      ) : (
        <div className={`custom-select ${focus ? "number-input-focus" : ""}`}>
          <input
            type="text"
            placeholder="DD/MM/YYYY"
            value={selectedData}
            className={`custom-select-input`}
            onChange={(e) => handleChange(e)}
            onFocus={() => setFocus(true)}
          />
          <img src={calendarIcon} alt="arrow-icon" />
        </div>
      )}
      {isError && !focus && (
        <div className="error-info-container">
          <div className="error-info-icon">{ErrorInfo()}</div>
          <span className="error-message">Please enter a valid birth date</span>
        </div>
      )}
      {focus && (
        <CustomCalendar
          selectedData={selectedData}
          setError={setIsError}
          setClose={setFocus}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          parentSelectWidth={parentSelectWidth}
        />
      )}
    </div>
  );
};

export default CalendarSelect;
