import React, { useState } from "react";
import "../../styles/NewDreamsSlider.css";
import defaultImage from "../../assets/images/defaultUser.png";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { SliderCardProps } from "src/types/models";
import TooltipDetails from "../Tooltip/TooltipDetails";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import { fulfillCheck } from "src/utils/fulfillCheck";
import { useDispatch, useSelector } from "react-redux";
import { setBgLoader } from "../../store/users";
import { getComments } from "src/services/api";
import { setActiveReply, updateDreamCommentsCount } from "src/store/action";
import ResponsiveCommentsModal from "../Comments/ResponsiveCommentsModal";
import { UsersModule } from "src/types/redux";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { commentType } from "src/consts/commentsTypeEnum";

const NewDreamCard = ({
  dream,
  isFulfilled,
  allFulfilled,
  isModal = false,
}: SliderCardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const progress = calculateDreamProgress(dream);
  const mainDreamImage = dream.images.find((image) => image.isMain);
  const tooltipKey = generateTooltipKey(dream?.user?.id);
  const isDreamFulfilled = fulfillCheck(allFulfilled, dream, dream?.user?.id);
  const [currentUserComment, setComment] = useState<string>("");

  const currentUser = useSelector(
    (state: UsersModule) => state.usersModule
  ).user;
  const mainImage = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;

  const [modalProps, setModalProps] = useState(false);
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(Boolean);

  const openCommentModal = (dreamId) => {
    setComment("");
    dispatch(setActiveReply(""));
    dispatch(setBgLoader(true));
    setModalProps(true);
    getComments(dreamId, 1, 10).then((res) => {
      setComments(res?.data?.results);
      setHasMore(res?.data?.meta?.hasNextPage);
      setModalProps(true);
      dispatch(updateDreamCommentsCount(dreamId, res?.data?.meta?.commentCount));
    });
  };

  return (
    <div className={isModal ? "new-dreams-card-container" : ""}>
      <div
        className="new-dreams-card shadow-card d-flex flex-column justify-content-between"
        style={{
          backgroundImage: mainDreamImage
            ? `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.4) 80%), url(${mainDreamImage.url})`
            : "",
        }}
      >
        <div
          style={{ height: "90%" }}
          className="d-flex flex-column justify-content-between cursor-pointer"
          onClick={() => openCommentModal(dream?.id)}
        >
          <div className="new-dream-name-img-box d-flex">
            <Link to={`/dashboard/public-page/${dream?.user?.id}`}>
              <img
                src={dream?.user?.mainImageUrl || defaultImage}
                alt=""
                className="new-dream-user-img"
              />
            </Link>
            <div className="new-dream-name-box">
              <Link to={`/dashboard/public-page/${dream?.user?.id}`}>
                <div
                  className="new-dream-user-name text-underline"
                  data-tooltip-id={tooltipKey}
                >
                  {`${dream?.user?.firstName || "No Name"} ${
                    dream.user?.lastName || ""
                  }`}
                </div>
              </Link>
              <TooltipDetails
                userId={dream?.user?.id}
                isFulfilled={isFulfilled}
                allFulfilled={allFulfilled}
                tooltipKey={tooltipKey}
              />
              <div className="new-dream-created-time">
                {moment(dream?.createdAt)?.fromNow(true)} ago
              </div>
            </div>
          </div>
          <div className="new-dream-details-box">
            <div className="new-dream-title">{dream.title || "No Title"}</div>
          </div>
        </div>
        <div
          className="new-dream-button-gradient"
          onClick={() =>
            isDreamFulfilled
              ? navigate(`/dashboard/public-page/${dream?.user?.id}`)
              : isFulfilled(dream?.id)
          }
        >
          <div className="new-dream-button-text">
            <span>
              {isDreamFulfilled
                ? "View Profile"
                : `Fulfill ${
                    dream?.user?.gender === "FEMALE" ? "Her" : "His"
                  } dream`}
            </span>
          </div>
        </div>
      </div>
      <ResponsiveCommentsModal
        dream={dream}
        modalProps={modalProps}
        setModalProps={setModalProps}
        setComments={setComments}
        comments={comments}
        setHasMore={setHasMore}
        hasMore={hasMore}
        mainImage={mainImage}
        isFulfilled={isFulfilled}
        currentUser={currentUser}
        allFulfilled={allFulfilled}
        progress={progress}
        user={dream?.user}
        type={commentType.dream}
        currentUserComment={currentUserComment}
        setComment={setComment}
      />
    </div>
  );
};
export default NewDreamCard;
