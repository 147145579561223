import React, { useState, useEffect } from "react";
import PopularDreams from "./PopularDream";
import TodaysBirthday from "./TodaysBirthday";
import TopDonors from "./TopDonors";
import NewDreams from "./NewDreams";
import { calculateSlidersWidth } from "src/utils/calculateSlidersWidth";

const Sliders = ({ index, isFulfilled }) => {
  const [sliderWidth, setSliderWidth] = useState(0);

  const updateSliderWidth = () => {
    const width = calculateSlidersWidth();
    if (width !== sliderWidth) {
      setSliderWidth(width);
    }
  };

  useEffect(() => {
    updateSliderWidth();

    window.addEventListener("resize", updateSliderWidth);

    return () => {
      window.removeEventListener("resize", updateSliderWidth);
    };
  }, []);

  return (
    <>
      {index === 2 && (
        <PopularDreams isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
      {index === 5 && (
        <NewDreams isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
      {index === 8 && (
        <TodaysBirthday isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
      {index === 11 && (
        <TopDonors isFulfilled={isFulfilled} sliderWidth={sliderWidth} />
      )}
    </>
  );
};

export default Sliders;
