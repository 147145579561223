import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./users";
import commentsReducer from "./comments";
import thunk from "redux-thunk";
import messageReducer from "./messages";
import onboardingReducer from "./onboarding";

const store = configureStore({
  reducer: {
    usersModule: userReducer,
    comments: commentsReducer,
    messages: messageReducer,
    onboarding: onboardingReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
