import moment from "moment";
import { UserSavedPostDto } from "src/services/api/data-contracts";

export const calculateSavedBorderRadius = (
  savedPosts: UserSavedPostDto[],
  index: number
) => {
  const postData = savedPosts[index]?.createdAt;
  const nextData = savedPosts[index + 1]?.createdAt;
  const prevData = savedPosts[index - 1]?.createdAt;

  const isPrevTimeDifferent = prevData
    ? moment(postData).diff(moment(prevData), "hours")
    : 0;
  const isNextTimeDifferent = nextData
    ? moment(postData).diff(moment(nextData), "hours")
    : 0;

  let borderRadius = "8px 0px 0px 8px";

  if (isPrevTimeDifferent !== 0 || index === 0) {
    borderRadius = "8px 8px 0px 8px";
  }

  if (isNextTimeDifferent !== 0 || index === savedPosts.length - 1) {
    borderRadius = "8px 0px 8px 8px";
  }

  if (isPrevTimeDifferent !== 0 && isNextTimeDifferent !== 0) {
    borderRadius = "8px 8px 8px 8px";
  }

  if (isPrevTimeDifferent !== 0 && index === savedPosts.length - 1) {
    borderRadius = "8px 8px 8px 8px";
  }

  return borderRadius;
};
