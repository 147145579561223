import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Dashboard.css";
import "../../styles/CustomNavBarStyles.css";
import { ReactComponent as LogoSvg } from "../../assets/images/navbar/Logo.svg";
import NotificationDropdown from "./NotificationDropdown";
import NavigationOffcanvas from "./NavigationOffCanvas";
import { balanceStar, navbarMessengerIcon } from "../../consts/SvgIcon";
import SearchBar from "./SearchBar";
import MobileSearchBar from "./MobileSearchBar";
import RandomModal from "./RandomModal";
import PricingModal from "../../pages/Pricing/PricingModal";
import LeaveFeedback from "./LeaveFeedback";
import { UsersModule } from "../../types/redux";
import MessengerNotification from "src/events/MessengerNotification";
import { setChatType } from "src/store/action";
import { getUserChats } from "src/services/api";

const NavBarComponent = () => {
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [allUsers, setAllUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [customModals, setCustomModal] = useState("");
  const [randomDonationId, setRandomDonationId] = useState(null);
  const [feedback, setFeedback] = useState(false);
  const currentUserId = localStorage.getItem("profileId");
  const [messengerNotification, setMessengerNotification] =
    useState<boolean>(false);

  const [messengerChats, setMessengerChats] = useState([]);
  const [requestsChats, setRequestsChats] = useState([]);

  useEffect(() => {
    const allMessages = [...messengerChats, ...requestsChats];

    const unreadMessage = allMessages.find(
      (chat) =>
        chat.lastMessage.to === currentUserId &&
        chat.lastMessage.seenAt === null
    );

    if (unreadMessage) {
      setMessengerNotification(true);
    }
  }, [messengerChats, requestsChats]);

  const getModal = () => {
    if (customModals === "randomModal") {
      return (
        <>
          {randomDonationId && (
            <RandomModal
              customModals={customModals}
              setCustomModal={setCustomModal}
              randomDonationId={randomDonationId}
            />
          )}
        </>
      );
    }
    if (customModals === "priceModal") {
      return (
        <PricingModal
          customModals={customModals}
          setCustomModal={setCustomModal}
        />
      );
    }
  };

  useEffect(() => {
    if (location.pathname !== "/messenger") {
      dispatch(setChatType(""));
    }
    getUserChats(false).then((res) => setRequestsChats(res.data));
    getUserChats(true).then((res) => setMessengerChats(res.data));
  }, [location]);

  const handleLogoClick = () => {
    location.pathname === "/newsfeed" ? window.location.reload() : void 0;
  };

  return (
    <div className="iq-top-navbar ps-1 pe-1">
      <div>{getModal()}</div>
      <LeaveFeedback setFeedback={setFeedback} feedback={feedback} />
      <MessengerNotification
        setMessengerNotification={setMessengerNotification}
      />
      <div className="iq-navbar-custom m-0">
        <Navbar
          expand="md"
          variant="light"
          className="p-0 d-flex justify-content-between align-items-center flex-nowrap"
          style={{ height: "59px" }}
        >
          <div className="iq-navbar-logo ms-2" style={{ width: "300px" }}>
            <Link
              onClick={handleLogoClick}
              to="/newsfeed"
              className="d-flex justify-content-start"
            >
              <LogoSvg className="fa-logo-icon" />
            </Link>
          </div>
          <SearchBar
            user={user}
            allUsers={allUsers}
            setAllUsers={setAllUsers}
            inputValue={inputValue}
            setInputValue={setInputValue}
            className="desktop-search"
            style={{ width: "300px" }}
          />
          <Nav
            as="ul"
            className="navbar-list d-flex justify-content-end align-items-center gap-2"
            style={{ width: "300px" }}
          >
            <MobileSearchBar
              user={user}
              allUsers={allUsers}
              setAllUsers={setAllUsers}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            <div
              className="notify-container"
              onClick={() => navigate("/messenger")}
            >
              {navbarMessengerIcon()}
              {messengerNotification && location.pathname !== "/messenger" && (
                <div className="notify-circle" />
              )}
            </div>
            <NotificationDropdown />
            <div className="user-balance-nav">
              <h5>Balance</h5>
              <div className="user-balance-footer">
                <span>{user?.balance ? user?.balance : 0}</span>{" "}
                <span>{balanceStar()}</span>
              </div>
            </div>
            <NavigationOffcanvas
              user={user}
              setCustomModal={setCustomModal}
              setRandomDonationId={setRandomDonationId}
              setFeedback={setFeedback}
            />
          </Nav>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBarComponent;
