import React from "react";

const SeenTime = ({ text }) => {
  return (
    <div className="seen-time-container">
      <span className="time-difference-text">{text}</span>
    </div>
  );
};

export default SeenTime;
