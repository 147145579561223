import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../assets/images/search-modal-close.svg";
import { checkEmail } from "src/consts/SvgIcon";
import { useNavigate } from "react-router-dom";

const PaymentSuccessfulModal = ({ setCustomModal, customModals }) => {
  const navigate = useNavigate();
  return (
    <Modal
      size="lg"
      backdrop={true}
      centered
      show={customModals}
      onHide={() => {
        navigate("/newsfeed");
        setCustomModal(false);
      }}
      animation={true}
      contentClassName="border-0"
      style={{ borderRadius: "8px" }}
      className="payment-modal-bootstrap"
    >
      <div className="payment-modal-container">
        <div
          onClick={() => {
            navigate("/newsfeed");
            setCustomModal(false);
          }}
          className="payment-close"
        >
          <img src={close} alt="x" className="payment-close-icon" />
        </div>
        <div className="payment-info-container">
          <div>{checkEmail()}</div>
          <span className="payment-title">Payment Successful</span>
          <span className="payment-description">
            Thanks for joining and welcome! You can now start your DreamCycle —
            being generous helps you achieving your dream faster.
          </span>
        </div>
        <div
          className="dashboard-card-buttons custom-gradient"
          style={{ height: "40px", marginBottom: "48px", width: "151px" }}
          onClick={() => {
            navigate("/newsfeed");
            setCustomModal(false);
          }}
        >
          <div className="dashboard-card-buttons-text">
            <span className="payment-text-btn">Start My Journey</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PaymentSuccessfulModal;
