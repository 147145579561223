import React from "react";
import triangleDown from "../../../assets/images/triangle-down.svg";
import triangleUp from "../../../assets/images/triangle-up.svg";

interface MonthSelectProps {
  minWidth?: string;
  dropdownItemWidth?: string;
  options: string[] | number[];
  optionSelected: number;
  setOptionSelected: React.Dispatch<React.SetStateAction<string>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  isMonths?: boolean;
}

const MonthSelect = ({
  minWidth,
  options,
  optionSelected,
  setOptionSelected,
  isMonths = false,
  dropdownItemWidth = "111px",
  isOpen,
  setIsOpen,
}: MonthSelectProps) => {

  return (
    <div
      style={{ minWidth: minWidth, border: isOpen ? "1px solid #EBBFFF" : "" }}
      className="month-select-container"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="selected-option-container">
        <span className="selected-option">
          {isMonths ? options[optionSelected] : optionSelected}
        </span>
        <div
          className="selected-triangle"
          style={{ alignItems: isOpen ? "start" : "end" }}
        >
          <img src={isOpen ? triangleUp : triangleDown} alt="triangle" />
        </div>
      </div>
      {isOpen && (
        <div className="month-select-dropdown">
          {options.map((option, index) => (
            <div
              style={{ width: dropdownItemWidth }}
              key={option}
              className={`month-select-dropdown-item ${
                optionSelected === index || option === optionSelected
                  ? "selected-option"
                  : "not-selected-option"
              }`}
              onClick={() => setOptionSelected(isMonths ? index + 1 : option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MonthSelect;
