import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserChats } from "src/services/api";
import { socket } from "src/socket";
import {
  setRequestsChats,
  setMessengerChats,
  setChatType,
} from "src/store/action";

interface RequestAcceptProps {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestAccept = ({ setState }: RequestAcceptProps) => {
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("profileId");

  useEffect(() => {
    socket.on("request-accepted", (data) => {
      
      const cardId =
        currentUserId === data?.fromUserId ? data?.toUserId : data?.fromUserId;

      getUserChats(false).then((res) => dispatch(setRequestsChats(res.data)));
      getUserChats(true).then((res) => {
        dispatch(setMessengerChats(res.data));
        dispatch(setChatType(cardId));
        setState(true);
      });
    });

    return () => {
      socket.off("request-accepted");
    };
  }, []);
  return <></>;
};

export default RequestAccept;
