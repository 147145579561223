import React from "react";
import { useSelector } from "react-redux";
import { ChatType } from "src/types/redux";
import SavedSection from "./MessengerComponents/SavedSection";
import RequestsSection from "./MessengerComponents/RequestsSection";
import UserChatSection from "./MessengerComponents/UserChatSection";
import NoSelectChat from "./MessengerComponents/MessengerPartials/NoSelectChat";

const ChatSection = () => {
  const chatType = useSelector((state: ChatType) => state.messages).chatType;
  const chatTypes = ["saved", "request", "requestsList", ""];

  return (
    <div className="chat-container">
      {!chatTypes.includes(chatType) && <UserChatSection />}
      {chatType === "saved" && <SavedSection />}
      {chatType === "request" && <RequestsSection />}
      {chatType === "" && <NoSelectChat />}
    </div>
  );
};

export default ChatSection;
