import { CreateDream, Page } from "src/types/models";
import {
  ActivityDto,
  CharityCommentDto,
  CharityDto,
  CheckNotificationDto,
  CreateCharityCommentDto,
  CreateCheckoutSessionDto,
  CreateDreamCommentDto,
  CreatePayoutDto,
  CreateShareNotificationDto,
  DonationDto,
  DreamAdminCheckDto,
  DreamCommentDto,
  DreamDto,
  DreamImageDto,
  FeedbackDto,
  LoginDto,
  NewsFeedDto,
  NotificationDto,
  PageDto,
  PayoutDto,
  RegisterDto,
  ReplacePasswordDto,
  TokensDto,
  TopDonorDto,
  UpdateProfileDto,
  UserCoverImageDto,
  UserDto,
  UserImageDto,
  UserSavedPostDto,
  UserShortInfoDto,
  WingDonationDto,
} from "./api/data-contracts";
import api from "./index";
import axios, { AxiosResponse } from "axios";

interface SavedPostInterface {
  dreamId?: string;
  postId?: string;
  charityId?: string;
}
export const register = async ({ email, password }: RegisterDto) => {
  return await api.post<TokensDto>("/api/v1/auth/register", {
    email,
    password,
  });
};

export const login = async ({ email, password }: LoginDto) => {
  return await api.post<TokensDto>("/api/v1/auth/login", { email, password });
};

export const getRefreshToken = async () => {
  return await axios.get<TokensDto>("/api/v1/auth/refresh", {
    headers: {
      authorization: "Bearer " + localStorage.getItem("refreshToken"),
    },
  });
};

export const getUser = async () => {
  return await api.get<UserDto>("/api/v1/auth/profile");
};

export const getAdmins = async () => {
  return await api.get<DreamAdminCheckDto>("/api/v1/admin/dream-check");
};

export const getNotification = async (page: number) => {
  return await api.get<PageDto & { results: NotificationDto[] }>(
    `/api/v1/notifications?order=DESC&page=${page}&take=15`
  );
};

export const getPosts = async (page: number) => {
  return await api.get<PageDto & { results: NewsFeedDto[] }>(
    `/api/v1/news-feeds?order=DESC&take=5&page=${page}`
  );
};

export const getActivity = async (
  userId: string,
  page: number,
  type?: string
) => {
  return await api.get<PageDto & { results: ActivityDto[] }>(
    `/api/v1/me/${userId}/activity?order=DESC&take=5&page=${page}${
      type ? `&type=${type}` : ""
    }`
  );
};

export const getAngelNewsfeedPostById = async (id: string) => {
  return await api.get(`/api/v1/news-feeds/${id}/dream-angel`);
};

export const patchNotification = async () => {
  return await api.patch("/api/v1/notifications/check-all");
};

export const patchNotificationById = async (
  notificationId: CheckNotificationDto
) => {
  return await api.patch<CheckNotificationDto>("/api/v1/notifications/check", {
    notificationId,
  });
};

export const shareNotification = async ({
  type,
  dreamId,
  charityId,
  replyUserId,
  media,
  dreamAngelId,
}: CreateShareNotificationDto) => {
  return await api.post<CreateShareNotificationDto>(
    "/api/v1/notifications/share",
    {
      type,
      dreamId,
      charityId,
      replyUserId,
      media,
      dreamAngelId,
    }
  );
};

export const updateUser = async ({
  firstName,
  lastName,
  description,
  phoneNumber,
  gender,
  birthday,
  city,
  country,
  onboardingStatus
}: UpdateProfileDto) => {
  return await api.patch<UserDto>("/api/v1/auth/profile", {
    firstName,
    lastName,
    description,
    phoneNumber,
    gender,
    birthday,
    city,
    country,
    onboardingStatus
  });
};

export const uploadImage = async (file: string | Blob) => {
  const formData = new FormData();
  formData.append("image", file);
  return await api.post("/api/v1/user/image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadImages = async (files: (string | Blob)[]) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("images", file);
  });
  return await api.post("/api/v1/user/images", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const setProfileImageAsMain = async (id: string) => {
  return await api.patch<UserImageDto>(`/api/v1/user/image/${id}/main`);
};

export const deleteImage = async (id: string) => {
  return await api.delete(`/api/v1/user/image/${id}`);
};

export const getDreamById = async (id: string) => {
  return await api.get<DreamDto>(`/api/v1/dreams/${id}`);
};

export const createDream = async ({ title, amount }: CreateDream) => {
  return api.post<DreamDto>("/api/v1/dreams", { title, amount });
};

export const uploadDreamImage = async (id: string, file: string | Blob) => {
  const formData = new FormData();
  formData.append("image", file);
  return await api.post(`/api/v1/dream/${id}/image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadDreamImages = async (
  id: string,
  files: (string | Blob)[]
) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("images", file);
  });
  return await api.post(`/api/v1/dream/${id}/images`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const setDreamImageAsMain = async (dreamId: string, imageId: string) => {
  return await api.patch<DreamImageDto>(
    `/api/v1/dream/${dreamId}/image/${imageId}/main`
  );
};

export const deleteDreamImage = async (dreamId: string, imageId: string) => {
  return await api.delete(`/api/v1/dream/${dreamId}/image/${imageId}`);
};

export const updateDream = async (
  id: string,
  title: string,
  amount: number
): Promise<AxiosResponse<DreamDto>> => {
  return await api.patch(`/api/v1/dreams/${id}`, { title, amount });
};

export const postPayments = async (amount: number) => {
  return await api.post("/api/v1/payments", {
    amount: amount,
  });
};

export const fulfill = async (dreamId: string) => {
  return await api.post<DonationDto>("/api/v1/donations", {
    dreamId: dreamId,
  });
};

export const randomDonation = async () => {
  return await api.post<DonationDto>("/api/v1/donations/random");
};

export const getAllCharities = async () => {
  return await api.get<PageDto & { results: CharityDto[] }>(
    "/api/v1/charities"
  );
};

export const wingDonation = async (charityId: string) => {
  return await api.post<WingDonationDto>("/api/v1/wing-donations", {
    charityId,
  });
};

export const getCharitiesById = async (id: string) => {
  return await api.get<CharityDto>(`/api/v1/charities/${id}`);
};
export const getUserById = async (id: string) => {
  return await api.get<UserDto>(`/api/v1/users/${id}`);
};

export const getUserShortInfoById = async (id: string) => {
  return await api.get<UserShortInfoDto>(`/api/v1/users/${id}/short`);
};

export const getAllTodaysBirthday = async () => {
  return await api.get<UserDto[]>(`/api/v1/users?birthDay=true`);
};

export const getTopDonorsThisMonth = async () => {
  return await api.get<TopDonorDto[]>(
    `/api/v1/donations/top-donors-this-month`
  );
};

export const getNewDreams = async () => {
  return await api.get<PageDto & { results: DreamDto[] }>(
    `/api/v1/new-dreams?order=ASC&page=1&take=40`
  );
};

export const getComments = async (
  dreamId: string,
  page: number,
  take: number
) => {
  return await api.get<Page & { results: DreamCommentDto[] }>(
    `/api/v1/dreams/${dreamId}/comments?order=DESC&page=${page}&take=${take}`
  );
};
export const getPublicComments = async (dreamId: string) => {
  return await api.get<Page & { results: DreamCommentDto[] }>(
    `/api/v1/p/dreams/${dreamId}/comments?order=DESC&page=${1}&take=${3}`
  );
};
export const getDreamCommentByCommentId = async (
  dreamId: string,
  commentId: string
) => {
  return await api.get(`/api/v1/dream/${dreamId}/comments/${commentId}`);
};
export const getCharityCommentByCommentId = async (
  charityId: string,
  commentId: string
) => {
  return await api.get(`/api/v1/charities/${charityId}/comments/${commentId}`);
};
export const getDreamAngelCommentByCommentId = async (
  newsFeedId: string,
  commentId: string
) => {
  return await api.get(`/api/v1/newsfeed/${newsFeedId}/comments/${commentId}`);
};
export const getCharityComments = async (
  charityId: string,
  page: number,
  take: number
) => {
  return await api.get<Page & { results: CharityCommentDto[] }>(
    `/api/v1/charitites/${charityId}/comments?order=DESC&page=${page}&take=${take}`
  );
};

export const getPublicCharityComments = async (charityId: string) => {
  return await api.get<Page & { results: CharityCommentDto[] }>(
    `/api/v1/p/charities/${charityId}/comments?order=DESC&page=${1}&take=${3}`
  );
};

export const createComments = async ({
  message,
  dreamId,
  replyUserId = null,
  parentId = null,
}: CreateDreamCommentDto) => {
  return await api.post<DreamCommentDto>("/api/v1/dreams/comments", {
    message: message,
    dreamId: dreamId,
    replyUserId: replyUserId,
    parentId: parentId,
  });
};

export const createCharitiesComments = async ({
  message,
  charityId,
  replyUserId = null,
  parentId = null,
}: CreateCharityCommentDto) => {
  return await api.post<CharityCommentDto>("/api/v1/charities/comments", {
    message: message,
    charityId: charityId,
    replyUserId: replyUserId,
    parentId: parentId,
  });
};

export const createDreamAngelComments = async ({
  message,
  dreamAngelId,
  newsFeedId,
  replyUserId = null,
  parentId = null,
}) => {
  return await api.post(`/api/v1/newsfeed-dream-angel/comments`, {
    message: message,
    dreamAngelId: dreamAngelId,
    newsFeedId: newsFeedId,
    replyUserId: replyUserId,
    parentId: parentId,
  });
};
export const getWingDonations = async (
  id: string,
  page: number,
  take?: number
) => {
  return await api.get<PageDto & { results: WingDonationDto[] }>(
    `/api/v1/p/wing-donations/charities/${id}?page=${page}&take=${take || 12}`
  );
};

export const uploadCoverImage = async (file: string | Blob) => {
  const formData = new FormData();
  formData.append("image", file);
  return await api.post(`/api/v1/user/cover/image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const deleteCoverImage = async (imageId: string | boolean) => {
  return await api.delete(`/api/v1/user/cover/image/${imageId}`);
};

export const getPricesId = async () => {
  return await api.get("/api/v1/payments/config");
};

export const checkoutSession = async (data: CreateCheckoutSessionDto) => {
  return await api.post("/api/v1/payments/checkout-session", data);
};

export const getSubscription = async () => {
  return await api.get("/api/v1/payments/subscriptions");
};
export const deleteSubscription = async (id: string) => {
  return await api.delete(`/api/v1/payments/subscriptions/${id}`);
};

export const lastTenDonations = async () => {
  return await api.get<DonationDto[]>("/api/v1/donations/last");
};
export const getProfileDream = async () => {
  return await api.get<PageDto & { results: DreamDto[] }>(
    `/api/v1/auth/profile/dreams`
  );
};

export const getProfileFulfilled = async (page: number) => {
  return await api.get<PageDto & { results: DonationDto[] }>(
    `/api/v1/auth/profile/donations?order=DESC&page=${page}&take=${12}`
  );
};
export const getProfileWingDonations = async (page: number) => {
  return await api.get<PageDto & { results: WingDonationDto[] }>(
    `/api/v1/auth/profile/wing-donations?order=DESC&page=${page}&take=${12}`
  );
};
export const getDreamDonations = async (dreamId: string, page: number) => {
  return await api.get<PageDto & { results: DonationDto[] }>(
    `/api/v1/auth/profile/dreams/${dreamId}/donations?order=DESC&page=${page}&take=${12}`
  );
};

export const likeCharity = async (charityId: string) => {
  return await api.patch<CharityDto>(`/api/v1/charities/${charityId}/like`);
};

export const likeDream = async (dreamId: string) => {
  return await api.patch<DreamDto>(`/api/v1/dreams/${dreamId}/like`);
};

export const likeNewsfeedDream = async (postId: string) => {
  return await api.patch<DreamDto>(`/api/v1/news-feeds/${postId}/like`);
};

export const getPublicUserDream = async (id: string) => {
  return await api.get<PageDto & { results: DreamDto[] }>(
    `/api/v1/dreams/users/${id}`
  );
};

export const getDreamAngelComments = async (
  dreamAngelId: string,
  page: number,
  take: number
) => {
  return await api.get(
    `/api/v1/newsfeed-dream-angel/${dreamAngelId}/comments?order=DESC&page=${page}&take=${take}`
  );
};

export const getPublicDreamAngelComments = async (dreamAngelId: string) => {
  return await api.get(
    `/api/v1/p/newsfeed-dream-angel/${dreamAngelId}/comments?order=DESC&page=${1}&take=${3}`
  );
};

export const getPublicWingDonations = async (id: string, page: number) => {
  return await api.get<PageDto & { results: WingDonationDto[] }>(
    `/api/v1/wing-donations/users/${id}?order=DESC&page=${page}&take=${12}`
  );
};
export const getPublicFulfilled = async (id: string, page: number) => {
  return await api.get<PageDto & { results: DonationDto[] }>(
    `/api/v1/donations/users/${id}?order=DESC&page=${page}&take=${12}`
  );
};

export const getDonationByUserId = async (id: string, page: number) => {
  return await api.get<PageDto & { results: DonationDto[] }>(
    `/api/v1/donations/users/${id}?order=DESC&take=${12}&page=${page}`
  );
};

export const getPublicDreamDonations = async (id: string, page: number) => {
  return await api.get<PageDto & { results: DonationDto[] }>(
    `/api/v1/donations/dreams/${id}?order=DESC&page=${page}&take=${12}`
  );
};

export const imagePosition = async (id: string, position: number) => {
  return await api.patch<UserCoverImageDto>(`/api/v1/user/cover/image/${id}`, {
    coverImagePosition: position,
  });
};
export const getSearchedUsers = async (query: string) => {
  return await api.get<UserDto[]>(`/api/v1/users?name=${query}`);
};
export const getAllDreams = async (
  order: string,
  page: number,
  take: number,
  isPopular: boolean = false
) => {
  return await api.get<PageDto & { results: DreamDto[] }>(
    `/api/v1/dreams?order=${order}&page=${page}&take=${take}&isPopular=${isPopular}`
  );
};
export const getAllPublicDreams = async () => {
  return await api.get<PageDto & { results: DreamDto[] }>(`/api/v1/p/dreams`);
};
export const getAllProfileDonations = async () => {
  return await api.get(`/api/v1/auth/profile/donations/all`);
};
export const getAllProfileWingDonations = async () => {
  return await api.get(`/api/v1/auth/profile/wing-donations/all`);
};
export const getEmail = async (email: string) => {
  return await api.post("/api/v1/auth/profile/forgot-password", {
    email: email,
  });
};
export const getPublicCharityById = async (charityId: string) => {
  return await api.get<CharityDto>(`/api/v1/p/charities/${charityId}`);
};
export const postFeedback = async (message: string) => {
  return await api.post<FeedbackDto>("/api/v1/feedbacks", { message: message });
};
export const payOut = async (object: CreatePayoutDto) => {
  return await api.post<PayoutDto>("/api/v1/payouts", object);
};
export const getPayOutsByDreamId = async (dreamId) => {
  return await api.get<PayoutDto>(`/api/v1/payouts/dreams/${dreamId}`);
};
export const resetPassword = async (
  password: string,
  confirmPassword: string,
  token: string
) => {
  return await api.post<ReplacePasswordDto>(
    "/api/v1/auth/profile/replace-password",
    { password: password, confirmPassword: confirmPassword },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};
export const confirmEmail = async (token: string) => {
  return await api.post("/api/v1/email/confirm", token, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};
export const verifyEmail = async (email: string) => {
  return await api.post(`/api/v1/email/verify`, { email });
};
export const getPublicDreamAngel = async (userId: string) => {
  return await api.get(`/api/v1/p/dreams/angel/${userId}`);
};

export const getSavedPost = async (page: number) => {
  return await api.get<PageDto & { results: UserSavedPostDto[] }>(
    `/api/v1/saved-post?order=DESC&page=${page}`
  );
};
export const savedPost = async (postId: SavedPostInterface) => {
  return await api.post(`/api/v1/saved-post`, {
    ...postId,
  });
};
export const getMessages = async (page: number, userId: string) => {
  return await api.get<PageDto & { results: any[] }>(
    `/api/v1/messages?order=DESC&page=${page}&take=25&to=${userId}`
  );
};

export const getUserChats = async (requests: boolean) => {
  return await api.get(`/api/v1/message-users?accepted=${requests}`);
};

export const acceptMessageRequest = async (requestId: string) => {
  return await api.post(`/api/v1/messages/accept-request/${requestId}`);
};
