import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GradientButton from "../Buttons/GradientButton";
import StepHeader from "./partials/StepHeader";
import CustomInput from "../Partials/CustomInput";
import { info } from "src/consts/SvgIcon";
import {
  createDream,
  getPublicUserDream,
  getUser,
  updateUser,
  uploadDreamImages,
} from "src/services/api";
import { setUser } from "src/store/users";
import { setStep } from "src/store/action";
import UploadDreamPhotos from "./partials/UploadDreamPhotos";
import { UsersModule } from "src/types/redux";
import { getStepNumber } from "src/utils/getStepNumber";
import { useQuery } from "@tanstack/react-query";
import { ErrorInfo } from "../../consts/SvgIcon";

export default function Step4() {
  const dispatch = useDispatch();
  const [userDescription, setUserDescription] = useState<string>("");
  const [uploadedPhotos, setUploadedPhotos] = useState<File[]>([]);

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [infoMessages, setInfoMessages] = useState([
    "You cannot change it later",
    "At least 150$",
  ]);

  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const currentStepNumber = getStepNumber((user as any).onboardingStatus) + 1;
  const isFormCompleted = currentStepNumber !== 4 ? true : false;
  const [isActive, setIsActive] = useState(false);

  const isDescriptionError =
    (userDescription.length < 10 && userDescription.length !== 0) ||
    userDescription.length > 255;

  useEffect(() => {
    const numericAmount = parseFloat(amount);
    if (amount === "") {
      setInfoMessages(["You cannot change it later", "At least 150$"]);
      setAmountError("");
    } else if (numericAmount < 150) {
      setInfoMessages(["You cannot change it later"]);
      setAmountError("At least 150$");
    } else {
      setInfoMessages(["You cannot change it later"]);
      setAmountError("");
    }
  }, [amount]);

  useEffect(() => {
    const isValidAmount = parseFloat(amount) >= 150;
    setIsActive(
      isFormCompleted
        ? true
        : uploadedPhotos.length > 0 &&
            userDescription.length > 10 &&
            userDescription.length < 255 &&
            isValidAmount
    );
  }, [uploadedPhotos, userDescription, amount]);

  const handleClick = () => {
    if (isFormCompleted) return dispatch(setStep(5));

    if (isActive) {
      createDream({
        title: userDescription,
        amount: parseFloat(amount),
      }).then((e) => {
        const id = e?.data?.id;
        uploadDreamImages(id, uploadedPhotos).then((response) => {
          if (!response) return;
          updateUser({
            onboardingStatus: "CREATE_DREAM",
          }).then((res) => {
            getUser().then((response) => {
              dispatch(setUser(response.data));
            });
            dispatch(setStep(5));
          });
        });
      });
    }
  };

  const skipDreamCreation = () => {
    if (isFormCompleted) return dispatch(setStep(5));

    updateUser({
      onboardingStatus: "CREATE_DREAM",
    }).then((res) => {
      getUser().then((response) => {
        dispatch(setUser(response.data));
      });
      dispatch(setStep(5));
    });
  };

  const { data: userDream, isLoading } = useQuery(["userDream", user.id], () =>
    getPublicUserDream(user.id).then((res) => res.data.results)
  );

  return (
    !isLoading && (
      <div className="step-4-container">
        <StepHeader
          title="Create Your Dream"
          proTip="Upload 3+ high-quality pictures that capture your dream to help others empathize and connect with your vision. Pairing them with an inspiring description will boost your chances of fulfilling your dream."
        />
        <UploadDreamPhotos
          uploadedPhotos={uploadedPhotos}
          setUploadedPhotos={setUploadedPhotos}
          isDisabled={isFormCompleted}
          addedImages={
            userDream.length
              ? userDream[0].images.map((image) => image.url)
              : []
          }
        />
        <div className="profile-description-container">
          <span className="textarea-title">Dream Description*</span>
          {isFormCompleted ? (
            <div className="disabled-textarea-input">
              <span className="gray-description">
                {userDream.length ? userDream[0]?.title : ""}
              </span>
            </div>
          ) : (
            <textarea
              id="description"
              name="description"
              value={userDescription}
              onChange={(e) => setUserDescription(e.target.value)}
              className="profile-textarea"
            />
          )}
          {isDescriptionError && (
            <div className="error-info-container">
              <div className="error-info-icon">{ErrorInfo()}</div>
              <span className="error-message">
                {userDescription.length > 255
                  ? "The description must be a maximum of 255 characters"
                  : "Please enter a description with at least 10 characters"}
              </span>
            </div>
          )}
        </div>
        <div className="profile-description-container">
          <CustomInput
            placeholder="1500"
            description="Dream Amount*"
            state={amount}
            setState={setAmount}
            errorMessage={amountError}
            isError={amountError !== ""}
            infoMessages={infoMessages}
            isInfo={true}
            isDisabled={isFormCompleted}
            disabledValue={`${userDream.length ? userDream[0].amount : 0}$`}
            isDollarIcon
          />
        </div>
        <div className="create-dream-bottom-container">
          <GradientButton
            style={{ width: "100%", maxWidth: "360px" }}
            text="Create Dream"
            textClassName={` ${
              !isActive ? "disabled-text" : "search-modal-btn-text"
            }`}
            className={` ${!isActive ? "disabled-btn" : "gradient-button"}`}
            onClick={handleClick}
          />
          <div
            className="d-flex align-items-center w-100"
            style={{ height: "30px" }}
          >
            <hr className="step-header-line" />
            <p className="pr-2">or</p>
            <hr className="step-header-line" />
          </div>
          <div
            style={{ maxWidth: "360px" }}
            className="custom-gradient sliders-button button-font-details"
            onClick={skipDreamCreation}
          >
            Continue as a Dream Angel
          </div>
          <div className="d-flex create-dream-continue-angel-box">
            <div>{info()}</div>
            <p style={{ fontSize: "12px" }}>
              A Dream Angel is a person who has not set a dream [yet]. They only
              donate to others, but accumulate dream points for any future
              dream, as well as gratitude and admiration from thousands of
              people.
            </p>
          </div>
        </div>
      </div>
    )
  );
}
