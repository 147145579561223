import React from "react";
import defaultImage from "../../../../assets/images/defaultUser.png";
import { MessagesAvatarProps } from "src/types/messenger";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const MessageAvatar = ({
  myMessage,
  message,
  isBatch,
}: MessagesAvatarProps) => {
  const hasDream = useRoleCheck(message?.fromUser);

  return (
    !myMessage &&
    !isBatch && (
      <div
        className={`${
          !hasDream ? "gradient-border gradient-border-message" : ""
        }`}
      >
        <img
          src={message?.fromUser?.mainImageUrl || defaultImage}
          alt="saved-icon"
          className={`${
            hasDream ? "chat-header-image" : "chat-header-image-dream-angel"
          }`}
        />
      </div>
    )
  );
};

export default MessageAvatar;
