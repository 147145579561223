export const scrollCarousel = (
  direction: string,
  containerScrollClass: string
) => {
  const containerScroll = document.querySelector(`.${containerScrollClass}`);

  if (!containerScroll) return;

  const scrollAmount = 250;

  if (direction === "right") {
    containerScroll.scrollLeft += scrollAmount;
  } else if (direction === "left") {
    containerScroll.scrollLeft -= scrollAmount;
  }
};
