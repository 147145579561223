import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import GoldenStar from "../../../assets/images/golden-star.svg";
import GradientButton from "src/components/Buttons/GradientButton";
import { ErrorInfo } from "src/consts/SvgIcon";
import { cancelUrl, successUrl } from "src/consts/payments";
import { checkoutSession } from "src/services/api";

export default function DreamArchitectModal({ show, handleClose, plan }) {
  const [amount, setAmount] = useState("");
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(55);
  const calculatedAmount = amount
    ? Math.ceil(parseFloat((parseInt(amount) * 1.1).toFixed(2)))
    : "0";

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || (/^\d*$/.test(value) && parseInt(value) <= 909090)) {
      setAmount(value);
    }
  };
  const isAmountLessThan800 = amount && parseInt(amount) < 800;

  const handleSubscription = (plan) => {
    const data = {
      priceId: plan.id,
      successUrl,
      cancelUrl,
      amount: Number(amount),
    };
    checkoutSession(data).then((res) => {
      window.location = res.data?.checkoutSessionUrl;
    });
  };

  useEffect(() => {
    const newWidth = Math.max(55, amount.length * 10 + 20);
    setInputWidth(newWidth);
  }, [amount]);

  return (
    <Modal
      centered
      backdrop={true}
      show={show}
      onHide={handleClose}
      animation={true}
      dialogClassName="dream-architect-modal"
    >
      <div className="dream-architect-modal-header">
        <p>Custom Plan</p>
        <img
          onClick={handleClose}
          src="/interact/close.svg"
          alt="close button"
        />
      </div>
      <div className="dream-architect-modal-body">
        <div className="dream-architect-modal-top">
          <div className="dream-architect-modal-stars">
            <div className="d-flex">
              {Array.from({ length: 5 }).map((_, i) => (
                <img key={i} src={GoldenStar} alt="star" />
              ))}
            </div>
            <p className="payment-cards-title">Dream Architect</p>
            <p className="dream-architect-description">
              Increase your impact even more. Get everything from previous
              plans, plus exclusive invitations to participate in special
              Dreamerz initiatives and forums, adds and promotions.
            </p>
          </div>
          <hr className="text-gray" />
          <div className="dream-architect-modal-amount-insert">
            <div
              className={`amount-insert-box ${
                isAmountLessThan800 ? "amount-insert-box-error" : ""
              }`}
            >
              <p>Fulfill</p>
              <div>
                <input
                  ref={inputRef}
                  type="text"
                  value={amount}
                  style={{ width: `${inputWidth}px` }}
                  onChange={handleInputChange}
                  placeholder="ˍ ˍ ˍ ˍ"
                />
                <span>Dreams / Month</span>
              </div>
            </div>
            {isAmountLessThan800 && (
              <div className="error-info-container">
                <div className="error-info-icon">{ErrorInfo()}</div>
                <span className="error-message">at least 800 dreams</span>
              </div>
            )}
          </div>
        </div>
        <div className="dream-architect-modal-bottom">
          <GradientButton
            text="Become a Dream Architect"
            textClassName={` ${
              isAmountLessThan800 ? "disabled-text" : "search-modal-btn-text"
            }`}
            className={` ${
              isAmountLessThan800 ? "disabled-btn" : "gradient-button"
            }`}
            onClick={() => {
              handleSubscription(plan);
            }}
          />
          <p>
            Total: {+calculatedAmount >= 880 ? `${calculatedAmount}$` : "ˍ ˍ ˍ"} / month{" "}
            <span>(cancel or edit anytime)</span>
          </p>
        </div>
      </div>
    </Modal>
  );
}
