import React from "react";
import SavedIcon from "../../../../assets/images/savedIcon.svg";
import CloseIcon from "../../../../assets/images/saved-header-close.svg";
import arrowLeft from "../../../../assets/images/grayArrowLeft.svg";
import { setChatType } from "src/store/action";
import { useDispatch } from "react-redux";
import useIsMobile from "src/hooks/useIsMobile";

const SavedSectionHeader = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(600);

  return (
    <div className="saved-section-header">
      <div className="header-info-container">
        <div className="saved-header-gradient">
          <img src={SavedIcon} alt="saved-icon" className="saved-header-icon" />
        </div>
        <span className="saved-text">Saved Posts</span>
      </div>
      {isMobile ? (
        <div className="back-arrow">
          <img
            src={arrowLeft}
            alt="arrow"
            className="back-arrow-icon"
            onClick={() => {
              dispatch(setChatType(""));
            }}
          />
        </div>
      ) : (
        <div className="back-arrow">
          <img
            src={CloseIcon}
            alt="saved-icon"
            className="saved-close-icon"
            onClick={() => {
              dispatch(setChatType(""));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SavedSectionHeader;
