import React, { useState } from "react";
import StepHeader from "./partials/StepHeader";
import UploadProfilePhotos from "./partials/UploadProfilePhotos";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "src/store/action";
import GradientButton from "../Buttons/GradientButton";
import { getUser, updateUser, uploadImages } from "src/services/api";
import { UsersModule } from "src/types/redux";
import { getStepNumber } from "src/utils/getStepNumber";
import { setUser } from "src/store/users";
import { ErrorInfo } from "../../consts/SvgIcon";

const Step3 = () => {
  const dispatch = useDispatch();
  const [userDescription, setUserDescription] = useState<string>("");
  const [uploadedPhotos, setUploadedPhotos] = useState<File[]>([]);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const currentStepNumber = getStepNumber((user as any).onboardingStatus) + 1;
  const isFormCompleted = currentStepNumber !== 3 ? true : false;

  const isDescriptionError =
    (userDescription.length < 10 &&
    userDescription.length !== 0 )|| 
    userDescription.length > 255;

  const isActive = isFormCompleted
    ? true
    : uploadedPhotos.length > 0 &&
      userDescription.length > 10 &&
      userDescription.length < 255
    ? true
    : false;

  const handleClick = () => {
    if (isFormCompleted) return dispatch(setStep(4));

    if (isActive) {
      uploadImages(uploadedPhotos).then(() => {
        updateUser({
          description: userDescription,
          onboardingStatus: "USER_PROFILE",
        }).then(() => {
          getUser().then((response) => {
            dispatch(setUser(response.data));
          });
          dispatch(setStep(4));
        });
      });
    }
  };

  return (
    <div className="step-1-container">
      <StepHeader
        title="Add some pictures and describe yourself in few words"
        proTip="Including 3+ high-quality photos with you and adding a description about yourself will fasten your journey towards achieving your dream"
      />
      <UploadProfilePhotos
        uploadedPhotos={uploadedPhotos}
        setUploadedPhotos={setUploadedPhotos}
        isDisabled={isFormCompleted}
        addedImages={user.images.map((image) => image.url)}
      />
      <div className="profile-description-container">
        <span className="textarea-title">Description*</span>
        {isFormCompleted ? (
          <div className="disabled-textarea-input">
            <span className="gray-description">{user.description}</span>
          </div>
        ) : (
          <textarea
            id="description"
            name="description"
            value={userDescription}
            onChange={(e) => setUserDescription(e.target.value)}
            className={`profile-textarea ${
              isDescriptionError ? "profile-textarea-error" : ""
            }`}
          />
        )}
        {isDescriptionError && (
          <div className="error-info-container">
            <div className="error-info-icon">{ErrorInfo()}</div>
            <span className="error-message">
              {userDescription.length > 255
                ? "The description must be a maximum of 255 characters"
                : "Please enter a description with at least 10 characters"}
            </span>
          </div>
        )}
      </div>
      <GradientButton
        style={{ width: "100%", marginTop: "44px", maxWidth: "360px" }}
        text="Continue"
        textClassName={` ${
          !isActive ? "disabled-text" : "search-modal-btn-text"
        }`}
        className={` ${!isActive ? "disabled-btn" : "gradient-button"}`}
        onClick={handleClick}
      />
    </div>
  );
};

export default Step3;
