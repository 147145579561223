import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fulfill,
  getAllProfileDonations,
  getProfileWingDonations,
  getPublicDreamDonations,
  getPublicFulfilled,
  getPublicUserDream,
  getPublicWingDonations,
  getUser,
  getUserById,
} from "../../services/api";
import "../../styles/Dashboard.css";
import { Col, Container, Row, Tab } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ProfileNavBar from "../../components/Profile/ProfileNavBar";
import useIsMobile from "../../hooks/useIsMobile";
import {
  setAllProfileFulfilled,
  setBgLoader,
  setProfileWingDonations,
  setPublicDream,
  setPublicFulfilled,
  setPublicReceived,
  setPublicUser,
  setPublicWingDonations,
  setUser,
} from "../../store/users";
import defaultImage from "../../assets/images/defaultUser.png";
import { toast } from "react-toastify";
import SimpleImageModal from "../../components/Partials/SimpleImageModal";
import BigImageModal from "../../components/Partials/BigImageModal";
import PricingModal from "../Pricing/PricingModal";
import DreamPhotoNew from "../../components/Profile/DreamPhotoNew";
import useSharedTab from "../../hooks/useSharedTab";
import ProfileDreamDetails from "../../components/Profile/ProfileDreamDetails";
import DreamMakersSlider from "../../components/Profile/DreamMakersSlider";
import CommentsNew from "src/components/Comments/CommentsNew";
import MissingData from "src/components/Profile/MissingData";
import { MISSING_DATA_ENUM } from "src/consts/missingDataEnum";
import UserAbout from "../../components/Profile/About/UserAbout";
import { ChatType, UsersModule } from "src/types/redux";
import ProfileFulfilled from "src/components/Profile/ProfileFulfilled";
import ProfileReceived from "src/components/Profile/ProfileReceived";
import { fulfillCheck } from "src/utils/fulfillCheck";
import GradientButton from "src/components/Buttons/GradientButton";
import ActivityPage from "src/components/Profile/Activity/ActivityPage";
import { useRoleCheck } from "src/hooks/useRoleCheck";
import { setChatType } from "src/store/action";
import { socket } from "src/socket";

const UserProfile = () => {
  const notify = (message) => toast.success(message);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const currentUserId = localStorage.getItem("profileId");
  
  const isMobile = useIsMobile(768);

  const user = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicUser;
  const mainUser = useSelector((state: UsersModule) => state.usersModule).user;
  const dream = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicDream;
  const publicFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicFulfilled;
  const publicWingDonations = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicWingDonations;
  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const [coverBigImage, setCoveBigImage] = useState(false);
  const [profileBigImages, setPropfileBigImages] = useState(false);
  const [coverImagePosition, setCoverImagePosition] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [dreamId, setDreamId] = useState("");
  const coverDragImage = useRef(null);
  const childRef = useState(null);
  const [customModals, setCustomModal] = useState("");
  const [currentIndex, setIndex] = useState(null);
  const [key, setKey] = useState(null);
  const allProfileFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;
  const { tab, setTabCallback } = useSharedTab();
  
  const hasDream = useRoleCheck(user);
  const isDreamFulfilled = fulfillCheck(
    allProfileFulfilled,
    dream,
    user?.id
  );

  const messengerChats = useSelector(
    (state: ChatType) => state.messages
  ).messengerChats.map((chat) => chat.user.id);
  const requestsChats = useSelector(
    (state: ChatType) => state.messages
  ).requestsChats.map((chat) => chat.user.id);

  const handleUserBigImages = (index) => {
    setPropfileBigImages(true);
    setIndex(index);
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentUserId && currentPath === `/dashboard/public-page/${currentUserId}`) {
      navigate("/user-profile");
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(setPublicUser({}));
    dispatch(setPublicDream({}));
    dispatch(setBgLoader(true));
    getUserById(params.id).then((response) => {
      dispatch(setPublicUser(response?.data));
      setKey(key + 1);
    });
    getPublicUserDream(params.id).then((res) => {
      dispatch(setPublicDream(res.data?.results?.[0]));
      if (res?.data?.results?.[0]?.id) {
        getPublicDreamDonations(res.data?.results?.[0]?.id, 1).then(
          (response) => {
            dispatch(setPublicReceived(response?.data));
          }
        );
      }
      getPublicFulfilled(params.id, 1).then((res) => {
        dispatch(setPublicFulfilled(res?.data));
      });
    });
    getPublicWingDonations(params.id, 1).then((res) => {
      dispatch(setPublicWingDonations(res.data));
    });
    getAllProfileDonations().then((res) => {
      dispatch(setAllProfileFulfilled(res?.data));
    });
    getProfileWingDonations(1).then((res) => {
      dispatch(setProfileWingDonations(res.data));
    });
  }, [params?.id]);

  useEffect(
    () => () => {
      dispatch(setPublicUser({}));
      dispatch(setPublicDream({}));
      dispatch(setPublicReceived({}));
      dispatch(setPublicFulfilled({}));
    },
    []
  );

  const isFulfilled = (id) => {
    if (!mainUser.balance) {
      setDreamId(id);
      setCustomModal("priceModal");
    } else {
      fulfill(id).then((res) => {
        if (!res) return;
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        };
        getUser().then(({ data }) => {
          dispatch(setUser(data));
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
        getPublicUserDream(params.id).then((res) => {
          dispatch(setPublicDream(res.data?.results?.[0]));
          if (res?.data?.results?.[0]?.id) {
            getPublicDreamDonations(res.data?.results?.[0]?.id, 1).then(
              (response) => {
                dispatch(setPublicReceived(response?.data));
              }
            );
          }
        });
      });
    }
  };

  const bigImage = (img) => {
    setImageUrl(img);
    setCoveBigImage(true);
  };

  useEffect(() => {
    setCoverImagePosition(user?.coverImage?.position);
  }, []);

  const addChat = () => {
    socket.emit("create-message", {
      from: currentUserId,
      to: user.id,
      content: "",
    });
    dispatch(setChatType(user.id));
  };

  const chatType = messengerChats.includes(user.id)
    ? user?.id
    : `requests-${user?.id}`;

  const isChat =
    currentUserId === user.id
      ? false
      : messengerChats.includes(user.id) || requestsChats.includes(user.id);

  return (
    <>
      <div
        className="d-flex justify-content-center p-0 center-container"
        style={{ backgroundColor: "rgba(241, 241, 241, 1)" }}
      >
        <Row className="d-flex justify-content-center w-100 m-0">
          <Col
            sm={12}
            className="card-mobile"
            style={{ backgroundColor: "white" }}
          >
            {profileBigImages && (
              <BigImageModal
                index={currentIndex}
                childRef={childRef}
                openBigImages={setPropfileBigImages}
                dream={user}
              />
            )}
            {coverBigImage && (
              <SimpleImageModal
                childRef={childRef}
                drambigImages={setCoveBigImage}
                imageUrl={imageUrl}
              />
            )}
            <div className="profile-header mb-0 bg-img-top">
              <div
                className="position-relative user-page-bg-img"
                style={{
                  overflow: "hidden",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                key={key + 1}
              >
                <img
                  ref={coverDragImage}
                  style={{
                    position: "absolute",
                    top: Number(
                      `${user?.coverImage?.url ? -coverImagePosition : 0}`
                    ),
                    width: "100%",
                    background: "transparent",
                    objectFit: "cover",
                    cursor: `${user?.coverImage?.url ? "pointer" : "default"}`,
                  }}
                  src={user?.coverImage?.url || "/coverImg.png"}
                  alt="profile-bg"
                  className="rounded"
                  onClick={() =>
                    user?.coverImage?.url
                      ? bigImage(user?.coverImage?.url || "/coverImg.png")
                      : null
                  }
                />
              </div>
              <div className={`profile-img d-flex justify-content-center public-main-image  ${hasDream ? "" : "public-main-image-margin"}`}>
                <div
                  className={
                    hasDream
                      ? "bg-white border-radius-img"
                      : " bg-white profile-angel-img-border"
                  }
                  style={{
                    cursor: `${user?.mainImageUrl ? "pointer" : "default"}`,
                  }}
                >
                  <div className="profile-img-border">
                    <img
                      src={user?.mainImageUrl || defaultImage}
                      alt="profile-img"
                      className="avatar-130 img-fluid border-0"
                      onClick={() =>
                        user?.mainImageUrl
                          ? handleUserBigImages(
                              user?.images?.findIndex((el) => el.isMain)
                            )
                          : null
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="profile-name-fulfill-box">
              {dream?.id ? (
                <div
                  className="d-flex justify-content-around"
                  style={{
                    color: "#4F4F4F",
                    cursor: "default",
                    margin: "0 auto",
                  }}
                >
                  <div className="profile-user-center-info">
                    <div className="angel-fulfilled-box">
                      <img
                        src="/interact/fulfilled-spark.svg"
                        alt=""
                        style={{
                          width: "17px",
                          display: isMobile ? "none" : "inline-block",
                        }}
                      />
                      <span>Received </span>
                      <span>{publicReceived?.meta?.itemCount || "0"}</span>
                    </div>
                    <img
                      src="/interact/fulfilled-spark.svg"
                      alt=""
                      style={{
                        width: "17px",
                        display: !isMobile ? "none" : "inline-block",
                      }}
                    />
                    <div className="angel-fulfilled-box">
                      <img
                        src="/interact/fulfilled-spark.svg"
                        alt=""
                        style={{
                          width: "17px",
                          display: isMobile ? "none" : "inline-block",
                        }}
                      />
                      <span>Fulfilled </span>
                      <span>{publicFulfilled?.meta?.itemCount || "0"}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div  />
              )}
              <div
                className="d-flex justify-content-center text-center text-center m-2 mb-0"
                style={{ paddingTop: "5px" }}
              >
                <h3 className="fw-bold profile-user-name">
                  {user?.firstName || "No name"} {user?.lastName}
                </h3>
              </div>
             </div>
              {!dream?.id ? (
                <div className="profile-angel-badge-container">
                  <div
                    className="d-flex justify-content-center text-center"
                    style={{ gap: "10px" }}
                  >
                    <div className="profile-angel-badge">
                      <p>Dream Angel</p>
                    </div>
                    <div className="angel-fulfilled-box">
                      <img
                        src="/interact/fulfilled-spark.svg"
                        alt=""
                        style={{ width: "17px" }}
                      />
                      <span>Fulfilled </span>
                      <span>{publicFulfilled?.meta?.itemCount || "0"}</span>
                    </div>
                  </div>
                  {params.id !== currentUserId && (
                    <div
                      className="d-flex justify-content-center message-button-profile"
                      onClick={() => {
                        navigate("/messenger");
                        if (isChat) {
                          dispatch(setChatType(chatType));
                        } else {
                          addChat();
                        }
                      }}
                    >
                      <p>Message</p>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center text-center public-profile-button-container"
                  style={{ gap: "10px" }}
                >
                  {!isDreamFulfilled && (
                    <div className="public-profile-gradient-button-box">
                      <GradientButton
                        minWidth="auto"
                        text={`Fulfill ${user?.gender === "FEMALE" ? "Her" : "His"} Dream`}
                        onClick={() => isFulfilled(dream?.id)}
                      />
                    </div>
                  )}
                  {params.id !== currentUserId && (
                    <div
                      className="d-flex justify-content-center message-button-profile"
                      onClick={() => {
                        navigate("/messenger");
                        if (isChat) {
                          dispatch(setChatType(chatType));
                        } else {
                          addChat();
                        }
                      }}
                    >
                      <p>Message</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={"third"}
            activeKey={tab}
            key={key}
          >
            <div className="d-flex justify-content-center nav-back-color">
              <div className="p-0 mt-3 shadow-none profile-navbar">
                <ProfileNavBar
                  setTabCallback={setTabCallback}
                  userData={user}
                  profileType={"public"}
                ></ProfileNavBar>
              </div>
            </div>
            <div className="container-for-profile-content">
              <Col sm={12}>
                <Tab.Content>
                  <PricingModal
                    customModals={customModals}
                    setCustomModal={setCustomModal}
                    dreamId={dreamId}
                  />
                  <Tab.Pane eventKey="first">
                    {!dream?.id || dream.progress === 100 ? (
                      <div className="d-flex justify-content-center">
                        <MissingData
                          type={MISSING_DATA_ENUM.USER_DREAM}
                        ></MissingData>
                      </div>
                    ) : (
                      <>
                        <Container
                          style={{
                            maxWidth: "860px",
                            alignItems: "center",
                            flexDirection: "column",
                            display: "flex",
                          }}
                        >
                          <DreamPhotoNew dream={dream} type="profile" />
                          <ProfileDreamDetails
                            received={publicReceived?.meta?.itemCount}
                            dream={dream}
                            type="profile"
                          />
                          <div className="dream-comments-box mb-3">
                            <CommentsNew
                              user={mainUser}
                              dreamId={dream?.id}
                              mainImg={mainImg}
                              dream={dream}
                              isFulfilled={isFulfilled}
                            />
                          </div>
                        </Container>
                        {!!publicReceived?.meta?.itemCount ? (
                          <DreamMakersSlider
                            donations={publicReceived.results}
                            isFulfilled={isFulfilled}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="activity"
                    >
                      <div className="d-flex justify-content-center">
                        <ActivityPage user={user} isFulfilled={isFulfilled} />
                      </div>
                    </Tab.Container>
                  </Tab.Pane>

                  <Tab.Pane eventKey="third">
                    <Container
                      style={{
                        maxWidth: "860px",
                        alignItems: "center",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <UserAbout user={user} dream={dream} type="profile" />
                    </Container>
                  </Tab.Pane>

                  <Tab.Pane eventKey="fourth">
                    <ProfileFulfilled
                      wings={publicWingDonations?.results}
                      received={publicFulfilled?.results}
                      hasMoreWing={publicWingDonations?.meta?.hasNextPage}
                      hasMoreFulfilled={publicFulfilled?.meta?.hasNextPage}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                      <ProfileReceived
                        received={publicReceived}
                        dream={dream}
                      />
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </div>
          </Tab.Container>
        </Row>
      </div>
    </>
  );
};

export default UserProfile;
