import React, { useState } from "react";
import GradientButton from "src/components/Buttons/GradientButton";
import GoldenStar from "../../../assets/images/golden-star.svg";
import DreamArchitectModal from "./DreamArchitectModal";
import {
  checkoutSession,
  deleteSubscription,
  getSubscription,
  getUser,
  updateUser,
} from "src/services/api";
import { cancelUrl, successUrl } from "src/consts/payments";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "src/store/users";
import { UsersModule } from "src/types/redux";
import { pushEvent } from "src/utils";

export interface PaymentCardsInterface {
  plan: {
    category: string;
    price: string;
    descriptionPoints: string[];
    custom?: boolean;
  };
  index: number;
  selectedPaymentPlan?: boolean;
  mostChosen?: boolean;
  paymentPlan?: any;
  setSelectedPaymentPlan?: React.Dispatch<React.SetStateAction<any>>;
}

export default function PaymentCards({
  plan,
  index,
  selectedPaymentPlan,
  mostChosen,
  paymentPlan,
  setSelectedPaymentPlan,
}: PaymentCardsInterface) {
  const dispatch = useDispatch();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubscription = (plan) => {
    updateUser({
      onboardingStatus: "COMPLETED",
    }).then(() => {
      const data = {
        priceId: plan.id,
        successUrl,
        cancelUrl,
      };
      checkoutSession(data).then((res) => {
        window.location = res.data?.checkoutSessionUrl;
      });
    });
  };

  const cancelSubscription = async () => {
    await deleteSubscription(user.monthlySubscriptionId);

    const res = await getSubscription();

    const activeSubscription = res.data.data.find(
      (subscription) => subscription.status === "active"
    );
    setSelectedPaymentPlan(activeSubscription);

    pushEvent("canceled_subscription", "pricing_page");
    const response = await getUser();
    dispatch(setUser(response?.data));
    return response;
  };

  const refreshSubscription = async (card) => {
    cancelSubscription().then(() => {
      if (card.custom) {
        handleShowModal();
      } else {
        handleSubscription(card);
      }
    });
  };

  return (
    <>
      <div
        className={`payment-cards ${
          (mostChosen || selectedPaymentPlan) && "most-chose-payment"
        }`}
      >
        {mostChosen && <div className="most-chosen-label">most chosen</div>}
        {selectedPaymentPlan && (
          <div className="most-chosen-label">current plan</div>
        )}
        <div className="payment-cards-content">
          <div className="payment-cards-top-container">
            <div className="d-flex">
              {Array.from({ length: index + 1 }).map((_, i) => (
                <img key={i} src={GoldenStar} alt="star" />
              ))}
            </div>
            <div className="payment-cards-title"> {plan.category} </div>
            <div className="payment-cards-amount">
              {plan.price}
              {!plan.custom && "$"} / month
            </div>
          </div>
          <div className="description-points-list">
            {!plan.custom ? (
              <ul className="description-points-list-container">
                {plan.descriptionPoints.map((point) => (
                  <li className="description-points-element"> {point} </li>
                ))}
              </ul>
            ) : (
              <>
                {plan.descriptionPoints.map((point) => (
                  <p className="description-points-element"> {point} </p>
                ))}
              </>
            )}
          </div>
        </div>
        {!plan.custom ? (
          <div className="payment-card-buttons-container">
            {selectedPaymentPlan ? (
              <div className="cancel-subscription-container">
                <h6 className="payment-card-expire-time">
                  {new Date(
                    paymentPlan?.latest_invoice?.lines?.data[0]?.period?.start *
                      1000
                  )
                    ?.toISOString()
                    ?.split("T")[0]
                    ?.replaceAll("-", "/")
                    ?.split("/")
                    ?.reverse()
                    ?.join("/")}{" "}
                  -
                  {" " +
                    new Date(
                      paymentPlan?.latest_invoice?.lines?.data[0]?.period?.end *
                        1000
                    )
                      ?.toISOString()
                      ?.split("T")[0]
                      ?.replaceAll("-", "/")
                      ?.split("/")
                      ?.reverse()
                      ?.join("/")}
                </h6>
                <div
                  className={"dashboard-card-buttons custom-gradient"}
                  style={{ width: "100%", height: "40px" }}
                  onClick={() => cancelSubscription()}
                >
                  <div className={"dashboard-card-buttons-text"}>
                    <span>Cancel subscription</span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <GradientButton
              minWidth="137px"
              text={selectedPaymentPlan ? "Refresh" : "Subscribe"}
              onClick={() => {
                if (selectedPaymentPlan) {
                  refreshSubscription(plan);
                } else {
                  handleSubscription(plan);
                }
              }}
            />
          </div>
        ) : (
          <>
            {selectedPaymentPlan ? (
              <div className="payment-card-buttons-container">
                {selectedPaymentPlan ? (
                  <div className="cancel-subscription-container">
                    <h6 className="payment-card-expire-time">
                      {new Date(
                        paymentPlan?.latest_invoice?.lines?.data[0]?.period
                          ?.start * 1000
                      )
                        ?.toISOString()
                        ?.split("T")[0]
                        ?.replaceAll("-", "/")
                        ?.split("/")
                        ?.reverse()
                        ?.join("/")}{" "}
                      -
                      {" " +
                        new Date(
                          paymentPlan?.latest_invoice?.lines?.data[0]?.period
                            ?.end * 1000
                        )
                          ?.toISOString()
                          ?.split("T")[0]
                          ?.replaceAll("-", "/")
                          ?.split("/")
                          ?.reverse()
                          ?.join("/")}
                    </h6>
                    <div
                      className={"dashboard-card-buttons custom-gradient"}
                      style={{ width: "100%", height: "40px" }}
                      onClick={() => cancelSubscription()}
                    >
                      <div className={"dashboard-card-buttons-text"}>
                        <span>Cancel subscription</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <GradientButton
                  minWidth="137px"
                  text={selectedPaymentPlan ? "Refresh" : "Subscribe"}
                  onClick={() => {
                    if (selectedPaymentPlan) {
                      refreshSubscription(plan);
                    } else {
                      handleSubscription(plan);
                    }
                  }}
                />
              </div>
            ) : (
              <>
                <div
                  style={{ maxWidth: "360px" }}
                  className="custom-gradient sliders-button button-font-details"
                  onClick={handleShowModal}
                >
                  Get Custom Plan
                </div>
                <DreamArchitectModal
                  show={showModal}
                  handleClose={handleCloseModal}
                  plan={plan}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
