import React, { useState } from "react";
import BigImageModal from "../Partials/BigImageModal";
import "../../styles/ProfileContent.css";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadDreamImages,
  getProfileDream,
  deleteDreamImage,
} from "src/services/api";
import { setErrorMessage, setBgLoader, setDream } from "../../store/users";
import EditProfileModal from "./About/EditProfileModal";
import { UsersModule } from "../../types/redux";
import useIsMobile from "../../hooks/useIsMobile";
import { DreamPhotoNewProps } from "../../types/profile-props";
import { toast } from "react-toastify";

const DreamPhotoNew = ({ dream, type }: DreamPhotoNewProps) => {
  const notify = (message) => toast.warning(message);
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const [dreamBigImages, setDreamBigImages] = useState(false);
  const [currentIndex, setIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const dispatch = useDispatch();
  const isMobile = useIsMobile(768);

  const handleDreamBigImages = (index) => {
    setDreamBigImages(true);
    setIndex(index);
  };

  const handleDreamImageChange = (e) => {
    dispatch(setErrorMessage(""));
    const image = [...e.target.files];
    dispatch(setBgLoader(true));
    uploadDreamImages(dream.id, image).then(() => {
      getProfileDream().then((response) => {
        dispatch(setDream(response.data));
      });
    });
  };

  const deleteDreamImg = (imageId) => {
    if (dream?.images?.length < 2) {
      return notify("Please add at least 1 dream image.");
    }
    deleteDreamImage(dream.id, imageId).then(() => {
      getProfileDream().then((response) => {
        dispatch(setDream(response.data));
      });
    });
  };

  const renderDefaultImages = (count) => {
    const defaultImages = [];
    for (let i = 0; i < count; i++) {
      defaultImages.push(
        <div key={`default-${i}`} className="h-100">
          <div
            className="public-dream-photo default-img-public-dream"
            style={{ position: "relative" }}
          >
            <div className="about-missing-profile-image">
              <img
                src="/interact/imgIcon.svg"
                alt="default"
                style={{ height: "24px" }}
              />
            </div>
            {type === "user" && (
              <div>
                <label style={{ cursor: "pointer" }}>
                  <input
                    className="file-upload"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      opacity: 0,
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    name="image"
                    id="image"
                    multiple
                    onChange={handleDreamImageChange}
                  />
                  <img
                    src="/interact/add-image.svg"
                    alt=""
                    className="about-add-profile-image"
                  />
                </label>
              </div>
            )}
          </div>
        </div>
      );
    }
    return defaultImages;
  };

  const responsive = {
    laptop: {
      breakpoint: { max: 5000, min: 866 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 865, min: 701 },
      items: 3.5,
    },
    mobile: {
      breakpoint: { max: 700, min: 601 },
      items: 3,
    },
    mobileS: {
      breakpoint: { max: 600, min: 501 },
      items: 2.5,
    },
    mobileXS: {
      breakpoint: { max: 500, min: 200 },
      items: 2,
    },
  };

  return (
    <div className="about-profile-picture mb-3" style={{ marginTop: "35px" }}>
      {dreamBigImages && (
        <BigImageModal
          index={currentIndex}
          openBigImages={setDreamBigImages}
          dream={dream}
        />
      )}
      <div className="dream-photo-images-new">
        <div className="dream-title-edit-container">
          <div className="dream-profile-photo-title">Dream Photos</div>
          {type === "user" && (
            <div
              onClick={handleShowModal}
              className="edit-profile-button"
              style={{ width: "auto" }}
            >
              <img
                src="/interact/edit-profile.svg"
                alt=""
                style={{ width: "20px" }}
              />
              <span>edit</span>
            </div>
          )}
        </div>
        <EditProfileModal
          show={showModal}
          handleClose={handleCloseModal}
          user={user}
          dream={dream}
        />

        <div className="dream-profile-photos-container">
          <Carousel
            responsive={responsive}
            draggable
            itemClass="new-carousel-dream-item"
            className="new-carousel-dream-images"
          >
            {dream?.images?.map((img, index) => (
              <div key={img?.id || index} className="carousel-dream-image">
                <img
                  draggable="false"
                  src={img?.avatarUrl}
                  alt="gallery"
                  className="public-dream-photo"
                  onClick={() => handleDreamBigImages(index)}
                />
                {type === "user" && !isMobile && (
                  <img
                    draggable="false"
                    src="/interact/delete-image.svg"
                    alt="delete"
                    className="delete-icon-dream-photo"
                    onClick={() => deleteDreamImg(img?.id)}
                  />
                )}
              </div>
            ))}
            {renderDefaultImages(Math.max(0, 5 - dream?.images?.length))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default DreamPhotoNew;
