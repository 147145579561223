import React from "react";
import StepHeader from "./partials/StepHeader";
import Step1Form from "./partials/Step1Form";

const Step1 = () => {
  return (
    <div className="step-1-container">
      <StepHeader title="Welcome, how should we call you?" />
      <Step1Form />
    </div>
  );
};

export default Step1;
