import React from "react";

const NoSelectChat = () => {
  return (
    <div className="no-open-chat-info">
      <span className="no-open-chat-text">
        Select a chat to start messaging
      </span>
    </div>
  );
};

export default NoSelectChat;
