import React, { useEffect } from "react";
import { socket } from "src/socket";

const RoomJoined = () => {
  useEffect(() => {
    socket.on("room-joined", (data) => {
    });

    return () => {
      socket.off("room-joined");
    };
  }, []);
  return <></>;
};

export default RoomJoined;
