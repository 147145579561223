import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GradientButton from "src/components/Buttons/GradientButton";
import CustomInput from "src/components/Partials/CustomInput";
import CustomSelect from "src/components/Partials/CustomSelect";
import { getUser, updateUser } from "src/services/api";
import { setStep } from "src/store/action";
import { setUser } from "src/store/users";
import { UsersModule } from "src/types/redux";
import { getStepNumber } from "src/utils/getStepNumber";

const Step1Form = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const currentStepNumber = getStepNumber((user as any).onboardingStatus) + 1;
  const isFormCompleted = currentStepNumber !== 1 ? true : false;

  const [firstName, setFirstName] = useState<string>("");
  const [firstNameErrorMessage, setFirstNameErrorMessage] =
    useState<string>("");

  const [lastName, setLastName] = useState<string>("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string>("");

  const [gender, setGender] = useState<string>("");

  const isDisableBtn = isFormCompleted
    ? false
    : firstName.length > 2 && lastName.length > 2 && gender !== ""
    ? false
    : true;

  useEffect(() => {
    if (firstName.length === 1) {
      setFirstNameErrorMessage(
        "Please enter a first name with at least 2 characters"
      );
    } else {
      setFirstNameErrorMessage("");
    }

    if (lastName.length === 1) {
      setLastNameErrorMessage(
        "Please enter a last name with at least 2 characters"
      );
    } else {
      setLastNameErrorMessage("");
    }
  }, [firstName, lastName]);

  const handleClick = () => {
    if (isFormCompleted) return dispatch(setStep(2));

    if (!isDisableBtn) {
      updateUser({
        firstName,
        lastName,
        gender: gender.toUpperCase() as "MALE" | "FEMALE",
        onboardingStatus: "WELCOME",
      }).then(() => {
        getUser().then((response) => {
          dispatch(setUser(response.data));
        });
        dispatch(setStep(2));
      });
    }
  };

  return (
    <div className="step-form-container">
      <CustomInput
        placeholder="Content"
        description="First name*"
        state={firstName}
        setState={setFirstName}
        errorMessage={firstNameErrorMessage}
        isError={firstNameErrorMessage !== ""}
        isDisabled={isFormCompleted}
        disabledValue={user.firstName}
      />
      <CustomInput
        placeholder="Content"
        description="Last name*"
        state={lastName}
        setState={setLastName}
        errorMessage={lastNameErrorMessage}
        isError={lastNameErrorMessage !== ""}
        isDisabled={isFormCompleted}
        disabledValue={user.lastName}
      />
      <CustomSelect
        description="Gender*"
        options={["Male", "Female"]}
        selectedOption={gender}
        setSelectedOption={setGender}
        selectType
        isDisabled={isFormCompleted}
        disabledValue={
          user.gender
            ? user?.gender.slice(0, 1) + user?.gender.slice(1).toLowerCase()
            : ""
        }
      />
      <GradientButton
        style={{ width: "100%", marginTop: "24px" }}
        text="Continue"
        textClassName={` ${
          isDisableBtn ? "disabled-text" : "search-modal-btn-text "
        } `}
        className={` ${isDisableBtn ? "disabled-btn" : "gradient-button"}`}
        onClick={handleClick}
      />
    </div>
  );
};

export default Step1Form;
