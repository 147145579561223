import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EmojiMenu from "src/components/Partials/EmojiMenu";
import { acceptMessageRequest } from "src/services/api";
import { socket } from "src/socket";
import { setCanceledChats } from "src/store/action";
import { ChatInputProps } from "src/types/messenger";

const ChatInput = ({
  isRequest,
  userWhoNeedAcceptRequestId,
  requestId,
  toUser,
  rows,
  setRows,
}: ChatInputProps) => {
  const [emojiPicker, showEmojiPicker] = useState(false);
  const [message, setMessage] = useState<string>("");
  const currentUserId = localStorage.getItem("profileId");
  const input = document.querySelector(".chat-input");
  const dispatch = useDispatch();

  const acceptRequest = () => {
    dispatch(setCanceledChats([]));

    if (isRequest && userWhoNeedAcceptRequestId) {
      acceptMessageRequest(requestId.lastMessage.from).then((res) => {
        socket.emit("create-message", {
          from: currentUserId,
          to: toUser,
          content: message,
        });
        setMessage("");
      });
    } else {
      if (message !== "") {
        socket.emit("create-message", {
          from: currentUserId,
          to: toUser,
          content: message,
        });
        setMessage("");
      }
    }
  };

  useEffect(() => {
    if (input && message !== "") {
      const inputWidth = input.clientWidth;
      // 1 letter === 6px
      const messageWidth = message.length * 6.6;
      const numberOfRows =
        +(messageWidth / inputWidth).toString().split(".")[0] + 1;

      if (numberOfRows !== rows) {
        if (numberOfRows === 0) {
          setRows(1);
        } else if (numberOfRows >= 5) {
          setRows(5);
        } else {
          setRows(numberOfRows);
        }
      }
    } else {
      setRows(1);
    }
  }, [message]);

  return (
    <div className="chat-input-container">
      <div className="chat-input-form">
        <textarea
          style={{
            height: `${rows * 20}px`,
            overflowY: rows >= 5 ? "scroll" : "hidden",
          }}
          // rows={rows}
          id="chat"
          className="chat-input"
          placeholder="Write a message"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              acceptRequest();
              showEmojiPicker(false);
            }
          }}
        />

        <EmojiMenu
          showEmojiPicker={showEmojiPicker}
          emojiPicker={emojiPicker}
          setText={setMessage}
          sendMessage={acceptRequest}
          isSendIcon={message.length > 0}
          isChat
        />
      </div>
    </div>
  );
};

export default ChatInput;
