import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  fulfill,
  getAllProfileDonations,
  getProfileFulfilled,
  getProfileWingDonations,
  getPublicFulfilled,
  getPublicWingDonations,
  getUser,
} from "../../services/api";
import {
  setAddProfileFulfilled,
  setAddProfileWingDonations,
  setAddPublicFulfilled,
  setAddPublicWingDonations,
  setAllProfileFulfilled,
  setProfileFulfilled,
  setPublicFulfilled,
  setUser,
} from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PricingModal from "../../pages/Pricing/PricingModal";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import { UsersModule } from "../../types/redux";
import "../../styles/ProfileFulfilled.css";
import MissingData from "./MissingData";
import { MISSING_DATA_ENUM } from "src/consts/missingDataEnum";
import WingFulfilledCard from "./Cards/WingFulfilledCard";
import DreamerFulfilledCard from "./Cards/DreamerFulfilledCard";
import { ProfileFulfilledProps } from "src/types/profile-props";
import { useRoleCheck } from "src/hooks/useRoleCheck";

let pageWing = 1;
let pageFul = 1;
let loader = false;

const ProfileFulfilled = ({
  wings,
  received,
  hasMoreWing,
  hasMoreFulfilled,
}: ProfileFulfilledProps) => {
  const notify = (message) => toast.success(message);
  const params = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: UsersModule) => state.usersModule
  ).user;
  const publicUser = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicUser;
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const [active, setActive] = useState(true);
  const [customModals, setCustomModal] = useState("");
  const [dreamId, setDreamId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPageW, setHasNextPageW] = useState<boolean>(hasMoreWing);
  const [hasNextPageF, setHasNextPageF] = useState<boolean>(hasMoreFulfilled);
  const hasDream = useRoleCheck(publicUser);

  useEffect(
    () => () => {
      setCurrentPage(1);
      pageWing = 1;
      pageFul = 1;
      dispatch(setPublicFulfilled({}));
      dispatch(setProfileFulfilled({}));
    },
    []
  );

  useEffect(() => {
    setHasNextPageF(hasMoreFulfilled);
    setHasNextPageW(hasMoreWing);
  }, [hasMoreFulfilled, hasMoreWing]);

  const loadMore = () => {
    if (active) {
      if (received?.length) {
        if (!loader) {
          pageFul += 1;
          loader = true;
          if (params?.id) {
            getPublicFulfilled(params.id, pageFul).then((response) => {
              dispatch(setAddPublicFulfilled(response?.data));
              setHasNextPageF(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          } else {
            getProfileFulfilled(pageFul).then((response) => {
              dispatch(setAddProfileFulfilled(response?.data));
              setHasNextPageF(response?.data?.meta?.hasNextPage);
              loader = false;
              setCurrentPage(currentPage + 1);
            });
          }
        }
      }
    } else {
      if (wings.length) {
        if (!loader) {
          pageWing += 1;
          loader = true;
          if (params?.id) {
            getPublicWingDonations(params.id, pageWing).then((response) => {
              loader = false;
              setCurrentPage(currentPage + 1);
              setHasNextPageW(response?.data?.meta?.hasNextPage);
              dispatch(setAddProfileWingDonations(response?.data));
            });
          } else {
            getProfileWingDonations(pageWing).then((response) => {
              loader = false;
              setCurrentPage(currentPage + 1);
              setHasNextPageW(response?.data?.meta?.hasNextPage);
              dispatch(setAddPublicWingDonations(response?.data));
            });
          }
        }
      }
    }
  };

  const isFulfilled = (id: string) => {
    if (!currentUser?.balance) {
      setDreamId(id);
      setCustomModal("priceModal");
    } else {
      fulfill(id).then((res) => {
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getUser().then(({ data }) => {
          dispatch(setUser(data));
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
      });
    }
  };
  const receivedDreams = !received?.length ? (
    <div className="missing-profile-fulfilled-data">
      <MissingData
        type={
          hasDream
            ? MISSING_DATA_ENUM.USER_FULFILLED
            : MISSING_DATA_ENUM.ANGEL_FULFILLED
        }
      ></MissingData>
    </div>
  ) : (
    received
      ?.filter(
        (item) => item.dream || currentUser?.id === item?.dream?.user?.id
      )
      .map((dr) => {
        return (
          <Col
            xs={6}
            sm={4}
            lg={3}
            style={{ padding: "0px 5px", marginBottom: "14px" }}
            key={dr.id}
          >
            <DreamerFulfilledCard
              isFulfilled={isFulfilled}
              dream={dr.dream}
              allFulfilled={allFulfilled}
            />
          </Col>
        );
      })
  );
  const wingDonations = !wings?.length ? (
    <div className="missing-profile-fulfilled-data">
      <MissingData type={MISSING_DATA_ENUM.WING_DONATIONS}></MissingData>
    </div>
  ) : (
    wings
      ?.filter((item) => item.charity)
      ?.map((dr) => {
        return (
          <Col xs={6} sm={4} lg={3} className="mb-3 ps-2 pe-2 wings-fulfilled-page-box" key={dr.id}>
            <WingFulfilledCard params={params} dream={dr} />
          </Col>
        );
      })
  );

  return (
    <>
      <InfiniteScroll
        loadMore={() => loadMore()}
        hasMore={active ? hasNextPageF : hasNextPageW}
        useWindow={true}
        loader={
          <div className="d-flex justify-content-center w-100" key="loader">
            <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
          </div>
        }
      >
        <div className="profile-fulfilled-card d-flex mt-3 mb-3">
          <div
            onClick={() => setActive(true)}
            className={
              active
                ? "profile-fulfilled-button-page profile-fulfilled-active-page-button"
                : "profile-fulfilled-button-page"
            }
          >
            <p
              className="profile-fulfilled-button-text"
              onClick={() => setActive(true)}
            >
              Fulfilled Dream
            </p>
          </div>
          <div
            onClick={() => setActive(false)}
            className={
              !active
                ? "profile-fulfilled-button-page profile-fulfilled-active-page-button"
                : "profile-fulfilled-button-page"
            }
          >
            <p className="profile-fulfilled-button-text">Wing Donations</p>
          </div>
        </div>
        <Row style={{ height: "auto", margin: "auto", maxWidth: "1040px" }}>
          <PricingModal
            customModals={customModals}
            setCustomModal={setCustomModal}
            dreamId={dreamId}
          />
          {active ? receivedDreams : wingDonations}
        </Row>
      </InfiniteScroll>
    </>
  );
};
export default ProfileFulfilled;
