import React, { useEffect, useState } from "react";
import loaderGif from "../../../assets/images/page-img/page-load-loader.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import DreamAngelProfile from "./MessengerPartials/DreamAngelProfile";
import { UserChatProps } from "src/types/messenger";
import { formattedDate } from "src/utils/formattedDate";
import ChatTimeDifference from "./MessengerPartials/ChatTimeDifference";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fulfill } from "src/services/api";
import { setUser } from "src/store/users";
import { UsersModule } from "src/types/redux";
import { DreamInfoDto } from "src/services/api/data-contracts";
import PricingModal from "src/pages/Pricing/PricingModal";
import SeenTime from "./MessengerPartials/SeenTime";
import { formatTimeDuration } from "src/utils/formatTimeDuration";
import { calculateMessageBorderRadius } from "src/utils/calculateMessageBorderRadius";
import UserChatMessage from "./MessengerPartials/UserChatMessage";
import useIsMobile from "src/hooks/useIsMobile";

const UserChat = ({
  user,
  messages,
  loadMore,
  hasNextPage,
  isLoading,
  rows,
}: UserChatProps) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(600);
  const notify = (message) => toast.success(message);
  const [chatUserDream, setChatUserDream] = useState<DreamInfoDto>();
  const [customModals, setCustomModal] = useState<string>("");
  const currentUser = useSelector(
    (state: UsersModule) => state.usersModule
  ).user;

  useEffect(() => {
    const activeDreams = user?.dreams?.filter(
      (dream) => dream?.progress !== 100
    );
    if (activeDreams.length > 0) {
      setChatUserDream(activeDreams[0]);
    }
  }, [user]);

  const fullfillDream = () => {
    if (chatUserDream?.id) {
      if (currentUser.balance < 1) {
        setCustomModal("pricing-modal");
      } else {
        fulfill(chatUserDream?.id).then((res) => {
          if (!res) return;
          if (res) {
            toast.dismiss();
            toast.clearWaitingQueue();
            notify("Success!");

            const updatedUserBalance = {
              ...currentUser,
              balance: currentUser.balance - 1,
            };
            dispatch(setUser(updatedUserBalance));
          }
        });
      }
    }
  };

  if (!user && !messages && !chatUserDream) {
    return (
      <div className="chat-loader" key="loader">
        <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
      </div>
    );
  }
  
  const inputHeight = rows === 1 ? 40 : rows * 20;
  const headerHeight = isMobile ? 82 : 92;

  return (
    <div
      className="user-chat-container"
      style={{ height: `calc(100% - ${inputHeight + headerHeight}px)` }}
    >
      {customModals !== "" && (
        <PricingModal
          customModals={customModals}
          setCustomModal={setCustomModal}
          dreamId={chatUserDream?.id}
        />
      )}
      <div className="user-section-chat" id="user-section-chat">
        <InfiniteScroll
          dataLength={messages.length}
          inverse={true}
          next={loadMore}
          hasMore={hasNextPage}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          scrollableTarget="user-section-chat"
          loader={
            <div className="d-flex justify-content-center w-100" key="loader">
              <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
            </div>
          }
          endMessage={
            <DreamAngelProfile
              user={user}
              fullfillDream={fullfillDream}
              chatUserDream={chatUserDream}
            />
          }
        >
          {!isLoading && (
            <div className="saved-posts-container">
              {messages.map((message, index) => {
                const date = formattedDate(messages, index);
                const seenTime = formatTimeDuration(message?.seenAt, true);
                const myMessage = user?.id === message?.from ? false : true;
                const { borderRadius, isBatch } = calculateMessageBorderRadius(
                  messages,
                  index,
                  myMessage
                );

                const isMyMessageNext =
                  messages[index + 1] === undefined
                    ? true
                    : message?.from === messages[index + 1]?.from
                    ? true
                    : false;

                return (
                  <div key={index} className="post-time-container">
                    {date && <ChatTimeDifference text={date} key={date} />}
                    <UserChatMessage
                      key={message?.id}
                      message={message}
                      borderRadius={borderRadius}
                      myMessage={myMessage}
                      isBatch={isBatch}
                      isMyMessageNext={isMyMessageNext}
                    />
                    {index === messages.length - 1 &&
                      message?.seenAt !== null && (
                        <SeenTime
                          text={
                            seenTime === "Seen now"
                              ? "Seen now"
                              : `Seen ${seenTime} ago`
                          }
                        />
                      )}
                  </div>
                );
              })}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default UserChat;
