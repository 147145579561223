import moment from "moment";

export const validDate = (dateString: string): boolean => {
  if (dateString === "") return false;

  const day = dateString.split("/")[0];
  const month = dateString.split("/")[1];
  const year = +dateString.split("/")[2];

  if (year && (year > new Date().getFullYear() || year < 1905)) {
    return false;
  }

  if (month && month?.length === 1) {
    return moment(dateString, "DD/M/YYYY", true).isValid();
  }
  if (day && day?.length === 1) {
    return moment(dateString, "D/MM/YYYY", true).isValid();
  }

  return moment(dateString, "DD/MM/YYYY", true).isValid();
};
