import { useState, useEffect } from "react";

const useContainerWidth = (containerRef) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries && entries[0].contentRect) {
        setWidth(entries[0].contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);

  return width;
};

export default useContainerWidth;
