import React, { useEffect, useState, useRef } from "react";
import StepHeader from "./partials/StepHeader";
import PaymentCards from "./partials/PaymentCards";
import { defaultPaymentPlans } from "src/consts/payments";
import { Link, useNavigate } from "react-router-dom";
import {
  getPricesId,
  getSubscription,
  getUser,
  updateUser,
} from "src/services/api";
import rightArrow from "../../assets/images/payment-scroll-right-arrow.svg";
import leftArrow from "../../assets/images/payment-scroll-left-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "src/store/action";
import { setUser } from "src/store/users";
import { UsersModule } from "src/types/redux";
import useScrollDrag from "src/hooks/useScrollDrag";

interface Step5Props {
  isPricingPage?: boolean;
}

const Step5 = ({ isPricingPage = false }: Step5Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const [paymentPlans, setPaymentPlans] = useState(defaultPaymentPlans);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<any>();
  const scrollContainerRef = useRef(null);

  const {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    scrollByAmount,
    showLeftArrow,
    showRightArrow,
    updateArrowVisibility,
  } = useScrollDrag(scrollContainerRef);

  useEffect(() => {
    if (user.balance !== 0) {
      skipPayment();
    }

    getPricesId().then((response) => {
      const plansWithIds = defaultPaymentPlans.map((plan) => {
        const priceIdKey = plan.priceId;
        const priceId = response.data[priceIdKey];
        return { ...plan, id: priceId };
      });
      setPaymentPlans(plansWithIds);
    });

    getSubscription().then((res) => {
      if (res.data.data.length) {
        const activeSubscription = res.data.data.find(
          (subscription) => subscription.status === "active"
        );
        setSelectedPaymentPlan(activeSubscription);
      }
    });
  }, []);

  const skipPayment = () => {
    if (!isPricingPage) {
      updateUser({
        onboardingStatus: "COMPLETED",
      }).then(() => {
        getUser().then((response) => {
          dispatch(setUser(response.data));
        });
        dispatch(setStep(6));
        navigate("/newsfeed");
      });
    }
  };

  return (
    <div className="step-5-container">
      <StepHeader
        title="Your Dream Realization Plan"
        description={
          isPricingPage
            ? "Refresh your subscription to continue dreaming and growing your support network, upgrade your plan to create bigger impact or cancel anytime and return when you're ready! "
            : "Every $1 donated represents a fulfilled dream collected towards realizing your own dreams while boosting their visibility worldwide. The more you give the more your support network grows, allowing you to achieve your dreams faster, while funding only half."
        }
        noStepNumber={!isPricingPage}
        navigateBack={isPricingPage}
      />
      <div className="payment-carousel-container">
        <div className="payment-carousel-arrow-container">
          {showLeftArrow && (
            <>
              <div className="payment-cards-scroll-left-btn">
                <img
                  src={rightArrow}
                  alt="icon"
                  onClick={() => scrollByAmount(-250)}
                  className="payment-cards-scroll-icon"
                />
              </div>
              <div className="full-container"></div>
            </>
          )}
          {showRightArrow && (
            <>
              <div className="full-container"></div>

              <div className="payment-cards-scroll-right-btn">
                <img
                  src={leftArrow}
                  alt="icon"
                  onClick={() => scrollByAmount(250)}
                  className="payment-cards-scroll-icon"
                />
              </div>
            </>
          )}
        </div>
        <div
          className="payment-cards-scroll"
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onScroll={updateArrowVisibility}
        >
          {paymentPlans.map((plan, index) => {
            let selectedCard;
            if (selectedPaymentPlan) {
              selectedCard = plan.id === selectedPaymentPlan?.plan?.id;
            }
            if (index === 4 && selectedPaymentPlan) {
              const plansId = paymentPlans.find(
                (plan) => plan.id === selectedPaymentPlan?.plan?.id
              );
              selectedCard = plansId === undefined;
            }
            const mostChosen = !selectedPaymentPlan && index === 1;
            return (
              <PaymentCards
                plan={plan}
                index={index}
                key={index}
                selectedPaymentPlan={selectedCard}
                setSelectedPaymentPlan={setSelectedPaymentPlan}
                mostChosen={mostChosen}
                paymentPlan={selectedPaymentPlan}
              />
            );
          })}
        </div>
      </div>
      <Link to="/newsfeed" onClick={skipPayment}>
        Skip for 7 days
      </Link>
    </div>
  );
};

export default Step5;
