import React from "react";
import SavedCharityCard from "./SavedCharityCard";
import SavedDreamAngelProfile from "./SavedDreamAngelProfile";
import SavedDreamCard from "./SavedDreamCard";
import { SavedChatPostProps } from "src/types/messenger";

const SavedChatPost = ({ post, borderRadius }: SavedChatPostProps) => {
  if (post.newsFeedDreamAngel) {
    return <SavedDreamAngelProfile post={post} borderRadius={borderRadius} />;
  } else if (post.charity) {
    return (
      <SavedCharityCard charity={post.charity} borderRadius={borderRadius} />
    );
  } else if (post.dream) {
    return <SavedDreamCard dream={post?.dream} borderRadius={borderRadius} />;
  } else {
    return <></>;
  }
};

export default SavedChatPost;
