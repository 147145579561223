import React from "react";
import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../../assets/images/defaultUser.png";
import "../../styles/DonorsSlider.css";
import { SliderCardProps } from "../../types/models";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "../Tooltip/TooltipDetails";
import { fulfillCheck } from "src/utils/fulfillCheck";
import { useRoleCheck } from "src/hooks/useRoleCheck";

const DonorsCard = ({
  donations,
  isFulfilled,
  allFulfilled,
}: SliderCardProps) => {
  const navigate = useNavigate();
  const tooltipKey = generateTooltipKey(donations?.user?.id);
  const isDreamFulfilled = fulfillCheck(
    allFulfilled,
    donations?.currentDream[0],
    donations?.user?.id
  );

  const hasDream = useRoleCheck(donations?.user);

  return (
    <div className="card-border-rainbow shadow-card">
      <div className="donors-card-img-box">
        <Link
          to={`/dashboard/public-page/${donations?.user?.id}`}
          style={{ height: "0px" }}
        >
          <div
            className={hasDream ? "donors-card-img" : "angel-donors-card-img"}
          >
            <img
              src={donations?.user.mainImageUrl || defaultImage}
              alt="profile-img"
              className="donors-card-img"
              style={{
                ...(!hasDream && {
                  border: "2px solid white",
                }),
              }}
            />
          </div>
        </Link>
      </div>
      <div className="donors-card-main-box">
        <div className="donors-card-name-dscription">
          <Link to={`/dashboard/public-page/${donations?.user?.id}`}>
            <div
              className="d-flex justify-content-center donors-card-name"
              data-tooltip-id={tooltipKey}
            >
              <span className="my-name-span lh-sm text-underline">
                {`${donations?.user?.firstName || "No Name"} ${
                  donations?.user?.lastName || ""
                }`}
              </span>
            </div>
          </Link>
          <TooltipDetails
            userId={donations?.user?.id}
            isFulfilled={isFulfilled}
            allFulfilled={allFulfilled}
            tooltipKey={tooltipKey}
          />
          {hasDream ? (
            <div className="my-span lh-sm donors-card-dream-description">
              {donations?.currentDream[0]?.title || " "}
            </div>
          ) : (
            <div className="donors-card-dream-description">
              <span className="donors-card-dream-angel-status">
                Dream Angel
              </span>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between flex-column donors-card-fulfill-box">
          <div
            style={{ height: "16px" }}
            className="received-angel-fulfilled-box"
          >
            <p>Fulfilled</p>
            <div className="fw-bold">{donations?.amount} Dreams</div>
          </div>
          {!hasDream ? (
            <Link to={`/dashboard/public-page/${donations?.user?.id}`}>
              <div className="custom-gradient sliders-button">
                <div className="dashboard-card-buttons-text">
                  <span>View Profile</span>
                </div>
              </div>
            </Link>
          ) : (
            <div
              className="custom-gradient sliders-button"
              onClick={() =>
                isDreamFulfilled
                  ? navigate(`/dashboard/public-page/${donations?.user?.id}`)
                  : isFulfilled(donations.currentDream?.id)
              }
            >
              <div className="dashboard-card-buttons-text">
                <span>
                  {isDreamFulfilled
                    ? "View Profile"
                    : `Fulfill ${
                        donations?.user?.gender === "FEMALE" ? "Her" : "His"
                      } dream`}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DonorsCard;
