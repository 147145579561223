enum StepEnum {
  WELCOME = "WELCOME",
  PERSONAL_DATA = "PERSONAL_DATA",
  USER_PROFILE = "USER_PROFILE",
  CREATE_DREAM = "CREATE_DREAM",
  SUBSCRIPTION_PLAN = "SUBSCRIPTION_PLAN",
  COMPLETED = "COMPLETED",
}
export const getStepNumber = (step: string): number => {
  if (step === null) return 0;
  const stepOrder = {
    [StepEnum.WELCOME]: 1,
    [StepEnum.PERSONAL_DATA]: 2,
    [StepEnum.USER_PROFILE]: 3,
    [StepEnum.CREATE_DREAM]: 4,
    [StepEnum.SUBSCRIPTION_PLAN]: 5,
    [StepEnum.COMPLETED]: 6,
  };

  return stepOrder[step] || 1;
};
