import React from "react";
import PublicNavBar from "src/components/NavBar/PublicNavBar";
import { checkEmail, logo } from "src/consts/SvgIcon";

export default function CheckYourEmail() {
  const email = localStorage.getItem("email");
  return (
    <div className="d-flex justify-content-center align-items-center flex-column screen-size">
      <PublicNavBar />
      <div className="gradient-login-bg">
        <div className="login-pages-container d-flex justify-content-center">
          <div className="check-email-content">
            <div style={{ color: "black" }}>{logo()}</div>
            <div className="d-flex flex-column align-items-center">
              <div> {checkEmail()} </div>
              <div className="check-email-title">
                <div>Check Your Email</div>
              </div>
              <div className="check-email-message">
                We have sent an email to <span>{email}</span> with a
                confirmation link. You can now close this tab.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
