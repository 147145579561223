import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserChats } from "src/services/api";
import { socket } from "src/socket";
import { setRequestsChats, setMessengerChats } from "src/store/action";

const ChatDeleted = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("chat-deleted", (data) => {
      getUserChats(false).then((res) => dispatch(setRequestsChats(res.data)));
      getUserChats(true).then((res) => dispatch(setMessengerChats(res.data)));
    });

    socket.on("delete-chat-error", (data) => {
      console.log("deleted-chat-error", data);
    });
    return () => {
      socket.off("chat-deleted");
      socket.off("delete-chat-error");
    };
  }, []);
  return <></>;
};

export default ChatDeleted;
