import "./assets/scss/socialv.scss";
import React, { useEffect } from "react";
import AppRouter from "./router/AppRouter";
import Loader from "./components/Partials/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Flip, ToastContainer } from "react-toastify";
import Footer from "./components/Layout/Footer";
import { ChatType, UsersModule } from "./types/redux";
import { setAdmins, setUser } from "./store/users";
import { getAdmins, getUser } from "./services/api";
import { socket } from "./socket";
import { useLocation, useNavigate } from "react-router-dom";
import ChatDeleted from "./events/ChatDeleted";

const nonFooterPages = ["/dashboard", "/newsfeed", "/messenger"];

function App() {
  let firstClientY;
  let firstClientX;
  let clientX;
  let clientY;
  const token = localStorage.getItem("accessToken");
  const canceledChats = useSelector(
    (state: ChatType) => state.messages
  ).canceledChats;
  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/messenger") {
      if (canceledChats.length) {
        socket.emit("delete-chat", {
          to: canceledChats[0].lastMessage.to,
          from: canceledChats[0].lastMessage.from,
        });
      }
    }

    if (token) {
      getUser().then((response) => {
        dispatch(setUser(response?.data));
        if (response?.data?.onboardingStatus !== "COMPLETED") {
          navigate("/user-profile/edit-form");
        }
      });
    }
  }, [location.pathname]);

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      if (e.cancelable) {
        e.preventDefault();
        e.returnValue = false;
      }
      return false;
    }
  };
  const isAdminChecked = useSelector(
    (state: UsersModule) => state.usersModule
  ).admins;

  useEffect(() => {
    if (isAdminChecked.length <= 0) {
      getAdmins().then((res) => {
        dispatch(setAdmins(res?.data));
      });
    }
  }, [dispatch, isAdminChecked]);

  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
  });

  useEffect(() => () => {
    const eventOptions = { passive: false } as EventListenerOptions;
    window.removeEventListener("touchstart", touchStart);
    window.removeEventListener("touchmove", preventTouch, eventOptions);
  });

  useEffect(() => {
    if (token) {
      socket.connect();

      socket.on("connect", () => {
        console.info("Socket is connected");
      });
    }
    return () => {
      socket.disconnect();
    };
  }, [token, socket]);

  const loader = useSelector(
    (state: UsersModule) => state.usersModule
  ).bgLoader;
  return (
    <div
      style={{
        backgroundColor: "#F1F1F1",
        minHeight: `${
          window.location.pathname === "/messenger" ? "100%" : "100vh"
        }`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChatDeleted />
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        draggablePercent={60}
        hideProgressBar={true}
        closeButton={false}
        toastClassName="shadow-none fw-bold text-black rounded-3 shadow-notifications"
        limit={1}
      />
      {loader && <Loader />}
      <AppRouter />
      {!nonFooterPages.includes(window.location.pathname) && <Footer />}
    </div>
  );
}

export default App;
