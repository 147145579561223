import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../assets/images/search-modal-close.svg";
import GradientButton from "src/components/Buttons/GradientButton";
import { getSearchedUsers } from "src/services/api";
import { socket } from "src/socket";
import { SearchChatModalProps } from "src/types/messenger";
import { useDispatch, useSelector } from "react-redux";
import { setChatType } from "src/store/action";
import SearchChatCard from "./MessengerPartials/SearchChatCard";
import { ChatType } from "src/types/redux";
import { UserDto } from "src/services/api/data-contracts";

const SearchChatModal = ({
  showSearchModal,
  setShowSearchModal,
}: SearchChatModalProps) => {
  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState<UserDto[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [timer, setTimer] = useState(null);
  const [userSelected, setUserSelected] = useState<string>("");
  const userFrom = localStorage.getItem("profileId");

  const requestChats = useSelector(
    (state: ChatType) => state.messages
  ).requestsChats;
  const messengerChats = useSelector(
    (state: ChatType) => state.messages
  ).messengerChats;

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        if (e.target.value) {
          getSearchedUsers(e.target.value).then((res) => {
            const chatUserIds = new Set([
              ...requestChats.map((chat) => chat.user.id),
              ...messengerChats.map((chatMes) => chatMes.user.id),
            ]);

            const newUsers = res.data.filter(
              (user) => !chatUserIds.has(user.id)
            );

            return setAllUsers(newUsers);
          });
        } else {
          setAllUsers([]);
        }
      }, 1000)
    );
  };

  useEffect(() => {
    if (inputValue === "") {
      setUserSelected("");
    }
  }, [inputValue]);

  const addChat = () => {
    if (userSelected !== "" && userFrom) {
      socket.emit("create-message", {
        from: userFrom,
        to: userSelected,
        content: "",
      });
    }
    dispatch(setChatType(userSelected));
    setShowSearchModal(false);
  };

  return (
    <Modal
      backdrop={true}
      centered
      show={!!showSearchModal}
      onHide={() => setShowSearchModal(false)}
      animation={true}
      contentClassName="border-0"
      style={{ borderRadius: "8px" }}
      className="search-bootstrap-modal"
    >
      <div className="search-modal-container">
        <div className="search-modal-title">
          <span>Search People</span>
          <div
            onClick={() => setShowSearchModal(false)}
            style={{ cursor: "pointer" }}
          >
            <img src={close} alt="x" />
          </div>
        </div>
        <input
          type="text"
          placeholder="Search.."
          className="search-modal-input"
          value={inputValue}
          onChange={(e) => handleChange(e)}
        />
        <div className="search-modal-line"></div>
        <div className="search-modal-results">
          {allUsers?.map((user) => {
            if (user?.id === userFrom) return null;
            return (
              <SearchChatCard
                key={user?.id}
                user={user}
                userSelected={userSelected}
                setUserSelected={setUserSelected}
              />
            );
          })}
        </div>
        <GradientButton
          onClick={addChat}
          text="Chat"
          textClassName={` ${
            userSelected === "" ? "disabled-text" : "search-modal-btn-text"
          }`}
          className={` ${
            userSelected === "" ? "disabled-btn" : "gradient-button"
          }`}
        />
      </div>
    </Modal>
  );
};

export default SearchChatModal;
