import React, { useEffect, useState } from "react";
import Card from "../../../components/Partials/Card";
import { Carousel } from "react-bootstrap";
import "../../../styles/Dashboard.css";
import { getCharityComments, getWingDonations } from "../../../services/api";
import { setBgLoader, setWingDonations } from "../../../store/users";
import { useDispatch, useSelector } from "react-redux";
import FeedComments from "../../../components/Comments/FeedComments";
import FulfilledBy from "../../../components/FulfilledBy";
import ResponsiveCommentsModal from "../../../components/Comments/ResponsiveCommentsModal";
import { SOCIALS_ENUM } from "../../../consts/socialsEnum";
import NewsfeedSocialInteraction from "../../../components/Partials/NewsfeedSocialInteraction";
import { User, UsersModule } from "src/types/redux";
import { ActivityType } from "src/components/Profile/Activity";
import { CharityCommentDto } from "src/services/api/data-contracts";
import { savePost, updateCharityLikes } from "src/store/action";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { shareTo } from "src/utils/share";
import CharityCard from "src/components/Partials/CharityCard";
import { commentType } from "src/consts/commentsTypeEnum";

type CharityTypes = {
  post;
  isFulfilled: (id: string) => void;
  onClick?: (id: string) => void;
  user?: User;
  type?: string;
  timestamp?: string;
  reply?: CharityCommentDto;
};

const Charity = ({
  post,
  isFulfilled,
  onClick,
  user,
  type,
  timestamp,
  reply,
}: CharityTypes) => {
  const [modalProps, setModalProps] = useState(false);
  const [show, setShow] = useState(false);
  const [commentInput, setCommentInput] = useState("");

  const mainUser = useSelector((state: UsersModule) => state.usersModule).user;
  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const wingDonationsProfile = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileWing;
  const progress = calculateDreamProgress(post?.charity);
  const wingDonations = useSelector(
    (state: UsersModule) => state.usersModule
  ).wingDonations;

  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(Boolean);
  const dispatch = useDispatch();

  useEffect(() => {
    const likesCount = post?.charity?.likedCharitiesByUsers.length;
    const likedBy = post?.charity?.likedCharitiesByUsers.map((user) => user.id);
    const userLiked = likedBy.includes(localStorage.getItem("profileId"));
    dispatch(
      updateCharityLikes(post?.charityId, likesCount, userLiked, likedBy)
    );
    if (post.isSaved) {
      dispatch(savePost(post?.charityId));
    }
  }, [post]);

  const openCommentModal = (dreamId) => {
    dispatch(setBgLoader(true));
    setModalProps(true);
    getCharityComments(dreamId, 1, 10).then((res) => {
      setComments(res?.data?.results);
      setHasMore(res?.data?.meta?.hasNextPage);
      setModalProps(true);
    });
  };

  const progressClick = () => {
    dispatch(setWingDonations({}));
    setShow((prev) => !prev);
    getWingDonations(post.charityId, 1).then((res) => {
      dispatch(setWingDonations(res.data));
    });
  };

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img} onClick={() => openCommentModal(post.charityId)}>
        <img src={img} className="post-image cursor-pointer" alt="img" />
      </Carousel.Item>
    );
  };

  return (
    <Card className="rounded-3 gap-card pb-0 shadow m-0">
      <Card.Body className="p-0 gap-post">
        {type ? (
          <ActivityType
            contributor={user}
            type={type}
            isFulfilled={isFulfilled}
            timestamp={timestamp}
            reply={reply}
          />
        ) : (
          <></>
        )}
        {post?.type === "FULFILL_WING_DONATION" && (
          <FulfilledBy
            post={post}
            contributor={post?.contributor}
            text={post?.title}
            onClick={type ? null : onClick}
            isFulfilled={isFulfilled}
          />
        )}
        <CharityCard
          wingDonationsProfile={wingDonationsProfile}
          charity={post.charity}
          isFulfilled={isFulfilled}
          progressClick={progressClick}
          carouselItem={carouselItem}
          wingDonations={wingDonations}
          setShow={setShow}
          show={show}
        />
        <div style={{ marginBottom: "-20px", marginTop: "-6px" }}>
          <NewsfeedSocialInteraction
            post={post}
            charity={post.charity}
            type={SOCIALS_ENUM.NEWSFEED_CHARITY}
            openCommentModal={openCommentModal}
          ></NewsfeedSocialInteraction>
        </div>
        <FeedComments
          user={mainUser}
          dreamId={post?.charityId}
          mainImg={mainImg}
          dream={post?.charity}
          openCommentModal={openCommentModal}
          type={commentType.charity}
          isFulfilled={isFulfilled}
          commentInput={commentInput}
          setCommentInput={setCommentInput}
        />
        <ResponsiveCommentsModal
          dream={post?.charity}
          type={commentType.charity}
          modalProps={modalProps}
          setModalProps={setModalProps}
          setComments={setComments}
          comments={comments}
          setHasMore={setHasMore}
          hasMore={hasMore}
          mainImage={mainImg}
          isFulfilled={isFulfilled}
          currentUser={mainUser}
          allFulfilled={wingDonationsProfile}
          progress={progress}
          user={post?.user}
          shareTo={shareTo}
          post={post}
          currentUserComment={commentInput}
          setComment={setCommentInput}
        />
      </Card.Body>
    </Card>
  );
};

export default Charity;
